import Container from 'components/Container';
import HGButton from 'components/HGButton';
import HGText from 'components/HGText';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActivityIndicator,
  Image,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {scale, widthPercentageToDP, heightPercentageToDP} from 'utils/Scale';
import useCounterCheckout from '../../hooks/useCounterCheckout';
import useTicketCheckout from '../../hooks/useTicketCheckout';
import Colors from '../../utils/Colors';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const [initialize, setInitialize] = useState(false);
  const {
    type: orderType,
    products,
    timeslot,
    billing,
    tickets,
    assignments,
    options,
  } = route.params ?? {};
  const {checkoutOrder, clearCart, getPaymentStatus} =
    orderType === 'counter' ? useCounterCheckout() : useTicketCheckout();
  const [paymentStatus, setPaymentStatus] = useState('pending');
  const [showPaymentButton, setShowPaymentButton] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [paymentId, setPaymentId] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const [intervalId, setIntervalId] = useState(null);

  const _getPaymentStatus = useCallback(
    async paymentId => {
      const res = await getPaymentStatus(paymentId);
      setPaymentStatus(res.status);
    },
    [getPaymentStatus],
  );

  const renderPaymentStatus = () => {
    let text = '';
    switch (paymentStatus) {
      case 'TRANSACTION_ABORTED':
        text = t('paymentResults.loadingScreen.failure');
        break;
      case 'TRANSACTION_IN_WRONG_STATE':
      case 'TRANSACTION_NOT_STARTED':
      default:
        text = t('paymentResults.loadingScreen.pending');
    }
    return <HGText>{text}</HGText>;
  };

  const _checkoutOrder = useCallback(() => {
    const afterCheckout = res => {
      if (res.error) {
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'OrderPaymentWebHook',
              params: {
                showPlaceholder: false,
                success: false,
                orderType,
              },
            },
          ],
        });
        return;
      }

      setOrderId(res.orderId);
      setPaymentLink(res.paymentLink);
      const _intervalId = setInterval(
        () => _getPaymentStatus(res.paymentId),
        2000,
      );
      setIntervalId(_intervalId);
      setPaymentId(res.paymentId);

      setTimeout(async () => {
        const res = await getPaymentStatus(res.paymentId);
        if (res.status === 'TRANSACTION_NOT_STARTED') {
          setShowPaymentButton(true);
        }
      }, 3000);

      const openPaymentLink = () => {
        if (Platform.OS === 'web') {
          window.location.replace(res.paymentLink);
        } else {
          navigation.navigate('PaymentWebView', {paymentLink: res.paymentLink});
        }
      };

      orderType === 'ticket' ? openPaymentLink() : clearCart(openPaymentLink);
    };

    orderType === 'ticket'
      ? checkoutOrder(
          {
            billing,
            tickets,
            options,
            assignments,
          },
          afterCheckout,
        )
      : checkoutOrder({products, timeslot}, afterCheckout);
  }, [
    navigation,
    orderType,
    _getPaymentStatus,
    checkoutOrder,
    clearCart,
    getPaymentStatus,
    products,
    timeslot,
    billing,
    tickets,
    assignments,
    options,
  ]);

  useEffect(() => {
    switch (paymentStatus) {
      case 'AUTHORIZED':
        clearInterval(intervalId);
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'OrderPaymentWebHook',
              params: {
                showPlaceholder: false,
                success: true,
                orderId,
              },
            },
          ],
        });
        break;
      case 'error':
      case 'TRANSACTION_ABORTED':
        clearInterval(intervalId);
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'OrderPaymentWebHook',
              params: {
                showPlaceholder: false,
                success: false,
                orderId,
              },
            },
          ],
        });
        break;
      default:
        break;
    }
  }, [navigation, paymentStatus, intervalId, orderId]);

  useEffect(() => {
    if (!initialize && products && timeslot) {
      setInitialize(true);
      _checkoutOrder();
    } else if (!initialize && billing && tickets && assignments) {
      setInitialize(true);
      _checkoutOrder();
    }
  }, [
    initialize,
    _checkoutOrder,
    products,
    timeslot,
    billing,
    tickets,
    assignments,
  ]);

  return (
    <Container style={styles.container}>
      {paymentStatus !== 'success' ? (
        <ActivityIndicator color={Colors.PRIMARY_COLOR} />
      ) : (
        <View />
      )}
      <View style={styles.paymentText}>{renderPaymentStatus()}</View>
      {showPaymentButton && paymentStatus === 'TRANSACTION_NOT_STARTED' ? (
        <HGButton
          label={t('make payment')}
          onPress={() => {
            Linking.openURL(`${paymentLink}`);
          }}
        />
      ) : (
        <View />
      )}
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentText: {
    marginVertical: scale(20),
  },
});
