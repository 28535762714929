import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from '../../utils/Scale';
import IconButton from '../IconButton';
import Row from '../Row';
import * as HGIcon from '../HGIcon';
import HGText from '../HGText';
import Colors from 'utils/Colors';
import {useTranslation} from 'react-i18next';

export default ({
  quantity,
  onChange,
  minValue = 1,
  size = 'normal',
  colorIfEnabled = false,
  hideQuantity = false,
  stock,
  isStockCheckMsgVisible = false,
  cart,
  itemId,
  isTicket = false,
}) => {
  const {t, i18n} = useTranslation();
  const [value, _setValue] = useState(quantity || minValue);
  const setValue = useCallback(
    add => {
      if (!isTicket) {
        let finalValue = value;
        if (add) {
          if (stock > 0) {
            const selectedKey = itemId.toString();
            const selectedValue = value;
            const carts = Object.entries(cart.items);
            const isItemExist = Object.keys(cart.items).includes(selectedKey);
            if (isItemExist) {
              for (const [key, value] of carts) {
                if (key === selectedKey) {
                  const checkStock = selectedValue + 1;
                  if (checkStock <= stock) {
                    finalValue = value + 1;
                  }
                }
              }
            } else {
              finalValue = value + 1;
            }
          } else if (!stock && stock !== 0) {
            finalValue = value + 1;
          }

          if (finalValue !== value) {
            _setValue(finalValue);
            onChange && onChange(finalValue, add);
          }
        } else {
          if (value - 1 >= minValue) {
            finalValue = value - 1;
          }

          _setValue(finalValue);
          onChange && onChange(finalValue, add);
        }
      } else {
        let finalValue = value;
        if (add) {
          if (stock !== false && stock >= 0) {
            if (stock > 0 && value < stock) {
              finalValue = value + 1;
            }
          } else {
            finalValue = value + 1;
          }
        } else {
          if (value - 1 >= minValue) {
            finalValue = value - 1;
          }
        }
        _setValue(finalValue);
        onChange && onChange(finalValue, add);
      }

      // let finalValue = value;
      // if (add) {
      //   if (stock !== false && stock >= 0) {
      //     if (stock > 0 && value < stock) {
      //       finalValue = value + 1;
      //     }
      //   } else {
      //     finalValue = value + 1;
      //   }
      // } else {
      //   if (value - 1 >= minValue) {
      //     finalValue = value - 1;
      //   }
      // }
      // _setValue(finalValue);
      // onChange && onChange(finalValue, add);
    },
    [_setValue, onChange, value, minValue],
  );

  return (
    <>
      <Row style={styles.container}>
        <IconButton
          {...(colorIfEnabled && quantity > 0
            ? {
                icon: (
                  <HGIcon.MinusCircleRed
                    style={styles[size === 'small' ? 'smallIcon' : 'icon']}
                  />
                ),
              }
            : {
                icon: (
                  <HGIcon.MinusCircle
                    style={styles[size === 'small' ? 'smallIcon' : 'icon']}
                  />
                ),
              })}
          onPress={setValue.bind(null, false)}
          size={size === 'small' ? scale(20) : scale(30)}
        />
        {hideQuantity ? (
          <View style={styles.hideQuantityDivider} />
        ) : (
          <HGText
            style={styles[size === 'small' ? 'smallQuantity' : 'quantity']}>
            {value}
          </HGText>
        )}
        <IconButton
          {...(colorIfEnabled && quantity > 0
            ? {
                icon: (
                  <HGIcon.PlusCircleRed
                    style={styles[size === 'small' ? 'smallIcon' : 'icon']}
                  />
                ),
              }
            : {
                icon: (
                  <HGIcon.PlusCircle
                    style={styles[size === 'small' ? 'smallIcon' : 'icon']}
                  />
                ),
              })}
          onPress={setValue.bind(null, true)}
          size={size === 'small' ? scale(20) : scale(30)}
        />
      </Row>
      {stock <= 5 && stock !== false && isStockCheckMsgVisible ? (
        <HGText style={styles.textStockCheck}>
          {stock > 0
            ? t('selectTicketQtyCard.stockCheckTicket').replace(
                '${stock}',
                stock,
              )
            : null}
          {stock === 0 ? t('selectTicketQtyCard.soldOut') : null}
        </HGText>
      ) : null}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  quantity: {
    fontSize: scale(17),
    width: scale(48),
    textAlign: 'center',
  },
  smallQuantity: {
    fontSize: scale(15),
    width: scale(32),
    textAlign: 'center',
  },
  icon: {
    width: scale(45),
    height: scale(45),
  },
  smallIcon: {
    width: scale(30),
    height: scale(30),
  },
  hideQuantityDivider: {
    width: scale(10),
  },
  textStockCheck: {
    fontWeight: 'bold',
    color: Colors.STOCK_CHECK_COLOR,
    alignSelf: 'flex-end',
    maxWidth: scale(110),
  },
});
