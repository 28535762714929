import {LeftChevron, RightChevron} from 'components/HGIcon';
import IconButton from 'components/IconButton';
import React, {useCallback, useState} from 'react';
import Cropper from 'react-easy-crop';
import {StyleSheet, View} from 'react-native';
import getCroppedImg from 'utils/cropImage.web';
import {heightPercentageToDP, scale, widthPercentageToDP} from 'utils/Scale';
export default ({image, onFinish, onClose}) => {
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [completeCrop, setCompleteCrop] = useState([]);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCompleteCrop([croppedArea, croppedAreaPixels]);
  };

  const onSubmit = useCallback(async () => {
    const [croppedArea, croppedAreaPixels] = completeCrop;
    // true as last param to return just the canvas
    const cropped = await getCroppedImg(image, croppedAreaPixels, 0, true);
    cropped.toBlob(
      blob => {
        onFinish && onFinish(blob); //blob
      },
      'image/jpeg',
      1,
    );
  }, [image, onFinish, completeCrop]);
  return (
    <View style={styles.container}>
      <Cropper
        image={image}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        objectFit="vertical-cover"
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
      <IconButton
        style={styles.backButton}
        icon={<LeftChevron />}
        onPress={onClose || (() => {})}
      />
      <IconButton
        size={scale(60)}
        style={styles.nextIcon}
        icon={<RightChevron />}
        onPress={onSubmit}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: 'black',
    width: widthPercentageToDP(100),
    height: heightPercentageToDP(100),
  },
  backButton: {
    zIndex: 999,
    position: 'absolute',
    top: scale(20),
    left: scale(20),
  },
  nextIcon: {
    position: 'absolute',
    top: heightPercentageToDP(100) - scale(100),
    right: scale(20),
  },
});
