import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGText from '../HGText';
import RadioButton from '../RadioButton';
import Row from '../Row';

export default ({label, active, onPress}) => {
  return (
    <TouchableOpacity onPress={onPress || (() => {})}>
      <Row style={styles.container}>
        <RadioButton active={active} />
        {label && (
          <View style={styles.labelContainer}>
            <HGText fontSize={scale(17)}>{label}</HGText>
          </View>
        )}
      </Row>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  labelContainer: {
    marginLeft: scale(7.5),
  },
});
