import React, {useEffect, useState} from 'react';
import {View, Text, ScrollView} from 'react-native';
import Container from 'components/Container';
import SearchBar from 'components/SearchBar';
import {scale} from '../../utils/Scale';
import Row from 'components/Row';
import HomeTopNavButtons from './components/HomeTopNavButtons';
import NotificationBanner from '../../components/NotificationBanner';
import {useTranslation} from 'react-i18next';
import GenreList from './components/GenreList';
import CategoryList from './components/CategoryList';
import InfiniteVerticalCategoryList from './components/InfiniteVerticalCategoryList';
import getEvents from 'hooks/getEvents';
import CONFIG from 'config';
import useSearchEvents from '../../hooks/useSearchEvents';
import useModal from '../../hooks/useModal';
import {initializeData} from '../../state/events';
import {useDispatch, useSelector} from 'react-redux';

export default ({navigation, route}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const {events, categories, error, loading} = getEvents();
  const [shownError, setShownError] = useState(false);
  const {
    events: filteredEvents,
    search,
    setSearch,
    fetching,
  } = useSearchEvents({events});
  const {showModal} = useModal();

  const timerActive = useSelector(state => state.events.timerActive);
  const [counts, setCounts] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;
    if (timerActive) {
      const newCounts = counts + 1;
      console.log('timer-started', newCounts);
      if (counts > 0) {
        console.log('timer-load', counts);
        dispatch(initializeData());
      }
      setCounts(newCounts);
      interval = setInterval(() => {
        setSeconds(seconds => seconds + 1);
      }, 60000 * 10);
    } else if (!timerActive && seconds !== 0) {
      console.log('timer-stopped');
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timerActive, seconds]);

  useEffect(() => {
    if (search && !route.params?.clearSearch) {
      navigation.setParams({clearSearch: () => setSearch('')});
    } else if (!search && route.params?.clearSearch) {
      navigation.setParams({clearSearch: null});
    }
  }, [search, navigation, route, setSearch]);

  useEffect(() => {
    if (error && !shownError) {
      setShownError(true);
      showModal({title: 'Error', body: 'Server is offline'});
    }
  }, [showModal, shownError, error]);

  if (search)
    return (
      <Container>
        <ScrollView>
          <SearchBar
            containerStyle={{marginVertical: scale(40)}}
            onChangeText={setSearch}
            value={search}
            onClear={() => setSearch('')}
          />
          <InfiniteVerticalCategoryList
            loading={loading || fetching}
            navigation={navigation}
            header={t('ALL EVENTS AT ONE GLANCE')}
            events={filteredEvents}
          />
        </ScrollView>
      </Container>
    );

  return (
    <Container>
      <ScrollView>
        <SearchBar
          loading={loading}
          containerStyle={{marginVertical: scale(40)}}
          onChangeText={setSearch}
          value={search}
        />
        {!CONFIG.DISABLE_TICKETS ||
        !CONFIG.DISABLE_FAVOURITES ||
        !CONFIG.DISABLE_BAR ? (
          <Row>
            {!CONFIG.DISABLE_FAVOURITES && (
              <HomeTopNavButtons name="favorites" disabled={true} />
            )}
            {!CONFIG.DISABLE_TICKETS && (
              <HomeTopNavButtons
                name="tickets"
                onPress={() => navigation.navigate('Tickets')}
                boldLabel={true}
              />
            )}
            {!CONFIG.DISABLE_BAR && (
              <HomeTopNavButtons
                name="counter"
                onPress={() => navigation.navigate('Bar')}
                boldLabel={true}
              />
            )}
          </Row>
        ) : null}
        {/* Pandemic Message Hide For Temporary */}
        {/* <NotificationBanner
          title={t('messages.covidHome.title')}
          body={t('messages.covidHome.body')}
        /> */}
        <GenreList />

        {categories.map(({name, id: genreId}) => (
          <CategoryList
            loading={loading}
            navigation={navigation}
            key={name}
            header={name}
            genre={name}
            events={events.filter(({categories}) => {
              let isInFilter = false;
              categories.forEach(({id}) => {
                if (id === genreId) {
                  isInFilter = true;
                }
              });
              return isInFilter;
            })}
          />
        ))}

        <InfiniteVerticalCategoryList
          loading={loading}
          navigation={navigation}
          header={t('ALL EVENTS AT ONE GLANCE')}
          events={events}
        />
      </ScrollView>
    </Container>
  );
};
