import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';

export default ({children, style, ...props}) => (
  <View style={[styles.container, style]} {...props}>
    {children}
  </View>
);

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
    backgroundColor: '#FFF',
    paddingVertical: scale(16),
    paddingHorizontal: scale(32),
    borderRadius: scale(10),
  },
});
