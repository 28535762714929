import React, {useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import HGTextInput from 'components/HGTextInput';
import {scale} from '../../../utils/Scale';
import {useTranslation} from 'react-i18next';
import Colors from '../../../utils/Colors';
import HGText from '../../../components/HGText';
import HGButton from 'components/HGButton';
import WhiteContainer from '../../../components/WhiteContainer';
import {useForm} from 'react-hook-form';
import useAccount from '../../../hooks/useAccount';
import useModal from '../../../hooks/useModal';
import {Eye} from 'components/HGIcon';
import HGCheckBox from '../../../components/HGCheckBox';
import inputValidations from '../../../utils/inputValidations';

const RegisterForm = ({navigation, route}) => {
  const {t} = useTranslation();
  const [ageConsent, setAgeConsent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors, isValid},
  } = useForm({mode: 'onChange'});

  const {register, loading} = useAccount();
  const {showModal} = useModal();
  const {onlyAlphabetWithSpace} = inputValidations();

  const onSubmit = async data => {
    const res = await register(data);
    if (res.error) {
      showModal({
        title: t('errors.register.title'),
        body: t('errors.register.body'),
      });
      return;
    }
    if (route.params?.redirect) {
      navigation.replace(route.params?.redirect);
    }
  };

  const watchFields = watch(['password']);
  return (
    <>
      <WhiteContainer style={styles.form}>
        <HGTextInput
          variant="form"
          form={{
            control,
            name: 'firstName',
            rules: {required: true, ...onlyAlphabetWithSpace},
          }}
          error={errors['firstName']}
          label={t('First Name')}
        />
        <HGTextInput
          variant="form"
          form={{
            control,
            name: 'lastName',
            rules: {required: true, ...onlyAlphabetWithSpace},
          }}
          error={errors['lastName']}
          label={t('Last Name')}
        />
        <HGTextInput
          variant="form"
          inputProps={{autoCapitalize: 'none'}}
          form={{
            control,
            name: 'email',
            rules: {
              validate: email => {
                const re =
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return (
                  re.test(String(email).toLowerCase()) || t('errors.email')
                );
              },
            },
          }}
          error={errors['email']}
          label={t('E-Mail')}
          keyboard={'email-address'}
        />
        <HGTextInput
          variant="form"
          isPasswordField={true}
          form={{
            control,
            name: 'password',
            rules: {
              required: true,
              minLength: {value: 8, message: t('errors.minLengthPassword')},
            },
          }}
          error={errors['password']}
          label={`${t('Password')} (${t('Min Length')}: 8)`}
        />
        <HGTextInput
          variant="form"
          isPasswordField={true}
          form={{
            control,
            name: 'confirmPassword',
            rules: {
              validate: val =>
                (val && val === watchFields[0]) || t('errors.passwordMatch'),
            },
          }}
          error={errors['confirmPassword']}
          label={t('Confirm Password')}
        />
        <HGCheckBox
          variant="form"
          label={t('forms.ageConsent')}
          value={ageConsent}
          onChange={setAgeConsent}
        />
      </WhiteContainer>
      <View style={styles.button}>
        <HGButton
          disabled={!ageConsent}
          loading={loading}
          label={t('Register')}
          onPress={handleSubmit(onSubmit)}
        />
      </View>
    </>
  );
};

export default RegisterForm;

const styles = StyleSheet.create({
  form: {
    marginBottom: scale(50),
  },
  button: {
    alignItems: 'center',
  },
  eye: {
    width: 24,
    height: 16,
  },
});
