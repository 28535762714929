import {LeftChevron} from 'components/HGIcon';
import IconButton from 'components/IconButton';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from '../../utils/Scale';

export default ({children, style, showFloatingBack, ...props}) => (
  <View style={[styles.container, style]} {...props}>
    {children}
    {showFloatingBack && (
      <View style={styles.floatingBack}>
        <IconButton
          TouchableComponentProps={{
            hitSlop: {top: 25, left: 25, right: 25, bottom: 25},
          }}
          style={styles.floatingBackInnerContainer}
          onPress={showFloatingBack.goBack}
          icon={<LeftChevron style={styles.floatingBackIcon} />}
        />
      </View>
    )}
  </View>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: scale(24),
    backgroundColor: '#F1EEED',
  },
  floatingBack: {
    alignContent: 'center',
    justifyContent: 'center',
    width: scale(20),
    height: scale(20),
    top: scale(24),
    left: scale(24),
    position: 'absolute',
  },
  floatingBackInnerContainer: {
    width: scale(20),
    height: scale(20),
    backgroundColor: '#F1EEED',
  },
  floatingBackIcon: {
    width: scale(20),
    height: scale(20),
  },
});
