import React, {useCallback, useEffect, useState} from 'react';
import {Image, View} from 'react-native';
import {widthPercentageToDP} from '../../../utils/Scale';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {getTicketQrCode} from '../../../state/account/helpers';
import BasicLoadingIndicator from '../../../components/BasicLoadingIndicator';
import HGText from '../../../components/HGText';
export default ({id}) => {
  const [initialize, setInitialize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [base64, setBase64] = useState('');
  const {accessToken} = useSelector(state => ({
    accessToken: state.account.accessToken,
  }));

  const setup = useCallback(async () => {
    setLoading(true);
    try {
      const res = await getTicketQrCode(accessToken, id);
      const {qr_code} = res;
      if (qr_code) {
        setBase64(`data:image/png;base64,${qr_code}`);
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [id, accessToken]);

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      setup();
    }
  }, [initialize, setup]);
  return (
    <View style={{justifyContent: 'center', alignItems: 'center'}}>
      {loading && <BasicLoadingIndicator />}
      {!!base64 && (
        <Image
          style={{
            width: widthPercentageToDP(50),
            height: widthPercentageToDP(50),
          }}
          source={{uri: base64}}
        />
      )}
    </View>
  );
};
