import {useCallback, useEffect, useState} from 'react';
import MOCK_TICKETS from '../utils/mock_data/tickets.json';
import getOrderDetails from './getOrderDetails';

export default params => {
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tickets, setTickets] = useState([]);
  const {getOrders} = getOrderDetails();
  const [error, setError] = useState(null);

  const fetchTickets = useCallback(async () => {
    setLoaded(true);
    setLoading(true);
    try {
      let orders = await getOrders();

      orders = orders.map(order => ({
        ...order,
        event: order.items[0].event,
      }));

      setTickets(orders);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [getOrders]);

  useEffect(() => {
    if (!loaded && !loading) {
      fetchTickets();
    }
  }, [loaded, loading, fetchTickets]);

  return {tickets, loading};
};
