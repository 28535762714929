import React from 'react';
import {View, StyleSheet, ScrollView, Platform} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
import HGButton from '../../components/HGButton';
import useAccount from '../../hooks/useAccount';
import {useForm} from 'react-hook-form';
import HGTextInput from 'components/HGTextInput';
import Row from 'components/Row';
import useModal from '../../hooks/useModal';
import FrontNavigationHeader from '../../components/FrontNavigationHeader';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors, isDirty, isValid},
  } = useForm({mode: 'onChange'});
  const {resetPassword, loading} = useAccount();

  const watchFields = watch(['password']);

  const {showModal} = useModal();

  const onSubmit = async data => {
    const res = await resetPassword({
      ...data,
      resetToken: new RegExp(/token=(.*)/).exec(window.location.search)[1],
    });
    if (res.success) {
      showModal({
        title: t('Success'),
        body: t('Successfully changed password'),
        onClose: () => {
          showModal(null);
          navigation.replace('App');
        },
      });
    } else {
      showModal({
        title: t('Error'),
        body: t('errors.resetPassword'),
        onClose: () => showModal(null),
      });
    }
  };

  return (
    <>
      <FrontNavigationHeader
        showBack={true}
        navigation={{
          goBack: () => {
            if (Platform.OS === 'web' && window) {
              window.location.replace('/');
            }
            navigation.replace('App');
          },
        }}
        title={t('reset password')}
      />
      <ScrollView contentContainerStyle={{minHeight: '100%'}}>
        <Container>
          <View style={styles.formContainer}>
            <HGTextInput
              variant="form"
              isPasswordField={true}
              form={{
                control,
                name: 'password',
                rules: {
                  required: true,
                  minLength: {value: 8, message: t('errors.minLengthPassword')},
                },
              }}
              error={errors['password']}
              label={`${t('forms.newPassword')} (${t('Min Length')}: 8)`}
            />
            <HGTextInput
              variant="form"
              isPasswordField={true}
              form={{
                control,
                name: 'confirmPassword',
                rules: {
                  validate: val =>
                    (val && val === watchFields[0]) ||
                    t('errors.passwordMatch'),
                },
              }}
              error={errors['confirmPassword']}
              label={t('forms.confirmNewPassword')}
            />
          </View>
          <Row style={styles.buttonContainer}>
            <HGButton
              loading={loading}
              disabled={!isValid || !isDirty || loading}
              label={t('Update')}
              onPress={handleSubmit(onSubmit)}
            />
          </Row>
        </Container>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginTop: scale(14),
    alignItems: 'center',
  },
  formContainer: {
    marginTop: scale(55),
  },
  buttonContainer: {
    justifyContent: 'center',
    marginTop: scale(50),
    marginBottom: scale(70),
  },
});
