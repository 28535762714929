import React from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import Row from '../../../components/Row';
import HGButton from '../../../components/HGButton';
import {useTranslation} from 'react-i18next';
import {parseCurrency} from '../../../utils/Utils';

export default ({price, onPress, multiplePrices = false}) => {
  const {t} = useTranslation();
  return (
    <View style={styles.container}>
      <Row style={styles.row}>
        <View style={styles.divider}>
          <HGText variant="eventDetailsMeta">
            {multiplePrices && `${t('from')} `}
            {parseCurrency({amount: price})} / Person
          </HGText>
          <HGText>{t('Incl. VAT')}</HGText>
        </View>
        <View style={styles.divider}>
          <HGButton label={t('Tickets')} onPress={onPress} />
        </View>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    backgroundColor: '#DEDAD9',
    width: '100%',
    height: scale(100),
    borderTopLeftRadius: scale(10),
    borderTopRightRadius: scale(10),
    position: 'absolute',
    bottom: 0,
    paddingVertical: scale(24),
    paddingHorizontal: scale(24),
  },
  row: {
    width: '100%',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
  },
});
