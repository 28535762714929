import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import {useSelector} from 'react-redux';
import ProgressPills from 'components/ProgressPills';
import Container from 'components/Container';
import {scale} from 'utils/Scale';
import Subtotal from 'components/Subtotal';
import TwoActionButtons from 'components/TwoActionButtons';
import {useTranslation} from 'react-i18next';
import {MOCK_COUNTER_DATA} from 'utils/mock_data';
import CheckoutItemCard from './components/CheckoutItemCard';
import getCounterProducts from '../../hooks/getCounterProducts';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import useCounterCheckout from '../../hooks/useCounterCheckout';
import {calculateTaxAmount} from '../../utils/Utils';

export default ({navigation, route}) => {
  const [checkLastOrder, setCheckLastOrder] = useState(
    !route.params?.getLastCheckoutOrder,
  );
  const {t, i18n} = useTranslation();
  const loggedIn = useSelector(state => state.account.loggedIn);
  const {reinstateLastOrder, cartItem:cart} = useCounterCheckout();
  const {products, loading} = getCounterProducts();
  const productList = {};
  products.forEach(({id, ...product}) => (productList[id] = product));

  const totalPrice = Object.keys(cart.items)
    .map(key => cart.items[key] * productList[key]?.price ?? 0)
    .reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (!checkLastOrder) {
      setCheckLastOrder(true);
      reinstateLastOrder();
    }
  }, [checkLastOrder, reinstateLastOrder]);

  const calculateRates = () => {
    let rates = [];
    Object.keys(cart.items).forEach(productId => {
      const qty = cart.items[productId];
      if (productList[productId]){
        const {price, vat_rate} = productList[productId];
  
        if (vat_rate > 0) {
          const subPrice = qty * price;
          rates = calculateTaxAmount(rates, vat_rate, subPrice);
        }
      }
    });

    return rates;
  };

  return (
    <ScrollView contentContainerStyle={{minHeight: '100%'}}>
      <Container>
        <View style={styles.progressContainer}>
          <ProgressPills total={3} current={1} />
        </View>
        <View>
          {loading && <BasicLoadingIndicator />}
          {!loading &&
            Object.keys(products).length > 0 &&
            Object.keys(cart.items).map((key, i) => {
              const item = productList[key];
              const {title = {}, subtitle = {}, price, stock_qty} = item || {};
              return (
                <View
                  key={key}
                  style={{
                    marginBottom:
                      i === Object.keys(cart.items).length - 1 ? 0 : scale(13),
                  }}>
                  <CheckoutItemCard
                    itemKey={key}
                    name={title[i18n.language] ?? title.de ?? title.en}
                    option={
                      subtitle[i18n.language] ?? subtitle.de ?? subtitle.en
                    }
                    quantity={cart.items[key]}
                    price={price}
                    stock={stock_qty}
                  />
                </View>
              );
            })}
        </View>
        <View style={styles.subtotalContainer}>
          <Subtotal total={totalPrice} vatRates={calculateRates()} />
        </View>
        <TwoActionButtons
          buttons={[
            {label: t('Add more'), onPress: navigation.goBack},
            {
              label: t('Continue'),
              loading: loading,
              disabled: !totalPrice || totalPrice === 0 || loading,
              onPress: () => {
                if (loggedIn) {
                  navigation.navigate('CounterCheckoutTimeslot');
                } else {
                  navigation.navigate('CheckoutLogin', {
                    screenType: 'checkout',
                    redirect: 'CounterCheckoutTimeslot',
                  });
                }
              },
            },
          ]}
        />
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  subtotalContainer: {
    marginTop: scale(24),
    marginBottom: scale(50),
  },
});
