import React from 'react';
import {View, TextInput, StyleSheet} from 'react-native';
import {scale} from '../../utils/Scale';
import {Search} from 'components/HGIcon';
import {useTranslation} from 'react-i18next';
import {Cross} from '../HGIcon';
import {TouchableOpacity} from 'react-native-gesture-handler';

export default ({
  containerStyle,
  placeholder,
  onClear,
  loading,
  value,
  ...props
}) => {
  const {t} = useTranslation();
  return (
    <View style={[styles.container, containerStyle]}>
      <Search style={styles.icon} />
      <TextInput
        {...props}
        style={styles.input}
        placeholder={
          loading
            ? 'Loading...'
            : placeholder || t('components.SearchBar.placeholder')
        }
        value={value}
        disabled={loading}
      />
      {onClear && value ? (
        <TouchableOpacity onPress={onClear}>
          <Cross style={styles.clearIcon} />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  icon: {marginRight: scale(12)},
  input: {fontSize: scale(17), height: scale(50), width: '100%'},
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: scale(25),
    borderColor: '#B9B9B9',
    backgroundColor: '#FFF',
    height: scale(50),
    paddingVertical: scale(14),
    paddingHorizontal: scale(16),
  },
  clearIcon: {
    width: scale(10),
    height: scale(10),
  },
});
