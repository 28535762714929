import {CardContainer} from 'components/Card';
import {Qr} from 'components/HGIcon';
import HGText from 'components/HGText';
import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import {DateTime} from 'luxon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, TouchableOpacity, View, Image} from 'react-native';
import {scale} from 'utils/Scale';
import {ordinal, parseDisplayDate} from 'utils/Utils';
import TicketQRCode from '../../../components/TicketQRCode';

export default ({uuid, eventName, date, eventStart, admission, onPress}) => {
  const {t, i18n} = useTranslation();
  return (
    <CardContainer padded={true}>
      <Row style={styles.headerContainer}>
        <View style={styles.qrContainer}>
          <Image
            source={require('../../../../assets/images/qr-clickme_50.png')}
            style={{width: scale(60), height: scale(60)}}
          />
        </View>
        <View>
          <HGText fontSize={scale(15)}>
            {parseDisplayDate(date, i18n.language)}
          </HGText>
          <HGText fontSize={scale(15)}>
            {`${t('admission')}: ${admission}${
              i18n.language === 'de' ? ' Uhr' : ''
            }`}
          </HGText>
          <HGText fontSize={scale(15)}>{`${t('Begin')}: ${eventStart}${
            i18n.language === 'de' ? ' Uhr' : ''
          }`}</HGText>
        </View>
      </Row>
      <View style={styles.divider} />
      <HGText fontSize={scale(14)} variant="header" upperCase={true}>
        {eventName}
      </HGText>
      <Row style={styles.infoRow}>
        <TouchableOpacity onPress={onPress}>
          <HGText variant="link" fontSize={18}>
            {t('Info')}
          </HGText>
        </TouchableOpacity>
      </Row>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
  },
  qrContainer: {
    marginRight: scale(17),
  },
  qrIcon: {
    width: scale(41),
    height: scale(41),
  },
  divider: {
    marginTop: scale(12),
    marginBottom: scale(19),
    borderTopWidth: 1,
    borderColor: hexToRgba('#808080', 0.5),
  },
  infoRow: {
    justifyContent: 'flex-end',
    marginTop: scale(14),
  },
});
