import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';
import {scale} from 'utils/Scale';
import DateTimePriceCard from '../EventDetails/components/DateTimePriceCard';
import DetailsCard from '../EventDetails/components/DetailsCard';
import GeneralInformationCard from './components/GeneralInformationCard';
import RegisteredVisitorCard from './components/RegisteredVisitorCard';

export default ({tickets, event}) => {
  const {t, i18n} = useTranslation();
  return (
    <View>
      <View style={styles.cardContainer}>
        <DateTimePriceCard
          date={event.date_at}
          time={event.admission_at}
          startTime={event.start_at}
          price={event.data.price}
        />
      </View>
      <View style={styles.cardContainer}>
        <DetailsCard
          title={t('description')}
          body={event.content[i18n.language]}
        />
      </View>
      <View style={styles.cardContainer}>
        <RegisteredVisitorCard
          visitors={tickets.map(
            ({visitor: {lastName, firstName}}, i) =>
              `${i + 1}. ${firstName} ${lastName}`,
          )}
          rawVisitors={tickets.map(({visitor}) => visitor)}
        />
      </View>
      <View style={styles.cardContainer}>
        <GeneralInformationCard
          directions={event.directions ?? ''}
          parking={event.parking ?? ''}
          coordinates={event.data.coordinates}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    marginBottom: scale(13),
  },
});
