import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {View, StyleSheet, Image} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGImage from '../HGImage';
import HGText from '../HGText';

export const CardContainer = ({
  containerStyle,
  padded,
  densePadded,
  children,
  containerViewProps,
  disableBottomPadded,
}) => (
  <View
    style={[
      styles.container,
      padded ? styles.paddedContainer : {},
      densePadded ? styles.densedPadded : {},
      containerStyle,
      disableBottomPadded ? styles.disableBottomPadded : {},
    ]}
    {...containerViewProps}>
    {children}
  </View>
);

export default ({
  containerStyle,
  subtitle,
  title,
  image,
  footerText,
  largeImage,
  containerViewProps = {},
}) => (
  <CardContainer
    containerStyle={containerStyle}
    containerViewProps={containerViewProps}>
    <HGImage
      source={{uri: image}}
      resizeMode="cover"
      style={largeImage ? styles.largeImage : styles.image}
    />
    <View style={styles.titleContainer}>
      <View>
        {typeof subtitle === 'string' ? (
          <HGText variant="meta">{subtitle}</HGText>
        ) : typeof subtitle === 'object' ? (
          subtitle
        ) : (
          subtitle()
        )}
      </View>
      <View>
        <HGText variant="smallHeader">{title.toUpperCase()}</HGText>
      </View>
      <View style={{flex: 1}} />
      {footerText && (
        <View>
          <HGText style={styles.footerText}>{footerText}</HGText>
        </View>
      )}
    </View>
  </CardContainer>
);

export const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    backgroundColor: '#FFF',
    borderRadius: scale(10),
    borderWidth: 1,
    borderColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
  },
  paddedContainer: {
    paddingHorizontal: scale(32),
    paddingVertical: scale(26),
  },
  densedPadded: {
    paddingHorizontal: scale(22.5),
    paddingVertical: scale(11),
  },
  image: {
    height: scale(144),
    width: 'auto',
  },
  largeImage: {
    height: scale(184),
    width: 'auto',
  },
  titleContainer: {padding: scale(9), flex: 1},
  title: {},
  subtitle: {},
  footerText: {color: '#808080'},
  disableBottomPadded: {paddingBottom: 0},
});
