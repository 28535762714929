import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {useTranslation} from 'react-i18next';
import PaymentPending from '../views/PaymentPending';
import PaymentWebView from '../views/PaymentPending/PaymentWebView';

const Stack = createStackNavigator();
export default ({}) => {
  const {t} = useTranslation();
  return (
    <Stack.Navigator initialRouteName="OrderPendingPayment" headerMode="none">
      <Stack.Screen name="OrderPendingPayment" component={PaymentPending} />
      <Stack.Screen name="PaymentWebView" component={PaymentWebView} />
    </Stack.Navigator>
  );
};
