import {View, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import React from 'react';

import {useTranslation} from 'react-i18next';
import hexToRgba from 'hex-to-rgba';

import {scale, widthPercentageToDP} from '../../utils/Scale';
import {parseCurrency} from '../../utils/Utils';

import {CardContainer} from 'components/Card';
import {
  Bar,
  ExclamationCircle,
  GreenCheckCircle,
  IdentityCard,
  TicketFill,
  IdCard,
  Warning,
} from 'components/HGIcon';
import Row from 'components/Row';
import HGText from '../HGText';

const TicketCardContainer = ({
  visitor,
  header,
  body,
  footer,
  isOptionCard = false,
}) => {
  const {t, i18n} = useTranslation();
  return (
    <View>
      <View style={[styles.cutout, styles.leftCutout]} />
      <View style={[styles.cutout, styles.rightCutout]} />
      <CardContainer
        padded={true}
        containerStyle={isOptionCard ? styles.optionCard : styles.card}
        disableBottomPadded={isOptionCard ? false : true}>
        <Row style={styles.headerContainer}>
          <Row style={styles.cardHeader}>{header}</Row>
        </Row>
        <View style={styles.divider} />
        {body}
        {!isOptionCard && (
          <>
            {!visitor && (
              <Row style={styles.warningContainer}>
                <HGText style={styles.warningText}>
                  {t('AssignVisitor.WarningText')}
                </HGText>
                <Warning />
              </Row>
            )}
          </>
        )}
      </CardContainer>
      {footer}
    </View>
  );
};

export const TicketOptionCard = ({quantity, unit, price}) => {
  const {t, i18n} = useTranslation();

  const totalPrice = quantity * price;

  return (
    <TicketCardContainer
      header={
        <>
          <Bar />
          <HGText
            upperCase={true}
            fontSize={scale(14)}
            variant="header"
            color="primary">
            {unit[i18n.language] ?? '-'}
          </HGText>
        </>
      }
      body={
        <>
          <Row>
            <HGText variant="eventDetailsMeta">{t('Number')}</HGText>
            <View style={styles.spacing} />
            <HGText variant="eventDetailsMeta">{t('Amount')}</HGText>
          </Row>
          <Row>
            <HGText variant="eventDetailsMeta" color="#808080">
              {quantity} {unit[i18n.language] ?? '-'}
            </HGText>
            <View style={styles.spacing} />
            <HGText variant="eventDetailsMeta" color="#808080">
              {parseCurrency({amount: totalPrice})}
            </HGText>
          </Row>
        </>
      }
      isOptionCard={true}
    />
  );
};

export const TicketCard = ({ticketType, onAssign, visitor}) => {
  const {t, i18n} = useTranslation();
  return (
    <TicketCardContainer
      visitor={visitor}
      header={
        <>
          <TicketFill style={styles.icon} />
          <HGText
            upperCase={true}
            fontSize={scale(14)}
            variant="header"
            color="primary">
            {ticketType?.title[i18n.language] ?? t('seat')}
          </HGText>
          <View style={styles.spacing} />
          {visitor ? (
            <GreenCheckCircle
              style={Platform.OS === 'web' ? styles.webIcon : {}}
            />
          ) : (
            <ExclamationCircle
              style={Platform.OS === 'web' ? styles.webIcon : {}}
            />
          )}
        </>
      }
      body={
        <>
          <Row>
            <HGText variant="eventDetailsMeta">{t('Price/ Discount')}</HGText>
            <View style={styles.spacing} />
            <HGText variant="eventDetailsMeta">{t('Amount')}</HGText>
          </Row>
          <Row style={styles.priceContainer}>
            <HGText variant="eventDetailsMeta" color="#808080">
              {t('Sale')}
            </HGText>
            <View style={styles.spacing} />
            <HGText variant="eventDetailsMeta" color="#808080">
              {parseCurrency({amount: ticketType?.price})}
            </HGText>
          </Row>
          {/* <Row>
            <HGText variant="eventDetailsMeta">{t('Visitor')}</HGText>
            <View style={styles.spacing} />
            {visitor && (
              <TouchableOpacity onPress={onAssign}>
                <HGText variant="eventDetailsMeta" style={styles.changeLink}>
                  {t('Change')}
                </HGText>
              </TouchableOpacity>
            )}
          </Row> */}
          {/* {!visitor && (
            <TouchableOpacity onPress={onAssign}>
              <Row style={styles.assignContainer}>
                <IdentityCard style={styles.assignIcon} />
                <HGText color="primary">{t('Assign person (binding)')}</HGText>
              </Row>
            </TouchableOpacity>
          )} */}
          {/* {visitor && (
            <View>
              <HGText color="#808080">
                {visitor.firstName} {visitor.lastName}
              </HGText>
            </View>
          )} */}
        </>
      }
      footer={
        <>
          <TouchableOpacity onPress={onAssign}>
            <Row style={styles.footerContainer}>
              <IdCard
                style={{
                  backgroundColor: '#FFF',
                  width: scale(26),
                  height: scale(22),
                }}
              />
              {!visitor && (
                <HGText style={styles.footerText}>
                  {t('Assign person (binding)')}
                </HGText>
              )}
              {visitor && (
                <>
                  <HGText color="#808080" style={styles.footerText}>
                    {visitor.firstName} {visitor.lastName}
                  </HGText>
                </>
              )}
            </Row>
          </TouchableOpacity>
        </>
      }
    />
  );
};

const styles = StyleSheet.create({
  card: {
    borderWidth: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  optionCard: {
    borderWidth: 0,
  },
  cutout: {
    height: scale(22),
    width: scale(22),
    backgroundColor: '#F1EEED',
    position: 'absolute',
    borderRadius: scale(11),
    zIndex: 999,
    top: scale(55),
  },
  leftCutout: {
    left: scale(-11),
  },
  rightCutout: {
    right: scale(-11),
  },
  spacing: {flex: 1},
  headerContainer: {
    flex: 1,
    alignItems: 'center',
  },
  cardHeader: {
    flex: 1,
    paddingLeft: scale(13),
    alignItems: 'center',
  },
  icon: {
    transform: [{rotate: '-35deg'}],
  },
  divider: {
    marginLeft: scale(-10),
    width: widthPercentageToDP(75),
    marginVertical: scale(17),
    borderTopWidth: 1,
    borderStyle: 'dashed',
    borderTopColor: hexToRgba('#707070', 0.25),
  },
  priceContainer: {
    marginBottom: scale(15),
  },
  changeLink: {
    color: '#1F7904',
    textDecorationLine: 'underline',
    // paddingTop: scale(8),
    marginLeft: scale(10),
    fontWeight: 'bold',
  },
  assignContainer: {
    marginTop: scale(8),
    alignItems: 'center',
  },
  assignIcon: {
    width: scale(20),
    height: scale(15),
    marginRight: scale(11),
  },
  webIcon: {
    width: scale(22),
    height: scale(22),
  },
  warningContainer: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: '#FFF',
    marginTop: scale(10),
  },
  warningText: {
    marginRight: scale(10),
    fontWeight: 'bold',
  },
  footerContainer: {
    paddingHorizontal: scale(32),
    paddingVertical: scale(10),
    backgroundColor: '#990729',
    flex: 1,
    borderBottomLeftRadius: scale(10),
    borderBottomRightRadius: scale(10),
    alignItems: 'center',
  },
  footerText: {
    marginLeft: scale(10),
    color: '#FFF',
  },
});
