import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Platform,
} from 'react-native';
import {scale} from '../../../utils/Scale';
import {Camera, UserFill} from 'components/HGIcon/';
import HGText from '../../../components/HGText';
import IconButton from 'components/IconButton';
import useAccount from 'hooks/useAccount';
import {useSelector} from 'react-redux';
import ImageCropWrapper from 'components/ImageCropWrapper';

const Avatar = ({hideUpload, hideName, size}) => {
  const {updateAvatar} = useAccount();
  const avatar = useSelector(state => state.account.avatar);
  const user = useSelector(state => state.account.user);

  const onUpdateAvatar = res => {
    if (Platform.OS === 'web') {
      if (res) {
        //res here is a blob
        updateAvatar({avatar: res});
      }
    } else {
      if (res && res.path) {
        updateAvatar({avatar: res.path});
      }
    }
  };
  return (
    <>
      <View
        style={[
          styles.container,
          size ? {width: size, height: size, borderRadius: size / 2} : {},
        ]}>
        {avatar ? (
          <Image
            style={[
              styles.customAvatar,
              size ? {width: size * 0.9, height: size * 0.9} : {},
            ]}
            source={{uri: avatar}}
          />
        ) : (
          <UserFill style={styles.avatar} />
        )}
        {!hideUpload && (
          <View style={styles.cameraButtonContainer}>
            <IconButton
              TouchableComponent={ImageCropWrapper}
              TouchableComponentProps={{
                getImageCb: onUpdateAvatar,
                width: 300,
                height: 300,
                cropping: true,
                front: true,
                types: ['image/*'],
              }}
              style={styles.cameraButton}
              icon={<Camera />}
            />
          </View>
        )}
      </View>
      {!hideName && (
        <View style={styles.nameContainer}>
          <HGText variant="header" style={styles.name}>
            {`${user?.first_name} ${user?.last_name}`.toUpperCase()}
          </HGText>
        </View>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    width: scale(120),
    borderRadius: scale(60),
    height: scale(120),
  },
  avatar: {
    height: '47.4%',
    width: '41.4%',
  },
  customAvatar: {
    height: scale(108),
    width: scale(108),
    borderRadius: scale(54),
  },
  nameContainer: {marginTop: 13},
  name: {
    fontSize: scale(17),
  },
  cameraButtonContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  cameraButton: {
    elevation: 2,
    shadowColor: 'black',
    shadowOpacity: 0.2,
    shadowRadius: 10,
    shadowOffset: {
      width: 0,
      height: scale(-9),
    },
  },
});

export default Avatar;
