import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import HGText from '../../../components/HGText';
import {scale} from '../../../utils/Scale';

export const UserNavigationButton = ({
  icon,
  label,
  labelStyle = {},
  endLabel = null,
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress || (() => {})}>
      <View style={styles.buttonContainer}>
        <View style={styles.iconContainer}>{icon}</View>
        <View style={{flex: 1}}>
          <HGText variant="header" style={[styles.label, labelStyle]}>
            {label?.toUpperCase()}
          </HGText>
        </View>
        {endLabel && (
          <View>
            <HGText capitalize style={[styles.endLabel]}>
              {endLabel}
            </HGText>
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: scale(20),
    paddingVertical: scale(15),
    borderRadius: scale(10),
    height: scale(50),
  },
  iconContainer: {
    width: scale(32),
    marginRight: scale(21),
  },
  label: {
    fontSize: scale(13),
  },
  endLabel: {
    color: '#808080',
  },
});

export default UserNavigationButton;
