import {Platform} from 'react-native';

import {DateTime} from 'luxon';
import {getAllCountries} from 'react-native-country-picker-modal';
import {configureStore} from '@reduxjs/toolkit';

export function ordinal(n) {
  var s = ['th', 'st', 'nd', 'rd'];
  var v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

export function parseDisplayDate(date, locale = 'de') {
  return DateTime.fromJSDate(new Date(date)).toFormat(`EEE, d. MMM yyyy`, {
    locale: locale,
  });
}

export function parseCurrency({amount, currency = 'EUR', locale = 'de'}) {
  const localeMap = {
    de: 'de-DE',
  };
  return `${new Intl.NumberFormat(localeMap[locale], {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(amount)}`;
}

export function checkIfImageURL(url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
}

export function getTimeslotMap(timeslot) {
  const TIMESLOT_MAP = {
    before: 'BEFORE_EVENT',
    possible: 'ASAP',
    break: 'IN_THE_BREAK',
    after: 'AFTER_EVENT',
  };

  const TIMESLOT_MAP_REVERSE = {};
  Object.keys(TIMESLOT_MAP).map(key => {
    TIMESLOT_MAP_REVERSE[TIMESLOT_MAP[key]] = key;
  });

  return TIMESLOT_MAP[timeslot] || TIMESLOT_MAP_REVERSE[timeslot];
}

export function orderTimeMap(orderTime) {
  switch (orderTime) {
    case 'asap':
      return 'possible';
    case 'after_event':
      return 'after';
    case 'before_event':
      return 'before';
    case 'in_the_break':
      return 'break';
  }
}

export function getQueryParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export function replaceWebUrl(newUrl = '/') {
  if (Platform.OS !== 'web') return;
  window.history.pushState('-', '', newUrl);
}

export function validateCountryCode(countryCode) {
  return getAllCountries().then(countries => {
    return countries.some(country => country.cca2 === countryCode);
  });
}

export function calculateTaxAmount(rates = [], vatRate, price) {
  const rt = vatRate / 100;

  if (vatRate > 0) {
    const totalRate = (price / (rt + 1)) * rt;
    rates.push({vat_rate: parseInt(vatRate), total: totalRate});
  }

  let result = [];
  if (rates.length > 0) {
    let group = rates.reduce((r, a) => {
      r[a.vat_rate] = [...(r[a.vat_rate] || []), a];
      return r;
    }, {});

    Object.entries(group).forEach(entry => {
      const [key, value] = entry;
      const sum = value.reduce((accumulator, object) => {
        return accumulator + object.total;
      }, 0);
      result.push({vat_rate: parseInt(key), total: sum});
    });

    result = result.filter(x => x.total > 0);
  }

  return result;
}
