// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Nobel.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* These styles make the body full-height */\r\nhtml,\r\nbody {\r\n  height: 100%;\r\n}\r\n/* These styles disable body scrolling if you are using <ScrollView> */\r\nbody {\r\n  position: fixed;\r\n  width: 100%;\r\n  overflow: hidden;\r\n}\r\n/* These styles make the root element full-height */\r\n#root {\r\n  display: flex;\r\n  height: 100%;\r\n}\r\n@font-face {\r\n  font-family: Nobel;\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n}\r\n", "",{"version":3,"sources":["webpack://./assets/web/styles.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;;EAEE,YAAY;AACd;AACA,sEAAsE;AACtE;EACE,eAAe;EACf,WAAW;EACX,gBAAgB;AAClB;AACA,mDAAmD;AACnD;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,4CAA4B;AAC9B","sourcesContent":["/* These styles make the body full-height */\r\nhtml,\r\nbody {\r\n  height: 100%;\r\n}\r\n/* These styles disable body scrolling if you are using <ScrollView> */\r\nbody {\r\n  position: fixed;\r\n  width: 100%;\r\n  overflow: hidden;\r\n}\r\n/* These styles make the root element full-height */\r\n#root {\r\n  display: flex;\r\n  height: 100%;\r\n}\r\n@font-face {\r\n  font-family: Nobel;\r\n  src: url(../fonts/Nobel.ttf);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
