import React from 'react';
import {View} from 'react-native';
import {heightPercentageToDP, scale} from '../../utils/Scale.js';
import Component from './ChangeAppLanguage.js';
export default props => {
  return (
    <View style={{height: heightPercentageToDP(100)}}>
      <Component {...props} />
    </View>
  );
};
