import React from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import Row from '../../../components/Row';
import HGButton from '../../../components/HGButton';
import {useTranslation} from 'react-i18next';
import {parseCurrency} from '../../../utils/Utils';

export default ({price, noOfTickets, onPress, vatRates = []}) => {
  const {t} = useTranslation();
  return (
    <View style={styles.container}>
      <Row style={styles.row}>
        <View style={styles.divider}>
          <HGText variant="eventDetailsMeta">
            {t('total')}
            {parseCurrency({amount: price})}
          </HGText>
          {/* <HGText color="primary">{t('Incl. VAT')} 19%</HGText> */}
          {vatRates.length > 0 &&
            vatRates.map(({vat_rate, total}) => (
              <HGText color="primary">
                {t('Incl. VAT')} {vat_rate}% - {parseCurrency({amount: total})}
              </HGText>
            ))}
        </View>
        <View style={styles.divider}>
          <HGButton
            disabled={noOfTickets <= 0}
            label={t('Next')}
            onPress={onPress}
          />
        </View>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    height: scale(100),
  },
  row: {
    width: '100%',
    alignItems: 'center',
  },
  divider: {
    flex: 1,
  },
});
