import React from 'react';
import {StyleSheet, View} from 'react-native';
import Row from 'components/Row';
import {scale} from 'utils/Scale';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import {parseCurrency} from '../../utils/Utils';

export default ({label, total, vatRates = []}) => {
  const {t} = useTranslation();
  return (
    <Row style={styles.container}>
      <View style={styles.fullWidth}>
        <HGText fontSize={scale(22)}>
          {label === 'total' ? t('Total') : t('Subtotal')}
        </HGText>
        {/* <HGText color="primary">{t('Incl. VAT')} 19%</HGText> */}
        {vatRates.length > 0 &&
          vatRates.map(({vat_rate, total}) => (
            <HGText color="primary">
              {t('Incl. VAT')} {vat_rate}% - {parseCurrency({amount: total})}
            </HGText>
          ))}
      </View>
      <HGText fontSize={scale(22)}>{parseCurrency({amount: total})}</HGText>
    </Row>
  );
};

const styles = StyleSheet.create({
  fullWidth: {
    flex: 1,
  },
  container: {
    alignItems: 'flex-start',
  },
});
