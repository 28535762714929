import React from 'react';
import {Share, StyleSheet, View} from 'react-native';
import HGImageBackground from '../HGImageBackground';
import IconButton from '../IconButton';
import Row from '../Row';
import {heightPercentageToDP, scale} from 'utils/Scale';
import * as HGIcon from 'components/HGIcon';
import {useNavigation} from '@react-navigation/native';
import config from '../../config';
import {useTranslation} from 'react-i18next';

export default ({image, shareLink}) => {
  const {t} = useTranslation();
  const navigation = useNavigation();
  return (
    <HGImageBackground
      source={{uri: image}}
      style={styles.image}
      resizeMode="cover">
      <Row style={styles.container}>
        <Row style={styles.backButtonContainer}>
          <IconButton
            onPress={navigation.goBack}
            icon={<HGIcon.LeftChevron style={styles.icon} />}
          />
        </Row>
        <Row>
          <IconButton
            style={styles.shareIconContainer}
            icon={<HGIcon.Share style={styles.shareIcon} />}
            onPress={() => {
              if (!shareLink) return;
              const subject = t('shareEventTitle');
              const msgBody = `${t('shareEventMsg1')}\n\n${shareLink}\n\n${t(
                'shareEventMsg2',
              )}`;
              Share.share(
                {
                  message: msgBody,
                  title: subject,
                },
                {
                  dialogTitle: subject,
                  subject: subject,
                },
              ).catch(err => {
                // if user dismiss in IOS will shoot out an error
              });
            }}
          />
          {!config.DISABLE_FAVOURITES && (
            <IconButton icon={<HGIcon.Heart style={styles.favouriteIcon} />} />
          )}
        </Row>
      </Row>
    </HGImageBackground>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: scale(18),
    paddingHorizontal: scale(16),
  },
  backButtonContainer: {
    flex: 1,
  },
  shareIconContainer: {
    marginRight: scale(20),
  },
  shareIcon: {
    width: scale(20),
    height: scale(25),
  },
  favouriteIcon: {
    width: scale(20),
    height: scale(22),
  },
  icon: {
    width: scale(20),
    height: scale(20),
  },
  image: {
    width: '100%',
    height: heightPercentageToDP(33),
  },
});
