import {useSelector} from 'react-redux';

import axios from 'axios';
import config from '../config';

export default () => {
  const accessToken = useSelector(state => state.account.accessToken);

  const claimTicket = async ({ticketId}) => {
    // NOTE: Previously claim ticket is separated to web and app, I am not sure why
    return new Promise((resolve, reject) =>
      axios
        .get(`${config.ENDPOINT_CLAIM_TICKET}/${ticketId}`, {
          headers: {
            'X-HG-PWA-Origin': config.PWA_ORIGIN,
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then(res => {
          resolve({
            success: true,
            ticketId: res.data.order_item_id,
          });
        })
        .catch(err => {
          const json = err.toJSON();
          resolve({success: false, error: json.message});
        }),
    );
  };

  const generateClaimURL = async ({ticketId}) => {
    return new Promise((resolve, reject) =>
      axios
        .get(config.ENDPOINT_GENERATE_CLAIM_URL(ticketId), {
          headers: {
            'X-HG-PWA-Origin': config.PWA_ORIGIN,
            Authorization: 'Bearer ' + accessToken,
          },
        })
        .then(res => {
          resolve({success: true, data: res.data});
        })
        .catch(err => {
          resolve({success: false});
        }),
    );
  };

  return {claimTicket, generateClaimURL};
};
