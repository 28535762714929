import React, {useCallback, useEffect, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {CardContainer} from '../../../components/Card';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {ordinal, parseCurrency} from '../../../utils/Utils';
import Row from '../../../components/Row';
import Colors from '../../../utils/Colors';
import QuantityStepInput from '../../../components/QuantityStepInput';

export default ({ticketTypes, onChange}) => {
  const {t, i18n} = useTranslation();
  const [tickets, _setTickets] = useState({});

  const setTickets = useCallback(
    (id, amount, add) => {
      _setTickets({...tickets, [id]: amount});
      onChange({...tickets, [id]: amount});
    },
    [tickets, onChange],
  );
  return (
    <CardContainer padded={true}>
      <HGText variant="header" style={styles.header}>
        {t('select tickets')}
      </HGText>
      {ticketTypes.map(({id, title, price, stock_qty}) => (
        <Row style={styles.row} key={id}>
          <View style={styles.fullWidth}>
            <HGText style={styles.title}>
              {title[i18n.language] ? title[i18n.language] : t('Seat')}
            </HGText>
            <HGText variant="meta" style={styles.meta}>
              {parseCurrency({amount: price})} / Person
            </HGText>
          </View>
          <View>
            <QuantityStepInput
              minValue={0}
              onChange={setTickets.bind(null, id)}
              stock={stock_qty}
              isStockCheckMsgVisible={true}
              isTicket={true}
            />
          </View>
        </Row>
      ))}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: scale(18),
    marginBottom: scale(13),
    textTransform: 'uppercase',
  },
  title: {
    fontSize: scale(17),
  },
  meta: {color: Colors.PRIMARY_COLOR},
  fullWidth: {flex: 1},
  row: {
    alignItems: 'center',
    marginBottom: scale(10),
  },
});
