import Container from 'components/Container';
import HGText from 'components/HGText';
import RadioButton from 'components/RadioButton';
import Row from 'components/Row';
import TwoActionButtons from 'components/TwoActionButtons';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ScrollView,
  Share,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {scale} from 'utils/Scale';
import ShareTicketCard from './components/ShareTicketCard';
import _claimTicket from '../../hooks/claimTicket';
import useModal from '../../hooks/useModal';

export default ({navigation, route}) => {
  const {tickets, event} = route.params;
  const [active, setActive] = useState(-1);
  const {generateClaimURL} = _claimTicket();
  const {t} = useTranslation();
  const {showModal} = useModal();

  const setActiveShare = i => {
    setActive(i);
  };

  const onShareButtonPressHandler = async () => {
    const ticket = tickets[active];

    if (ticket) {
      const results = await generateClaimURL({ticketId: ticket.id});

      if (!results.success) {
        console.log(results);
        showModal({title: t('tickets.share_error')});
        return;
      }

      const claimURL = results.data.claim_url;
      const subject = t('shareTicketTitle');
      const msgBody = `${t('shareTicketMsg1')}\n\n${claimURL}\n\n${t(
        'shareTicketMsg2',
      )}`;

      Share.share(
        {
          message: msgBody,
          title: subject,
        },
        {
          dialogTitle: subject,
          subject: subject,
        },
      ).catch(err => {
        // if user dismiss in IOS will shoot out an error
      });

      // Share.share({
      //   message: claimURL,
      // }).catch(err => {
      //   // if user dismiss in IOS will shoot out an error
      // });
    }
  };

  return (
    <Container>
      <ScrollView>
        <View style={styles.instructionContainer}>
          <HGText fontSize={scale(17)}>{t('tickets.share')}</HGText>
        </View>
        {tickets.map(({visitor, price}, i) => {
          return (
            <Row
              style={styles.cardContainer}
              key={`${visitor.firstName}-${visitor.lastName}-${i}`}>
              <TouchableOpacity onPress={setActiveShare.bind(null, i)}>
                <RadioButton active={active === i} />
              </TouchableOpacity>
              <View style={styles.card}>
                <ShareTicketCard visitor={visitor} price={price} />
              </View>
            </Row>
          );
        })}
        <View style={styles.actionButtons}>
          <TwoActionButtons
            buttons={[
              {
                label: t('Cancel'),
                onPress: () => {
                  navigation.pop();
                },
              },
              {
                label: t('Share'),
                variant: 'secondary',
                onPress: onShareButtonPressHandler,
                disabled: active === -1,
              },
            ]}
          />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  instructionContainer: {
    paddingHorizontal: scale(28),
    marginTop: scale(50),
    marginBottom: scale(34),
  },
  cardContainer: {
    marginBottom: scale(14),
  },
  card: {
    marginLeft: scale(20),
    flex: 1,
  },
  actionButtons: {
    marginTop: scale(6),
    marginBottom: scale(70),
  },
});
