import axios from 'axios';
import {Platform} from 'react-native';
import config from '../../config';
import getUtmDetails from '../../hooks/getUtmDetails';

const {getUtm} = getUtmDetails();

const getAvatar = async token => {
  const utmString = await getUtm();
  const avatarRes = await fetch(
    `${config.ENDPOINT_DOCUMENTS}?type=profile_picture${
      utmString && `&${utmString}`
    }`,
    {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        ...(Platform.OS === 'web'
          ? {
              'X-HG-PWA-Origin': config.PWA_ORIGIN,
            }
          : {}),
      },
    },
  );
  const avatar = await avatarRes.json();
  return avatar.length > 0 ? avatar[avatar.length - 1].url : null;
};

export const loginUser = async ({username, password}) => {
  try {
    const utmString = await getUtm();
    const res = await fetch(
      `${config.ENDPOINT_LOGIN}${utmString && `?${utmString}`}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(Platform.OS === 'web'
            ? {
                'X-HG-PWA-Origin': config.PWA_ORIGIN,
              }
            : {'X-SKIP-DEVICE': ''}),
        },
        body: JSON.stringify({
          signin: {
            email: username,
            password,
          },
        }),
      },
    );
    if (res.status === 400) {
      // invalid username / password? Failed to login
      throw Error('fail_login');
    }
    const json = await res.json();
    const avatar = await getAvatar(json.access_token);

    // get latest profile image
    return {
      id: json.id,
      accessToken: json.access_token,
      refreshToken: json.refresh_token,
      avatar,
      user: {email: json.email, ...json.profile},
    };
  } catch (err) {
    return {success: false, error: err};
  }
};

export const registerUser = async ({firstName, lastName, email, password}) => {
  try {
    const utmString = await getUtm();
    const res = await fetch(
      `${config.ENDPOINT_REGISTER}${utmString && `?${utmString}`}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          // 'X-DEVICE-ID': config.DEVICE_ID,
          ...(Platform.OS === 'web'
            ? {
                'X-HG-PWA-Origin': config.PWA_ORIGIN,
              }
            : {}),
        },
        body: JSON.stringify({
          user_account: {
            email,
            password,
            password_confirmation: password,
            profile: {
              first_name: firstName,
              last_name: lastName,
              birthday_date: '2000-10-10', //TODO: need get date of birth from user?
            },
          },
        }),
      },
    );
    if (res.status === 400) {
      // most likely email address has been taken already.
      throw new Error('fail_register');
    }
    const json = await res.json();
    if (!json.email) {
      // TODO: remove once API is fixed?
      // most likely email address has been taken already.
      throw new Error('fail_register');
    }
    return {
      accessToken: json.access_token,
      refreshToken: json.refresh_token,
      user: {email: json.email, ...json.profile},
    };
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

export const getProfile = async token => {
  try {
    const utmString = await getUtm();
    const res = await fetch(
      `${config.ENDPOINT_PROFILE}${utmString && `?${utmString}`}`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          ...(Platform.OS === 'web'
            ? {
                'X-HG-PWA-Origin': config.PWA_ORIGIN,
              }
            : {}),
        },
      },
    );
    const json = await res.json();
    const avatar = await getAvatar(token);
    return {...json, avatar};
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

export const updateProfile = async (profile, token) => {
  try {
    const utmString = await getUtm();

    const res = await fetch(
      `${config.ENDPOINT_PROFILE}${utmString && `?${utmString}`}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
          ...(Platform.OS === 'web'
            ? {
                'X-HG-PWA-Origin': config.PWA_ORIGIN,
              }
            : {}),
        },
        body: JSON.stringify({
          profile,
        }),
      },
    );
    const json = await res.json();
    return json;
  } catch (err) {
    console.log(err);
    return {success: false, error: err};
  }
};

export const uploadAvatar = async (file, token) => {
  const formData = new FormData();
  if (Platform.OS === 'web') {
    formData.append(
      'file',
      new File([file], 'profile_image.jpg', {type: 'image/jpeg'}),
    );
  } else {
    formData.append('file', {
      uri: 'file://' + file,
      type: 'image/jpeg',
      name: 'profile_image.jpg',
    });
  }
  formData.append('document_type', 'profile_picture');
  try {
    const utmString = await getUtm();
    const res = await axios.request({
      url: `${config.ENDPOINT_DOCUMENTS}${utmString && `?${utmString}`}`,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + token,
        ...(Platform.OS === 'web'
          ? {
              'X-HG-PWA-Origin': config.PWA_ORIGIN,
            }
          : {}),
      },
      data: formData,
    });
    const json = await res.data;
    return json.url;
  } catch (err) {
    console.log(err);
  }
};

export const changePassword = async (passwordReq, token) => {
  try {
    const utmString = await getUtm();
    const res = await axios.request({
      url: `${config.ENDPOINT_CHANGE_PASSWORD}${utmString && `?${utmString}`}`,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        ...(Platform.OS === 'web'
          ? {
              'X-HG-PWA-Origin': config.PWA_ORIGIN,
            }
          : {}),
      },
      data: passwordReq,
    });
    return {success: true};
  } catch (err) {
    // console.log('changePassword', err);
    return {success: false};
  }
};

export const getUserQrCode = async (token, id) => {
  try {
    const utmString = await getUtm();
    const res = await axios.request({
      url: `${config.ENDPOINT_GENERATE_QR_CODE}/U:${id}${
        utmString && `?${utmString}`
      }`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        ...(Platform.OS === 'web'
          ? {
              'X-HG-PWA-Origin': config.PWA_ORIGIN,
            }
          : {}),
      },
    });
    return res.data;
  } catch (err) {
    return {success: false};
  }
};

export const getTicketQrCode = async (token, id) => {
  try {
    const utmString = await getUtm();

    const res = await axios.request({
      url: `${config.ENDPOINT_GENERATE_QR_CODE}/T:${id}${
        utmString && `?${utmString}`
      }`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
        ...(Platform.OS === 'web'
          ? {
              'X-HG-PWA-Origin': config.PWA_ORIGIN,
            }
          : {}),
      },
    });
    return res.data;
  } catch (err) {
    return {success: false};
  }
};
