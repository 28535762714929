import React from 'react';
import {StyleSheet, View} from 'react-native';
import {CardContainer} from '../../../components/Card';
import Row from '../../../components/Row';
import * as HGIcon from 'components/HGIcon';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import {DateTime} from 'luxon';
import {ordinal, parseCurrency, parseDisplayDate} from '../../../utils/Utils';
import {useTranslation} from 'react-i18next';

export default ({date, time, startTime, price, multiplePrices = false}) => {
  const {t, i18n} = useTranslation();
  return (
    <CardContainer padded={true}>
      <Row style={styles.row}>
        <HGIcon.Calendar />
        <HGText variant="eventDetailsMeta" style={styles.label}>
          {parseDisplayDate(date, i18n.language)}
        </HGText>
      </Row>
      <Row style={styles.row}>
        <HGIcon.Bell />
        <HGText variant="eventDetailsMeta" style={styles.label}>
          {t('admission')}: {time}
          {i18n.language === 'de' ? ' Uhr' : ''}
        </HGText>
      </Row>
      <Row style={styles.row}>
        <HGIcon.Time />
        <HGText variant="eventDetailsMeta" style={styles.label}>
          {t('Begin')}: {startTime}
          {i18n.language === 'de' ? ' Uhr' : ''}
        </HGText>
      </Row>
      <Row style={[styles.row, {marginBottom: 0}]}>
        <HGIcon.Euro />
        <HGText variant="eventDetailsMeta" style={styles.label}>
          {multiplePrices && `${t('from')} `}
          {parseCurrency({amount: price})}
        </HGText>
      </Row>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  row: {
    marginBottom: scale(6),
    alignItems: 'center',
  },
  label: {marginLeft: scale(4)},
});
