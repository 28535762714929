import React, {useState} from 'react';
import {View, Text, ScrollView} from 'react-native';
import Container from 'components/Container';
import SearchBar from 'components/SearchBar';
import {scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
import InfiniteVerticalCategoryList from './components/InfiniteVerticalCategoryList';
import getEvents from 'hooks/getEvents';
import useSearchEvents from '../../hooks/useSearchEvents';

export default ({navigation, route}) => {
  const {genre, genreId} = route.params;
  const {t} = useTranslation();
  const {events, loading} = getEvents({genreId});
  const {
    events: filteredEvents,
    search,
    setSearch,
    fetching,
  } = useSearchEvents({events});

  return (
    <Container>
      <ScrollView>
        <SearchBar
          containerStyle={{marginVertical: scale(40)}}
          onChangeText={setSearch}
          value={search}
          onClear={() => setSearch('')}
        />
        <InfiniteVerticalCategoryList
          loading={loading || fetching}
          navigation={navigation}
          header={t('ALL EVENTS AT ONE GLANCE')}
          events={search ? filteredEvents : events}
        />
      </ScrollView>
    </Container>
  );
};
