import React, {useCallback, useState} from 'react';
import {
  Linking,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from 'react-native';
import {CardContainer, styles as cardStyles} from '../../../components/Card';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import {useTranslation} from 'react-i18next';
import Map from 'components/Map';

export default ({directions, parking, coordinates}) => {
  const {t} = useTranslation();
  return (
    <CardContainer>
      <View style={cardStyles.densedPadded}>
        <HGText variant="header" style={styles.header}>
          {t('general information')}
        </HGText>
        {/* <View style={styles.sectionContainer}>
          <HGText variant="header" upperCase={true} style={styles.title}>
            {t('directions')}
          </HGText>
          <HGText variant="eventDetailsMeta">{directions}</HGText>
        </View>
        <View style={styles.sectionContainer}>
          <HGText variant="header" upperCase={true} style={styles.title}>
            {t('parking')}
          </HGText>
          <HGText variant="eventDetailsMeta">{parking}</HGText>
        </View> */}
      </View>
      <View style={styles.mapContainer}>
        <Map
          latitude={coordinates[0]}
          longitude={coordinates[1]}
          onPressDirections={() =>
            Linking.openURL(
              `https://www.google.com/maps/dir/?api=1&destination=${
                typeof coordinates === 'string'
                  ? coordinates
                  : `${coordinates[0]},${coordinates[1]}`
              }`,
            )
          }
        />
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: scale(18),
    marginBottom: scale(25),
    textTransform: 'uppercase',
  },
  title: {
    fontSize: 14,
    marginBottom: scale(18),
  },
  sectionContainer: {
    marginBottom: scale(20),
  },
  mapContainer: {
    margin: scale(8),
    borderRadius: scale(10),
    overflow: 'hidden',
  },
});
