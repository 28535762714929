import React, {useRef, useState} from 'react';
import {StyleSheet, View, TouchableOpacity, Platform} from 'react-native';
import CountryPicker from 'react-native-country-picker-modal';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import {scale} from '../../utils/Scale';
import Colors from '../../utils/Colors';
import HGText from 'components/HGText';
import * as HGIcon from 'components/HGIcon';

const ErrorMessage = ({type, message}) => {
  const {t} = useTranslation();
  const getErrorMessage = () => {
    switch (type) {
      case 'required':
        return t('errors.required');
      default:
        return t('errors.default');
    }
  };

  return (
    <HGText style={styles.errorContainer} variant="error">
      {message || getErrorMessage()}
    </HGText>
  );
};

const HGCountryPicker = ({
  variant,
  form,
  extraStyle,
  labelText,
  labelEndComponent,
  error,
  value,
  language,
  onChange: _onChange,
}) => {
  const {t, i18n} = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  // useEffect(() => {

  // }, []);

  function onChange(val) {
    _onChange && _onChange(val.cca2);
  }

  const RenderCountryPicker = ({onChange, value}) => {
    return (
      <>
        {value === undefined ? (
          <>
            {isVisible && (
              <CountryPicker
                countryCode={value}
                withFlag={true}
                withCountryNameButton={true}
                visible={isVisible}
                onClose={() => setIsVisible(false)}
                translation={i18n.language === 'de' ? 'deu' : 'common'}
                onSelect={onChange}
                preferredCountries={['DE', 'AT', 'CH']}
                closeButtonStyle={styles.closeButton}
                closeButtonImageStyle={styles.closeButtonImage}
              />
            )}
            {value === undefined && <HGText>{t('selectCountry')}</HGText>}
          </>
        ) : (
          <CountryPicker
            countryCode={value}
            withFlag={true}
            withCountryNameButton={true}
            visible={isVisible}
            onClose={() => setIsVisible(false)}
            translation={i18n.language === 'de' ? 'deu' : 'common'}
            onSelect={onChange}
            preferredCountries={['DE', 'AT', 'CH']}
            closeButtonStyle={styles.closeButton}
            closeButtonImageStyle={styles.closeButtonImage}
          />
        )}
      </>
    );
  };

  const rootStyle = [];
  switch (variant) {
    case 'form':
      rootStyle.push(styles.formRootContainer);
      break;
  }
  if (extraStyle) rootStyle.push(extraStyle);

  return (
    <View style={rootStyle}>
      {labelText && (
        <View
          style={[
            styles.labelContainer,
            error ? styles.errorInputContainer : {},
          ]}>
          <HGText variant="inputLabel">{labelText}</HGText>
          {labelEndComponent && (
            <>
              <View style={styles.emptySeparator} />
              {labelEndComponent()}
            </>
          )}
        </View>
      )}
      <TouchableOpacity onPress={() => setIsVisible(true)}>
        <View style={[styles.inputContainer]}>
          {form ? (
            <Controller
              {...form}
              render={({field: {onChange, value}}) => (
                <RenderCountryPicker
                  onChange={v => {
                    onChange(v.cca2);
                  }}
                  value={value}
                />
              )}
            />
          ) : (
            <RenderCountryPicker onChange={onChange} value={value} />
          )}

          <View style={styles.emptySeparator} />
          <HGIcon.RightChevron style={styles.chevron} />
        </View>
      </TouchableOpacity>
      {error ? (
        <ErrorMessage type={error.type} message={error.message} />
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  emptySeparator: {flex: 1},

  formRootContainer: {
    marginBottom: scale(15),
  },

  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
    marginBottom: scale(6),
  },

  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: scale(18),
    borderWidth: 0.25,
    borderColor: Colors.SECONDARY_COLOR,
    backgroundColor: '#FFF',
    height: scale(36),
    paddingHorizontal: scale(16),
  },

  errorContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
  },

  chevron: {
    height: scale(17),
    width: scale(8),
    transform: [{rotate: '-90deg'}],
  },

  closeButton: {
    flex: 1,
    alignItems: 'flex-end',
    paddingHorizontal: scale(8),
  },
  closeButtonImage:
    Platform.OS === 'android'
      ? {width: scale(25), height: scale(25)}
      : {width: scale(50), height: scale(50)},
});

export default HGCountryPicker;
