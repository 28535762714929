import React, {useCallback, useEffect, useState} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Splash from '../views/Splash';
import HomeNavigator from './HomeNavigator';
import TicketNavigator from './TicketNavigator';
import BarNavigator from './BarNavigator.js';
import * as HGIcon from 'components/HGIcon';
import UserNavigator from './UserNavigator';
import EventNavigator from './EventNavigator';
import CheckoutNavigator from './CheckoutNavigator';
import {Linking, Platform, View} from 'react-native';
import {mobileCheck} from 'utils/WebUtils';
import webOrientationLock from 'hooks/webOrientationLock';
import CONFIG from 'config';
import {useDispatch, useSelector} from 'react-redux';
import useAccount from '../hooks/useAccount';
import ResetPassword from '../views/ResetPassword';
import {root_goBack} from './RootNavigation';
import PaymentResults from '../views/PaymentResults';
import PaymentNavigator from './PaymentNavigator';
import {getQueryParameterByName} from '../utils/Utils';
import ClaimSharedTicket from '../views/ClaimSharedTicket';
import useModal from '../hooks/useModal';
import {useTranslation} from 'react-i18next';
import {initializeData} from '../state/events';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

let _workaroundDoubleCall = null;

const MainTab = ({navigation, route}) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const userLoggedIn = useSelector(state => state.account.loggedIn);
  const [redirectClaimTicket, setRedirectClaimTicket] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const {lastClaimed} = route.params ?? {};
  const {showModal} = useModal();

  useEffect(() => {
    const processClaimTicket = url => {
      if (!url || url.indexOf('share_ticket') < 0) return;
      const ticketClaimId = getQueryParameterByName('ticket_id', url);
      if (
        !ticketClaimId ||
        ticketClaimId === lastClaimed ||
        _workaroundDoubleCall === ticketClaimId
      )
        return;
      _workaroundDoubleCall = ticketClaimId;
      setRedirectClaimTicket(ticketClaimId);
      // if not logged in must bring to login page
    };

    if (Platform.OS === 'android' || Platform.OS === 'ios') {
      Linking.addEventListener('url', ({url}) => {
        processClaimTicket(url.toString());
      });
      Linking.getInitialURL().then(event => {
        processClaimTicket(event);
      });

      return () => {
        Linking.removeAllListeners('url');
      };
    } else if (Platform.OS === 'web') {
      if (window.location.href.indexOf('share_ticket?ticket_id') >= 0) {
        processClaimTicket(window.location.href);
      }
    }
  }, [navigation, lastClaimed]);

  useEffect(() => {
    if (userLoggedIn && redirectClaimTicket) {
      setRedirectClaimTicket(false);
      navigation.replace('ClaimSharedTicket', {
        claimTicketId: redirectClaimTicket,
      });
    } else if (redirectClaimTicket && redirectClaimTicket !== showLoginDialog) {
      setShowLoginDialog(redirectClaimTicket);
      showModal({
        title: t('Login Required'),
        body: t('Please login to claim the ticket'),
      });
      navigation.navigate('User');
    }
  }, [
    userLoggedIn,
    redirectClaimTicket,
    navigation,
    showModal,
    t,
    showLoginDialog,
  ]);

  return (
    <Tab.Navigator tabBarOptions={{showLabel: false}}>
      <Tab.Screen
        name="Home"
        component={HomeNavigator}
        options={{
          tabBarIcon: ({focused}) =>
            focused ? <HGIcon.HomeFill /> : <HGIcon.Home />,
        }}
        listeners={{
          tabPress: e => {
            // Prevent default action
            // e.preventDefault();
            // Any custom code here
            dispatch(initializeData({stopTimer: true}));
          },
        }}
      />
      {!CONFIG.DISABLE_TICKETS && (
        <Tab.Screen
          name="Tickets"
          component={TicketNavigator}
          options={{
            tabBarIcon: ({focused}) =>
              focused ? <HGIcon.TicketFill /> : <HGIcon.Ticket />,
            unmountOnBlur: true,
          }}
        />
      )}
      {!CONFIG.DISABLE_BAR && (
        <Tab.Screen
          name="Bar"
          component={BarNavigator}
          initialParams={{
            goToCollect: route?.params?.goToCollect ?? false,
          }}
          options={{
            tabBarIcon: ({focused}) =>
              focused ? <HGIcon.BarCupFill /> : <HGIcon.BarCup />,
            unmountOnBlur: true,
          }}
        />
      )}
      <Tab.Screen
        name="User"
        component={UserNavigator}
        options={{
          tabBarIcon: ({focused}) =>
            focused ? (
              <HGIcon.UserFill style={{width: 19.22, height: 22}} />
            ) : (
              <HGIcon.User />
            ),
        }}
      />
    </Tab.Navigator>
  );
};

export default ({}) => {
  const {t} = useTranslation();
  const accessToken = useSelector(state => state.account.accessToken);
  const [webInitialized, setWebInitialized] = useState(Platform.OS !== 'web');
  const [autoLogin, setAutoLogin] = useState(!!accessToken);
  const [loggingIn, setLoggingIn] = useState(false);
  const {relogin} = useAccount();

  const reloginUser = useCallback(
    async ({accessToken}) => {
      setLoggingIn(true);
      try {
        await relogin({accessToken});
      } catch (err) {
      } finally {
        setLoggingIn(false);
      }
    },
    [relogin],
  );

  useEffect(() => {
    if (autoLogin && accessToken) {
      reloginUser({accessToken});
      setAutoLogin(false);
    }
  }, [autoLogin, accessToken, reloginUser]);

  webOrientationLock();

  useEffect(() => {
    if (!webInitialized) {
      setWebInitialized(true);
      window.addEventListener('onBack', e => {
        root_goBack();
      });

      if (Platform.OS === 'web' && !mobileCheck()) {
        if (window.location.href.indexOf('/event/') > -1) {
          alert(t('attentionMsg2'));
        } else if (window.location.href.indexOf('/share_ticket?') > -1) {
          alert(t('attentionMsg'));
        }
      }
    }
  }, [webInitialized]);

  if (Platform.OS === 'web' && !mobileCheck()) {
    window.location.replace('//www.kulturboden-hallstadt.de');
    // redirect if viewing on a desktop
    return <View />;
  }

  if (loggingIn) return <Splash navigation={null} />;
  return (
    <Stack.Navigator
      initialRouteName={Platform.OS === 'ios' ? 'App' : 'Splash'}
      headerMode="none"
      screenOptions={{title: 'Kulturboden'}}>
      <Stack.Screen name="Splash" component={Splash} />
      <Stack.Screen name="App" component={MainTab} />
      <Stack.Screen name="Event" component={EventNavigator} />
      <Stack.Screen name="Checkout" component={CheckoutNavigator} />
      <Stack.Screen name="ResetPassword" component={ResetPassword} />
      <Stack.Screen name="OrderPendingPayment" component={PaymentNavigator} />
      <Stack.Screen name="OrderPaymentWebHook" component={PaymentResults} />
      <Stack.Screen name="ClaimSharedTicket" component={ClaimSharedTicket} />
    </Stack.Navigator>
  );
};
