import {ShoppingCart, ShoppingCartLine} from 'components/HGIcon';
import IconButton from 'components/IconButton';
import hexToRgba from 'hex-to-rgba';
import React, {useEffect, useRef, useState} from 'react';
import {StyleSheet} from 'react-native';
import {View} from 'react-native';
import {scale} from 'utils/Scale';
import HGText from 'components/HGText';
import {useDispatch, useSelector} from 'react-redux';

export default ({onPress, cartNum}) => {
  return (
    <View style={styles.container}>
      <IconButton
        onPress={onPress}
        size={scale(55)}
        style={styles.button}
        icon={<ShoppingCartLine style={styles.icon} />}
      />
      <View style={styles.badgeNumContainer}>
        <HGText style={styles.badgeNumText}>
          {cartNum > 99 ? '99+' : cartNum}
        </HGText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: scale(18),
    right: scale(18),
  },
  button: {
    backgroundColor: '#F1EEED',
    borderColor: hexToRgba('#4B281B', 0.25),
    borderWidth: 1,
  },
  badgeNumContainer: {
    minWidth: scale(15),
    maxWidth: scale(22),
    height: scale(15),
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: scale(30),
    top: scale(10),
    backgroundColor: '#8d1e2e',
    paddingHorizontal: scale(4),
  },
  badgeNumText: {
    color: 'white',
    fontSize: scale(10),
  },
  icon: {
    width: scale(76),
    height: scale(76),
    marginRight: scale(3),
  },
});
