import React, {useEffect, useState} from 'react';
import {Linking, ScrollView, StyleSheet, View} from 'react-native';
import Container from 'components/Container';
import ProgressPills from 'components/ProgressPills';
import {heightPercentageToDP, scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
import HGText from '../../components/HGText';
import {useSelector} from 'react-redux';
import {CardContainer} from '../../components/Card';
import InvoiceAddressForm from './components/InvoiceAddressForm';
import {useForm} from 'react-hook-form';
import HGCheckBox from 'components/HGCheckBox';
import CheckBoxLink from './components/CheckBoxLink';
import TwoActionButtons from 'components/TwoActionButtons';
import useTicketCheckout from 'hooks/useTicketCheckout';
import useModal from 'hooks/useModal';
import {LOREM_IPSUM} from 'utils/mock_data';
import config from '../../config';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import {validateCountryCode} from '../../utils/Utils';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const initialValues = useSelector(state => state.ticketCheckout.billing);
  const initialUserPofile = useSelector(state => state.account.user);
  const [initialize, setInitialize] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors, isDirty, isValid},
  } = useForm({mode: 'onChange'});

  const [salutionVal, countryCodeVal] = watch(['salutation', 'countryCode']);

  const [readPrivacy, setReadPrivacy] = useState(false);
  const [readGTC, setReadGTC] = useState(false);
  const {
    updateBillingAddress,
    fetchBillingAddress,
    loading: loadingTicketInfo,
  } = useTicketCheckout();

  const submitForm = data => {
    updateBillingAddress({billingAddress: data}, () => {
      if (route.params?.nextGoBack) {
        navigation.goBack();
      } else {
        navigation.navigate('TicketCheckoutOverview');
      }
    });
  };

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      if (initialValues) {
        Object.keys(initialValues).map(key => {
          setValue(key, initialValues[key], {
            shouldDirty: true,
            shouldTouch: true,
          });
        });
      } else {
        fetchBillingAddress(res => {
          // NOTE: Add the field name that you want to update before creating the form here.
          // This will produce object of field name to value that we can use to setValue below
          let fieldInitialValues = {};
          const listFieldNames = [
            'first_name',
            'last_name',
            'email',
            'phone_number',
            'gender',
            'country_code',
          ];
          for (const fieldName of listFieldNames) {
            fieldInitialValues = {
              ...fieldInitialValues,
              [fieldName]: getInitialFieldValue(
                initialUserPofile,
                res,
                fieldName,
              ),
            };
          }

          fieldInitialValues['first_name'] &&
            setValue('firstName', fieldInitialValues['first_name'], {
              shouldDirty: true,
            });

          fieldInitialValues['last_name'] &&
            setValue('lastName', fieldInitialValues['last_name']);

          fieldInitialValues['email'] &&
            setValue('email', fieldInitialValues['email']);

          fieldInitialValues['gender'] &&
            setValue('salutation', fieldInitialValues['gender']); // Setting gender to salutation field

          fieldInitialValues['phone_number'] &&
            setValue('phone', fieldInitialValues['phone_number']);

          if (res) {
            setValue('address', res.address_1);
            setValue('postal', res.zip);
            setValue('city', res.city);
            setValue('companyName', res.company_name);
          }

          // NOTE: We need to validate the country code before setting it to avoid crash. We can consider putting this on country picker itself, but it might be heavy
          if (fieldInitialValues['country_code']) {
            validateCountryCode(fieldInitialValues['country_code']).then(
              isvalidated => {
                if (isvalidated) {
                  setValue('countryCode', fieldInitialValues['country_code']);
                }
              },
            );
          }
        });
      }
    }
  }, [
    initialize,
    initialValues,
    fetchBillingAddress,
    setValue,
    initialUserPofile,
  ]);

  const {showModal} = useModal();

  const onPressReadDocPlaceholder = title => {
    if (config.EXTERNAL_PRIVACY && title === 'Privacy Policy') {
      Linking.openURL(config.EXTERNAL_PRIVACY);
      return;
    } else if (config.EXTERNAL_GTC && title === 'GTC') {
      Linking.openURL(config.EXTERNAL_GTC);
      return;
    }
    // TODO: Replace with actual GTC and privacy policy
    showModal({
      title,
      component: () => (
        <ScrollView style={{height: heightPercentageToDP(50)}}>
          <HGText>{LOREM_IPSUM}</HGText>
        </ScrollView>
      ),
    });
  };

  // MARK: Helpers
  const getInitialFieldValue = (
    userProfile = {},
    previousBillingInfo = {},
    fieldName,
  ) => {
    // Method to decide which initial value to use
    let initialFieldValue = null;
    if (previousBillingInfo[fieldName]) {
      initialFieldValue = previousBillingInfo[fieldName];
    } else if (userProfile[fieldName]) {
      initialFieldValue = userProfile[fieldName];
    }

    return initialFieldValue;
  };

  return (
    <ScrollView>
      <Container>
        <View style={styles.progressContainer}>
          <ProgressPills total={3} current={2} />
        </View>
        <CardContainer densePadded={true} containerStyle={styles.container}>
          <HGText>{t('checkout.billingAddress')}</HGText>
        </CardContainer>
        <View style={styles.formContainer}>
          {loadingTicketInfo && <BasicLoadingIndicator />}
          <View style={{display: loadingTicketInfo ? 'none' : 'flex'}}>
            <InvoiceAddressForm
              control={control}
              errors={errors}
              values={{
                salutation: salutionVal,
                countryCode: countryCodeVal,
              }}
            />
          </View>
        </View>
        <View style={styles.readContainer}>
          <CheckBoxLink
            onPressLink={onPressReadDocPlaceholder.bind(null, 'Privacy Policy')}
            label={t('forms.privacyPolicy')}
            value={readPrivacy}
            onChange={setReadPrivacy}
          />
        </View>
        <CheckBoxLink
          onPressLink={onPressReadDocPlaceholder.bind(null, 'GTC')}
          label={t('forms.gtc')}
          value={readGTC}
          onChange={setReadGTC}
        />
        <View style={styles.buttons}>
          <TwoActionButtons
            buttons={[
              {label: t('Back'), onPress: navigation.goBack},
              {
                label: t('Continue'),
                onPress: handleSubmit(submitForm),
                disabled:
                  loadingTicketInfo ||
                  !isDirty ||
                  !isValid ||
                  !readPrivacy ||
                  !readGTC,
              },
            ]}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  container: {
    marginBottom: scale(13),
  },
  formContainer: {
    marginBottom: scale(19),
  },
  readContainer: {
    marginBottom: scale(36),
  },
  buttons: {
    marginTop: scale(50),
    marginBottom: scale(60),
  },
});
