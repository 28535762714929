import React from 'react';
import hexToRgba from 'hex-to-rgba';
import {scale} from '../../utils/Scale';
import Colors from '../../utils/Colors';
import Row from '../Row';
const {View, StyleSheet} = require('react-native');

const Pill = ({active}) => (
  <View style={[styles.pill, active ? styles.activePill : {}]} />
);

export default ({total, current}) => {
  const getPills = () => {
    const arr = [];
    for (let i = 0; i < total; i++) {
      arr.push(<Pill key={`progress-${i}`} active={i < current} />);
    }
    return arr;
  };
  return <Row>{getPills()}</Row>;
};

const styles = StyleSheet.create({
  pill: {
    height: scale(8),
    width: scale(39),
    backgroundColor: '#FFF',
    borderRadius: scale(200),
    borderWidth: 1,
    borderColor: hexToRgba(Colors.PRIMARY_COLOR, 0.25),
    marginRight: scale(10),
  },
  activePill: {
    backgroundColor: Colors.PRIMARY_COLOR,
  },
});
