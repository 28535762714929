import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import Home from '../views/Home';
import FrontNavigationHeader from '../components/FrontNavigationHeader';
import {useTranslation} from 'react-i18next';
import HomeFiltered from '../views/Home/HomeFiltered';

const Stack = createStackNavigator();
export default ({}) => {
  const {t} = useTranslation();
  return (
    <Stack.Navigator initialRouteName="Events">
      <Stack.Screen
        name="Events"
        component={Home}
        options={{
          header: ({navigation, scene: {route}}) => (
            <FrontNavigationHeader
              title={t('home.navigation.title')}
              subTitle={t('home.navigation.subtitle')}
              {...(route.params?.clearSearch
                ? {
                    showBack: true,
                    navigation: {goBack: route.params?.clearSearch},
                  }
                : {})}
            />
          ),
        }}
      />
      <Stack.Screen
        name="EventsFilter"
        component={HomeFiltered}
        options={{
          header: ({navigation, scene: {route}}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t(route.params.genre)}
              subTitle={t('home.navigation.title')}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};
