import React from 'react';
import {StyleSheet} from 'react-native';

import {useTranslation} from 'react-i18next';

import config from '../../../config';
import {getSalutationOptions, LANGUAGE_OPTIONS} from 'utils/form_options';
import inputValidations from '../../../utils/inputValidations';

import HGTextInput from 'components/HGTextInput';
import SelectInput from 'components/SelectInput';

export default ({control, errors, values}) => {
  const {t, i18n} = useTranslation();
  const SALUTATION_OPTIONS = getSalutationOptions(i18n.language);
  const {onlyAlphabetWithSpace, phoneNumber} = inputValidations();

  return (
    <>
      <SelectInput
        variant="form"
        form={{
          control,
          name: 'gender',
          rules: {required: true},
        }}
        value={values.gender}
        error={errors['gender']}
        label={t('forms.salutation')}
        disableTranslation={true}
        placeholder={t('forms.placeholders.gender')}
        options={SALUTATION_OPTIONS}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'firstName',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['firstName']}
        label={t('forms.firstName')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'lastName',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['lastName']}
        label={t('forms.lastName')}
      />
      {/*
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'address',
          rules: {required: true},
        }}
        error={errors['address']}
        label={t('forms.address')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'postal',
          rules: {required: true},
        }}
        error={errors['postal']}
        label={t('forms.postal')}
      />
      */}
      {!config.DISABLE_PROFILE_COUNTRY && (
        <HGTextInput
          variant="form"
          form={{
            control,
            name: 'country',
            rules: {required: true},
          }}
          error={errors['country']}
          label={t('forms.country')}
        />
      )}
      <HGTextInput
        variant="form"
        inputProps={{autoCapitalize: 'none'}}
        form={{
          control,
          name: 'email',
          rules: {
            validate: email => {
              const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(String(email).toLowerCase()) || t('errors.email');
            },
          },
        }}
        disabled={true}
        error={errors['email']}
        label={t('forms.email')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'phone',
          rules: {required: true, ...phoneNumber},
        }}
        error={errors['phone']}
        label={t('forms.phone')}
      />
      {config.ENABLE_PROFILE_LANGUAGE_SELECTOR && (
        <SelectInput
          form={{
            control,
            name: 'language',
            rules: {required: true},
          }}
          value={values.language}
          error={errors['language']}
          label={t('forms.language')}
          placeholder={t('forms.placeholders.language')}
          options={LANGUAGE_OPTIONS}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({});
