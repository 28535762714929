import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import ProgressPills from 'components/ProgressPills';
import Container from 'components/Container';
import {scale} from 'utils/Scale';
import TwoActionButtons from 'components/TwoActionButtons';
import {useTranslation} from 'react-i18next';
import {CardContainer} from 'components/Card';
import HGText from 'components/HGText';
import RadioButtonInput from 'components/RadioButtonInput';
import useCounterCheckout from 'hooks/useCounterCheckout';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const {cart, updateTimeslot} = useCounterCheckout();
  return (
    <ScrollView contentContainerStyle={{minHeight: '100%'}}>
      <Container>
        <View style={styles.progressContainer}>
          <ProgressPills total={3} current={2} />
        </View>
        <View>
          <CardContainer
            densePadded={true}
            containerStyle={styles.instructionsContainer}>
            <HGText>{t('checkout.selectTimeslot')}</HGText>
          </CardContainer>
          <CardContainer
            densePadded={true}
            containerStyle={styles.timeslotContainer}>
            <RadioButtonInput
              active={cart.timeslot === 'before'}
              label={t('checkout.timeslots.before')}
              onPress={updateTimeslot.bind(null, {timeslot: 'before'}, null)}
            />
          </CardContainer>
          <CardContainer
            densePadded={true}
            containerStyle={styles.timeslotContainer}>
            <RadioButtonInput
              active={cart.timeslot === 'possible'}
              label={t('checkout.timeslots.possible')}
              onPress={updateTimeslot.bind(null, {timeslot: 'possible'}, null)}
            />
          </CardContainer>
          <CardContainer
            densePadded={true}
            containerStyle={styles.timeslotContainer}>
            <RadioButtonInput
              active={cart.timeslot === 'break'}
              label={t('checkout.timeslots.break')}
              onPress={updateTimeslot.bind(null, {timeslot: 'break'}, null)}
            />
          </CardContainer>
          <CardContainer densePadded={true}>
            <RadioButtonInput
              active={cart.timeslot === 'after'}
              label={t('checkout.timeslots.after')}
              onPress={updateTimeslot.bind(null, {timeslot: 'after'}, null)}
            />
          </CardContainer>
        </View>
        <View style={styles.actionButtonsContainer}>
          <TwoActionButtons
            buttons={[
              {label: t('Back'), onPress: navigation.goBack},
              {
                disabled: !cart.timeslot,
                label: t('Continue'),
                onPress: () => {
                  if (route.params?.nextGoBack) {
                    navigation.goBack();
                  } else {
                    //navigation.navigate('CounterPaymentMethods');
                    navigation.navigate('CounterCheckoutOverview');
                  }
                },
              },
            ]}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  instructionsContainer: {
    paddingHorizontal: scale(8),
    marginBottom: scale(13),
  },
  timeslotContainer: {
    marginBottom: scale(13),
  },
  actionButtonsContainer: {
    marginTop: scale(50),
    marginBottom: scale(70),
  },
});
