import {useEffect, useState} from 'react';

export default ({events: _events}) => {
  // TO REPLACE EVENTS PROP WITH API CALL
  const [fetching, setFetching] = useState(false);
  const [timer, setTimer] = useState(null);
  const [search, setSearch] = useState('');
  const [events, setEvents] = useState([]);
  const [searchingTerm, setSearchingTerm] = useState('');

  useEffect(() => {
    if (!search && timer) {
      clearTimeout(timer);
      setFetching(false);
    } else if (searchingTerm !== search) {
      setFetching(true);
      if (timer) {
        clearTimeout(timer);
      }
      setSearchingTerm(search);
      const newTimer = setTimeout(() => {
        setEvents(
          _events.filter(
            ({title}) => title.toLowerCase().indexOf(search.toLowerCase()) >= 0,
          ),
        );
        setFetching(false);
      }, 500);
      setTimer(newTimer);
    }
  }, [search, timer, _events, searchingTerm]);

  return {fetching, setSearch, search, events};
};
