import React from 'react';
import {TouchableOpacity, View, StyleSheet} from 'react-native';
import {scale} from '../../../utils/Scale';
import {HeartFill, TicketFill, BarCupFill} from 'components/HGIcon';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import hexToRgba from 'hex-to-rgba';

export default ({name, disabled, onPress, boldLabel = false}) => {
  const {t} = useTranslation();
  const Icon = () => {
    switch (name) {
      case 'favorites':
        return <HeartFill style={styles.icon} />;

      case 'tickets':
        return <TicketFill style={styles.icon} />;

      case 'counter':
        return <BarCupFill style={styles.icon} />;
    }
  };

  const getLabel = () => {
    switch (name) {
      case 'favorites':
        return t('Favorites');

      case 'tickets':
        return t('Tickets');

      case 'counter':
        return t('Counter');
    }
  };

  return (
    <TouchableOpacity disabled={disabled} onPress={onPress || (() => {})}>
      <View
        style={[
          styles.container,
          disabled ? {backgroundColor: 'rgba(0,0,0,0.1)'} : {},
        ]}>
        <Icon />
        <HGText style={[styles.label, boldLabel ? {fontWeight: 'bold'} : {}]}>
          {getLabel()}
        </HGText>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  icon: {
    width: scale(34.5),
    marginBottom: scale(5),
  },
  label: {
    fontSize: scale(11),
  },
  container: {
    marginRight: scale(10),
    backgroundColor: '#FFF',
    borderColor: hexToRgba('#4B281B', 0.25),
    borderRadius: scale(10),
    borderWidth: 1,
    height: scale(90),
    width: scale(74),
    padding: scale(12),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
