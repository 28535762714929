import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {useTranslation} from 'react-i18next';
import TicketCheckout from '../views/TicketCheckout';
import FrontNavigationHeader from '../components/FrontNavigationHeader';
import PaymentMethod from '../views/PaymentMethod';
import TicketCheckoutInvoiceAddress from '../views/TicketCheckoutInvoiceAddress';
import TicketCheckoutOverview from '../views/TicketCheckoutOverview';
import Login from '../views/Login';
import Register from '../views/Register';
import PaymentResults from '../views/PaymentResults';
import CounterCheckout from '../views/CounterCheckout';
import CounterCheckoutTimeslot from '../views/CounterCheckoutTimeslot';
import CounterCheckoutOverview from '../views/CounterCheckoutOverview';
import UserNavigator from './UserNavigator';

const Stack = createStackNavigator();
export default ({}) => {
  const {t} = useTranslation();
  return (
    <Stack.Navigator initialRouteName="TicketCheckoutCart">
      <Stack.Screen
        name="TicketCheckoutCart"
        component={TicketCheckout}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('shopping cart')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="TicketPaymentMethods"
        component={PaymentMethod}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('payment method')}
            />
          ),
        }}
        initialParams={{screenType: 'ticketCheckout'}}
      />
      <Stack.Screen
        name="TicketCheckoutInvoiceAddress"
        component={TicketCheckoutInvoiceAddress}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('invoice address')}
            />
          ),
        }}
        initialParams={{screenType: 'ticketCheckout'}}
      />
      <Stack.Screen
        name="TicketCheckoutOverview"
        component={TicketCheckoutOverview}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('overview')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CheckoutLogin"
        component={UserNavigator}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('log in')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CheckoutRegister"
        component={Register}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('log in')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CheckoutSuccess"
        component={PaymentResults}
        options={{headerShown: false}}
      />

      <Stack.Screen
        name="CounterCheckoutCart"
        component={CounterCheckout}
        options={{
          header: ({navigation, scene: {route}}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('bar.navigation.checkout')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CounterCheckoutTimeslot"
        component={CounterCheckoutTimeslot}
        options={{
          header: ({navigation, scene: {route}}) => (
            <FrontNavigationHeader
              showBack={true}
              navigation={navigation}
              title={t('time slot')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="CounterPaymentMethods"
        component={PaymentMethod}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('payment method')}
            />
          ),
        }}
        initialParams={{screenType: 'counterCheckout'}}
      />
      <Stack.Screen
        name="CounterCheckoutOverview"
        component={CounterCheckoutOverview}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('overview')}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};
