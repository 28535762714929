import React, {useRef, useState} from 'react';
import {
  StyleSheet,
  View,
  TextInput,
  TouchableWithoutFeedback,
  Platform,
} from 'react-native';
import {scale} from '../../utils/Scale';
import HGText from 'components/HGText';
import Colors from '../../utils/Colors';
import {useTranslation} from 'react-i18next';
import {Controller} from 'react-hook-form';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {Eye} from '../HGIcon';

const ErrorMessage = ({type, message}) => {
  const {t} = useTranslation();
  const getErrorMessage = () => {
    switch (type) {
      case 'required':
        return t('errors.required');
      case 'maxLength':
        return t('errors.maxlength');
      default:
        return t('errors.default');
    }
  };

  return (
    <HGText style={styles.errorContainer} variant="error">
      {message || getErrorMessage()}
    </HGText>
  );
};

const HGTextInput = ({
  label,
  variant,
  labelEndComponent,
  inputEndComponent,
  onChange: _onChange,
  isPasswordField,
  value,
  error,
  form,
  style,
  inputProps = {},
  disabled = false,
  keyboard = 'default',
}) => {
  const [showPassword, setShowPassword] = useState(false);

  function onChange(val) {
    _onChange && _onChange(val);
  }

  const inputRef = useRef(null);

  const rootStyle = [];
  switch (variant) {
    case 'form':
      rootStyle.push(styles.formRootContainer);
      break;
  }
  if (style) rootStyle.push(style);

  return (
    <View style={rootStyle}>
      {label && (
        <View style={styles.labelContainer}>
          <HGText variant="inputLabel">{label}</HGText>
          {labelEndComponent && (
            <>
              <View style={styles.emptySeparator} />
              {labelEndComponent()}
            </>
          )}
        </View>
      )}
      <View
        style={[
          styles.inputContainer,
          error ? styles.errorInputContainer : {},
        ]}>
        <TouchableWithoutFeedback disabled={disabled} onPress={() => inputRef.current?.focus()}>
          {form ? (
            <Controller
              {...form}
              render={({field: {onChange, onBlur, value: _value = ''}}) => (
                <TextInput
                  {...{
                    secureTextEntry: isPasswordField ? !showPassword : false,
                    ...inputProps,
                  }}
                  value={_value}
                  ref={inputRef}
                  disabled={disabled}
                  style={styles.input}
                  onBlur={onBlur}
                  onChangeText={onChange}
                  keyboardType={keyboard}
                />
              )}
            />
          ) : (
            <TextInput
              {...{
                secureTextEntry: isPasswordField ? !showPassword : false,
                ...inputProps,
              }}
              disabled={disabled}
              value={value}
              ref={inputRef}
              style={styles.input}
              onChangeText={onChange || (() => {})}
              keyboardType={keyboard}
            />
          )}
        </TouchableWithoutFeedback>
        {inputEndComponent && inputEndComponent()}
        {isPasswordField && (
          <TouchableOpacity
            hitSlop={{top: 10, left: 10, right: 10, bottom: 10}}
            onPress={() => {
              setShowPassword(!showPassword);
            }}>
            <Eye style={styles.eye} />
          </TouchableOpacity>
        )}
      </View>
      {error ? (
        <ErrorMessage type={error.type} message={error.message} />
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  emptySeparator: {flex: 1},
  formRootContainer: {
    marginBottom: scale(15),
  },
  labelContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
    marginBottom: scale(7),
  },
  errorContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingHorizontal: scale(14),
  },
  inputContainer: {
    ...{
      flexDirection: 'row',
      alignItems: 'center',
      borderRadius: scale(18),
      borderWidth: 0.25,
      borderColor: Colors.SECONDARY_COLOR,
      backgroundColor: '#FFF',
      height: scale(36),
      paddingHorizontal: scale(16),
    },
    ...(Platform.OS === 'web'
      ? {
          '-moz-box-sizing': 'border-box',
        }
      : {}),
  },
  errorInputContainer: {
    borderWidth: 1,
    borderColor: Colors.PRIMARY_COLOR,
  },
  input: {height: scale(36), flex: 1},
  eye: {
    width: 24,
    height: 16,
  },
});

export default HGTextInput;
