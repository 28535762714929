import React, {useState} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGText from '../HGText';
import {Cross} from 'components/HGIcon';

export default ({
  body,
  title,
  actionText,
  containerStyle,
  actionTextOnPress,
  hideCloseButton = false,
}) => {
  const [hide, setHide] = useState(false);

  return (
    <View style={hide ? {display: 'none'} : [styles.container, containerStyle]}>
      {title && (
        <HGText style={{fontWeight: '700', marginBottom: scale(26)}}>
          {title}
        </HGText>
      )}
      {typeof body === 'function' ? body() : <HGText>{body}</HGText>}
      {actionText && (
        <TouchableOpacity onPress={actionTextOnPress || (() => {})}>
          <HGText style={styles.actionText} variant="actionText">
            {actionText}
          </HGText>
        </TouchableOpacity>
      )}

      {hideCloseButton ? null : (
        <TouchableOpacity
          style={styles.closeIconContainer}
          onPress={() => setHide(true)}>
          <Cross style={styles.closeIcon} />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: scale(19),
    borderColor: Colors.PRIMARY_COLOR,
    borderWidth: 1,
    borderLeftWidth: scale(8),
    backgroundColor: '#FFF',
    borderRadius: scale(8),
  },
  actionText: {
    marginTop: scale(33),
  },
  closeIconContainer: {
    position: 'absolute',
    right: scale(10),
    top: scale(10),
  },
  closeIcon: {
    width: scale(10),
    height: scale(10),
  },
});
