import React from 'react';
import {StyleSheet, View} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';

export default ({active}) => {
  return (
    <View
      style={[
        styles.outerContainer,
        active ? styles.activeOuterContainer : {},
      ]}>
      {active && <View style={styles.innerContainer} />}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    height: scale(18),
    width: scale(18),
    borderRadius: scale(9),
    borderWidth: 1,
    borderColor: '#707070',
    justifyContent: 'center',
    alignItems: 'center',
  },
  activeOuterContainer: {
    borderColor: Colors.PRIMARY_COLOR,
  },
  innerContainer: {
    height: scale(10),
    width: scale(10),
    borderRadius: scale(5),
    backgroundColor: Colors.PRIMARY_COLOR,
  },
});
