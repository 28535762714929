import {useNavigation} from '@react-navigation/core';
import BasicLoadingIndicator from 'components/BasicLoadingIndicator';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Image,
} from 'react-native';
import {useDispatch} from 'react-redux';
import HGImage from '../../../components/HGImage';
import HGText from '../../../components/HGText';
import getEvents from '../../../hooks/getEvents';
import {scale} from '../../../utils/Scale';

const GenreCard = ({name, image, navigation, id}) => {
  const {t} = useTranslation();
  return (
    <TouchableOpacity
      onPress={() =>
        navigation.navigate('EventsFilter', {genre: name, genreId: id})
      }>
      <View style={styles.card}>
        <HGImage source={{uri: image}} style={styles.cardImage} />
        <View style={styles.cardLabel}>
          <HGText style={styles.cardLabelText} variant="header">
            {name}
          </HGText>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default ({}) => {
  const {t} = useTranslation();
  const {categories: genres = [], loading} = getEvents({skipInitialize: true});
  const navigation = useNavigation();
  return (
    <View style={styles.container}>
      <HGText variant="header" style={styles.header}>
        {t('GENRES')}
      </HGText>
      <ScrollView style={styles.list} horizontal>
        {loading && <BasicLoadingIndicator />}
        {genres.map(({id, name, assets}) => {
          const image = assets[0]?.file?.url ?? ''; //TODO: DEFAULT 404 NO FILE HERE
          return (
            <GenreCard
              key={id}
              id={id}
              name={name}
              image={image}
              navigation={navigation}
            />
          );
        })}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    marginBottom: scale(30),
  },
  container: {
    marginTop: scale(36),
    marginBottom: scale(26),
  },
  list: {
    height: scale(150),
  },
  card: {
    marginRight: scale(10),
  },
  cardImage: {
    width: scale(102),
    height: scale(102),
    borderRadius: scale(10),
    marginBottom: scale(10),
  },
  cardLabel: {
    borderRadius: scale(10),
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: scale(14),
    paddingVertical: scale(8),
  },
  cardLabelText: {
    fontSize: scale(14),
  },
});
