import AddressBook from 'components/AddressBook';
import HGButton from 'components/HGButton';
import {Cross, UserDetails} from 'components/HGIcon';
import HGText from 'components/HGText';
import HGTextInput from 'components/HGTextInput';
import IconButton from 'components/IconButton';
import Row from 'components/Row';
import SelectInput from 'components/SelectInput';
import React, {useEffect, useState} from 'react';
import {useFormState} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {View, StyleSheet, TouchableOpacity, Platform} from 'react-native';
import {useSelector} from 'react-redux';
import {getSalutationOptions} from 'utils/form_options';
import {scale, widthPercentageToDP} from 'utils/Scale';
import inputValidations from '../../../utils/inputValidations';

export default ({
  onClose: _onClose,
  onAssign: _onAssign,
  errors,
  control,
  watch,
  setValue,
  index,
  reset,
  initialValues,
}) => {
  const {t, i18n} = useTranslation();
  const SALUTATION_OPTIONS = getSalutationOptions(i18n.language);
  const [salutation, phone] = watch(['salutation', 'phone']);
  // we watch phone here as well to workaround control wrong state on ending second modal
  const {isDirty, isValid} = useFormState({control});
  const [initialized, setInitialize] = useState(false);
  const currentUser = useSelector(state => state.account.user);

  const onClose = () => {
    _onClose();
    reset();
  };

  const onAssign = () => {
    _onAssign();
    onClose();
  };

  useEffect(() => {
    if (!initialized) {
      setInitialize(true);
      if (!initialValues) {
        if (!isDirty) {
          setValue('firstName', currentUser.first_name, {shouldDirty: true});
          setValue('lastName', currentUser.last_name, {shouldDirty: true});
          setValue('phone', currentUser.phone_number, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
        return;
      }
      Object.keys(initialValues).forEach(key => {
        if (key === 'salutation' && salutation) {
          // Don't overwrite
        } else if (initialValues[key]) {
          setValue(key, initialValues[key], {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      });
    }
  }, [initialized, initialValues, setValue, salutation, currentUser, isDirty]);

  const onSelectFromAddressBook = ({firstName, lastName, phone}) => {
    setValue('firstName', firstName, {shouldDirty: true});
    setValue('lastName', lastName, {shouldDirty: true});
    setValue('phone', phone, {shouldDirty: true});
  };

  const {onlyAlphabetWithSpace, phoneNumber} = inputValidations();

  return (
    <View style={styles.container}>
      <View style={styles.closeIcon}>
        <IconButton
          icon={
            Platform.OS === 'web' ? <Cross style={styles.webIcon} /> : <Cross />
          }
          onPress={onClose}
        />
      </View>
      <Row style={styles.headerContainer}>
        <HGText variant="header" fontSize={scale(14)} upperCase={true}>
          {t('assign person')}
        </HGText>
        <View style={styles.spacing} />
        <AddressBook onSelect={onSelectFromAddressBook} />
        <View style={styles.spacing} />
      </Row>
      <View style={styles.divider} />
      {/* <SelectInput
        variant="form"
        form={{
          control,
          name: 'salutation',
          rules: {required: true},
        }}
        value={salutation}
        error={errors['salutation']}
        label={t('forms.salutation')}
        placeholder={t('forms.placeholders.salutation')}
        disableTranslation={true}
        options={SALUTATION_OPTIONS}
      /> */}
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'firstName',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['firstName']}
        label={t('forms.firstName')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'lastName',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['lastName']}
        label={t('forms.lastName')}
      />
      <HGTextInput
        form={{
          control,
          name: 'phone',
          rules: {required: true, ...phoneNumber},
        }}
        error={errors['phone']}
        label={t('forms.phone')}
        keyboard={'phone-pad'}
      />
      <View style={styles.closeButton}>
        <HGButton
          disabled={!isDirty || !isValid || !phone}
          fullWidth={true}
          label={t('Assign')}
          onPress={onAssign}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderRadius: scale(10),
    paddingTop: scale(19),
    paddingBottom: scale(50),
    paddingHorizontal: scale(24),
    width: widthPercentageToDP(75),
  },
  headerContainer: {
    alignItems: 'center',
  },
  assignButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F1EEED',
    height: scale(32),
    width: scale(32),
    borderRadius: scale(16),
  },
  assignButton: {
    top: -2,
    left: -1,
  },
  spacing: {
    flex: 1,
  },
  divider: {
    marginTop: scale(20),
    marginBottom: scale(27.5),
    borderTopWidth: 0.5,
    backgroundColor: '#707070',
    left: scale(-9),
    width: widthPercentageToDP(75) - scale(30),
  },
  closeButton: {
    marginTop: scale(50),
  },
  closeIcon: {
    position: 'absolute',
    right: scale(5),
    top: scale(5),
  },
  webIcon: {
    width: scale(10),
    height: scale(10),
  },
});
