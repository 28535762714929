import {createSlice} from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import persistConfig from './persistConfig';

const initialState = {
  id: '',
  event: {},
  tickets: {},
  options: [],
  paymentMethod: 'credit',
  billing: null,
  assignments: {},
};
/*
const initialState = {
  id: '1',
  event: MOCK_EVENT_DETAILS,
  quantity: 1,
  options: MOCK_EVENT_DETAILS.options.map(obj => ({quantity: 1, ...obj})),
  paymentMethod: 'credit',
  billing: {
    salutation: 'mr',
    title: 'mr',
    firstName: 'Christian',
    lastName: 'Mager',
    address: 'Virchowstraße 20D',
    postal: '90409 Nürnberg',
    email: 'christian.mager@helloguest.com',
    phone: '12345678',
  },
  assignments: [],
};
*/

const ticketCheckoutSlice = createSlice({
  name: 'ticketCheckout',
  initialState,
  reducers: {
    createCart: (state, action) => {
      const {payload} = action;
      state.id = payload.id;
      state.event = payload.event;
      state.tickets = payload.tickets;
      state.options = payload.options;
      state.billing = null;
      const assignments = {};
      Object.keys(payload.tickets).map((productId, productIndex) => {
        assignments[productId] = Array.from({
          length: payload.tickets[productId],
        });
      });
      state.assignments = assignments;
    },
    updatePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload.paymentMethod;
    },
    updateBillingAddress: (state, action) => {
      state.billing = {...action.payload.billingAddress};
    },
    updateAssignments: (state, action) => {
      state.assignments = {
        ...state.assignments,
        [action.payload.productId]: state.assignments[
          action.payload.productId
        ].map((obj, j) =>
          action.payload.index === j ? {...action.payload.data} : obj,
        ),
      };
    },
  },
});

const actions = ticketCheckoutSlice.actions;

export const createCart =
  ({id, event, tickets, options}, cb) =>
  async dispatch => {
    dispatch(actions.createCart({id, event, tickets, options}));
    cb && cb();
  };

export const updatePaymentMethod =
  ({paymentMethod}, cb) =>
  async dispatch => {
    dispatch(actions.updatePaymentMethod({paymentMethod}));
    cb && cb();
  };

export const updateBillingAddress =
  ({billingAddress}, cb) =>
  async dispatch => {
    dispatch(actions.updateBillingAddress({billingAddress}));
    cb && cb();
  };

export const updateAssignments =
  ({productId, index, data}, cb) =>
  async dispatch => {
    dispatch(actions.updateAssignments({productId, index, data}));
    cb && cb();
  };

export default persistReducer(persistConfig, ticketCheckoutSlice.reducer);
