import {CardContainer} from 'components/Card';
import Container from 'components/Container';
import FileUploadWrapper from 'components/FileUploadWrapper';
import HGButton from 'components/HGButton';
import {Upload} from 'components/HGIcon';
import HGText from 'components/HGText';
import Row from 'components/Row';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, StyleSheet, View} from 'react-native';
import {TouchableWithoutFeedback} from 'react-native-gesture-handler';
import {useSelector} from 'react-redux';
import {scale} from 'utils/Scale';
import BasicLoadingIndicator from '../../../components/BasicLoadingIndicator';
import FrontNavigationHeader from '../../../components/FrontNavigationHeader';
import IconButton from '../../../components/IconButton';
import Colors from '../../../utils/Colors';
import {heightPercentageToDP} from '../../../utils/Scale';
import LocalUploadedDocuments from '../components/LocalUploadedDocuments';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const initialValues = useSelector(state => state.corona);
  const [initialize, setInitialize] = useState(false);
  const [loadingLocalUpload, setLoadingLocalUpload] = useState(false);
  const [refreshFiles, setRefreshFiles] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [parentTouch, setParentTouch] = useState(new Date().getTime());
  const scrollContainer = useRef(null);

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      if (initialValues) {
      }
    }
  }, [initialize, initialValues]);

  const getFileCb = async _file => {
    setLoadingLocalUpload(true);
    setRefreshFiles(_file);
    setLoadingLocalUpload(false);
  };

  return (
    <>
      <FrontNavigationHeader
        navigation={navigation}
        showBack={true}
        title={
          route.params?.documentType
            ? t(`user.navigation.corona.${route.params?.documentType}`)
            : 'Upload'
        }
        endComponent={() => (
          <View style={styles.headerUploadContainer}>
            <IconButton
              TouchableComponent={FileUploadWrapper}
              TouchableComponentProps={{
                types: ['image/*'],
                getFileCb,
                documentType: route.params.documentType,
                onUploadStatus: setIsUploading,
              }}
              icon={<Upload style={{width: 24, height: 24}} />}
              backgroundColor={Colors.BACKGROUND_COLOR}
              disabled={isUploading}
            />
          </View>
        )}
      />
      <Container>
        <TouchableWithoutFeedback
          style={{height: heightPercentageToDP(100) - scale(75)}}
          onPress={evt => {
            setParentTouch(new Date().getTime());
          }}>
          <ScrollView style={{height: '100%'}} ref={scrollContainer}>
            <CardContainer densePadded={true} containerStyle={styles.notice}>
              <HGText>
                {t(
                  `user.coronaRegistration.documentNotice.${route.params.documentType}`,
                )}
              </HGText>
            </CardContainer>
            <LocalUploadedDocuments
              parentTouch={parentTouch}
              documentType={route.params.documentType}
              lastRefresh={refreshFiles}
            />
            {isUploading && (
              <View
                style={styles.loadingIndicator}
                onLayout={({nativeEvent}) => {
                  scrollContainer.current?.scrollTo({y: nativeEvent.layout.y});
                }}>
                <BasicLoadingIndicator />
              </View>
            )}

            <Row style={styles.buttonContainer}>
              <HGButton
                TouchableComponent={FileUploadWrapper}
                TouchableComponentProps={{
                  types: ['image/*'],
                  getFileCb,
                  documentType: route.params.documentType,
                  onUploadStatus: setIsUploading,
                }}
                fullWidth={true}
                icon={<Upload />}
                label={t('Upload')}
                labelColor="primary"
                variant="tertiary"
                disabled={isUploading}
                loading={loadingLocalUpload}
              />
            </Row>
          </ScrollView>
        </TouchableWithoutFeedback>
      </Container>
    </>
  );
};

const styles = StyleSheet.create({
  notice: {
    marginTop: scale(40),
    marginBottom: scale(70),
  },
  loadingIndicator: {
    marginTop: scale(30),
  },
  buttonContainer: {
    zIndex: -1,
    marginTop: scale(30),
    paddingHorizontal: scale(71),
    justifyContent: 'center',
    marginBottom: scale(70),
  },
  headerUploadContainer: {
    justifyContent: 'center',
    marginRight: scale(24),
  },
});
