//  {name: '', items: [{name: '', options: [{description: '', price: ''}]}]}
//  {name: '', options: [{description: '', price: ''}]},

export const MOCK_COUNTER_DATA = [
  {
    name: 'soft drinks',
    image: require('../../assets/placeholders/placeholder_drinks.jpg'),
    items: [
      {
        name: 'Cola',
        options: [
          {description: '0.25L', price: 1.7},
          {description: '0.50L', price: 2.6},
        ],
      },
      {
        name: 'Bitter Lemon',
        options: [
          {description: '0.25L', price: 1.7},
          {description: '0.50L', price: 2.5},
        ],
      },
      {
        name: 'Effect Energy-Drink',
        options: [
          {description: '0.25L', price: 2.3},
          {description: '0.50L', price: 4.0},
        ],
      },
      {
        name: 'Quellwasser still',
        options: [
          {description: '0.25L', price: 1.4},
          {description: '0.50L', price: 2.2},
        ],
      },
    ],
  },
  {
    name: 'Säfte',
    image: require('../../assets/placeholders/placeholder_juices.jpg'),
    items: [
      {
        name: 'Orange',
        options: [
          {description: '0.25L', price: 1.7},
          {description: '0.50L', price: 2.6},
        ],
      },
      {
        name: 'Apfelsaftschorle',
        options: [
          {description: '0.25L', price: 1.7},
          {description: '0.50L', price: 2.6},
        ],
      },
      {
        name: 'Maracuja',
        options: [
          {description: '0.25L', price: 1.7},
          {description: '0.50L', price: 2.6},
        ],
      },
      {
        name: 'Saftschorlen',
        options: [
          {description: '0.25L', price: 1.7},
          {description: '0.50L', price: 2.6},
        ],
      },
    ],
  },
  {
    name: 'Heißgetränke',
    image: require('../../assets/placeholders/placeholder_hotdrinks.jpg'),
    items: [
      {
        name: 'Kaffee',
        options: [{description: 'Tasse', price: 2.2}],
      },
      {
        name: 'Cappuccino',
        options: [{description: 'Tasse', price: 2.5}],
      },
      {
        name: 'Tee (verschiedene Sorten)',
        options: [{description: 'Tasse', price: 2.2}],
      },
      {
        name: 'Kakao',
        options: [{description: 'Tasse', price: 2.2}],
      },
      {
        name: 'Latte Macchiato',
        options: [{description: 'Glas', price: 2.7}],
      },
    ],
  },
];

export const MOCK_COUNTER_ORDERS = [
  {
    collectionNo: 121,
    timing: 'possible',
    items: [
      {name: 'Orange 0.25L', quantity: 1},
      {name: 'Apfelsaftschorle 0.25L', quantity: 1},
    ],
  },
];

export const LOREM_IPSUM = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut pharetra mauris dolor, quis cursus risus auctor non. Nam non tellus commodo, commodo velit nec, aliquet ligula. Morbi vehicula scelerisque neque. Aliquam lorem mi, malesuada id elit ut, sagittis ultrices eros. Donec in velit viverra, finibus augue at, pulvinar eros. Duis maximus mattis gravida. Vivamus blandit nibh est, in sodales massa condimentum et. Nulla facilisi.

Mauris sodales dolor erat, eget pretium risus scelerisque ac. Pellentesque at neque suscipit, mollis lacus vitae, pretium enim. Donec venenatis odio neque. Cras ac dolor rutrum, malesuada nibh sit amet, posuere felis. Pellentesque a nunc massa. Donec faucibus mollis augue in varius. Vivamus viverra pellentesque velit ut imperdiet. Ut tortor orci, aliquam sed posuere in, cursus ut leo. In elit leo, venenatis vitae libero vitae, imperdiet imperdiet dui. Fusce lectus leo, tincidunt ac enim ac, condimentum consequat mauris.

Nullam non ultrices lacus. Vivamus eget nunc elit. Praesent finibus dui cursus odio sodales euismod. Nulla facilisi. Fusce in elit finibus odio commodo convallis. Fusce ut pharetra tellus. Suspendisse mollis sem commodo mauris tempor sodales. Donec felis nisi, porttitor in consequat et, dapibus at urna.

Praesent ac fermentum ante. Vivamus tincidunt sem sit amet sapien placerat maximus. Phasellus at magna vitae purus condimentum pharetra sed at arcu. Phasellus turpis nibh, suscipit id accumsan efficitur, accumsan ac nisi. Pellentesque convallis felis ligula. Suspendisse pharetra laoreet eros. Vestibulum luctus est urna, in dapibus tellus lacinia at. Fusce ultrices molestie mattis. Duis euismod vitae augue vitae vulputate. Curabitur nec nibh lacinia, tempor massa sit amet, tempus risus.

Integer ante tortor, vehicula id nunc gravida, viverra sodales diam. Morbi magna magna, pretium a tempus a, bibendum nec nibh. Quisque maximus venenatis porttitor. Phasellus a sapien a orci commodo consectetur id sit amet felis. Quisque volutpat lobortis ullamcorper. Cras condimentum venenatis ullamcorper. Nullam ac porta urna. Ut pellentesque, magna sit amet blandit sollicitudin, dolor odio hendrerit diam, at tempor lacus lorem vitae libero. Etiam dapibus faucibus fermentum. Morbi vulputate mauris non sodales cursus. Suspendisse potenti. Praesent in mauris at sapien condimentum mollis. Morbi velit urna, ultricies eget accumsan ac, imperdiet in diam. Nunc augue eros, imperdiet id turpis a, ultricies ullamcorper justo. Praesent iaculis cursus neque ut faucibus. Sed ac posuere diam.

`;
