import hexToRgba from 'hex-to-rgba';
import React  from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {scale} from '../../utils/Scale';
import HGTouchableOpacity from '../../components/HGTouchableOpacity';

const IconButton = ({
  icon,
  backgroundColor,
  onPress,
  style,
  size,
  TouchableComponent,
  TouchableComponentProps,
}) => {
  const TouchComponent = TouchableComponent || HGTouchableOpacity;

  return (
    <TouchComponent
      style={{flexDirection: 'row'}}
      onPress={onPress || (() => {})}
      {...TouchableComponentProps}>
      <View
        style={[
          styles.container,
          size
            ? {
                width: scale(size),
                height: scale(size),
                borderRadius: scale(size / 2),
              }
            : {},
          backgroundColor ? {backgroundColor} : {},
          style,
        ]}>
        {icon}
      </View>
    </TouchComponent>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: scale(18),
    width: scale(36),
    height: scale(36),
  },
});

export default IconButton;
