import {Cross, Search} from 'components/HGIcon';
import HGText from 'components/HGText';
import Row from 'components/Row';
import SearchBar from 'components/SearchBar';
import React, {useEffect, useState} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {scale, widthPercentageToDP} from 'utils/Scale';

export default ({category, onSearchChange, searchValue, onPress}) => {
  const [openSearch, setOpenSearch] = useState(false);

  useEffect(() => {
    if (!openSearch && searchValue) {
      onSearchChange('');
    }
  }, [openSearch, onSearchChange, searchValue]);
  return (
    <Row style={styles.container}>
      <TouchableOpacity
        onPress={() => {
          setOpenSearch(!openSearch);
        }}>
        <View style={styles.searchIcon}>
          {openSearch ? <Cross style={styles.crossIcon} /> : <Search />}
        </View>
      </TouchableOpacity>

      {openSearch ? (
        <SearchBar
          placeholder="Search food and drinks"
          containerStyle={{flex: 1}}
          onChangeText={onSearchChange}
          onClear={() => onSearchChange('')}
        />
      ) : (
        <ScrollView
          horizontal
          contentContainerStyle={{minWidth: widthPercentageToDP(100)}}>
          {category.map((name, i) => (
            <TouchableOpacity
              key={`${name}-${i}`}
              onPress={onPress?.bind(null, i) ?? (() => {})}>
              <View style={styles.categoryPill}>
                <HGText variant="coronaFloatingMenuItem">{name}</HGText>
              </View>
            </TouchableOpacity>
          ))}
        </ScrollView>
      )}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    minHeight: scale(50),
  },
  crossIcon: {
    width: scale(15),
    height: scale(15),
  },
  searchIcon: {
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    width: scale(40),
    height: scale(40),
    marginRight: scale(12),
    borderRadius: scale(5),
  },
  categoryPill: {
    backgroundColor: '#FFF',
    borderRadius: scale(50),
    paddingHorizontal: scale(24),
    paddingVertical: scale(13.5),
    marginRight: scale(12),
  },
});
