import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  foodAddicList: [],
};

const foodAddictiveSlice = createSlice({
  name: 'foodAddictive',
  initialState,
  reducers: {
    updateFoodAddictive: (state, action) => {
      state.foodAddicList = action.payload.foodAddicList;
    },
  },
});

const actions = foodAddictiveSlice.actions;

export const updateFoodAddictive = ({foodAddicList}, cb) => {
  async dispatch => {
    dispatch(actions.updateFoodAddictive({foodAddicList}));
  };
  cb && cb();
};

export default foodAddictiveSlice.reducer;
