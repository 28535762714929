import React from 'react';
import {IdentityCard} from 'components/HGIcon';
import NotificationBanner from 'components/NotificationBanner';
import Row from 'components/Row';
import {StyleSheet, View} from 'react-native';
import {scale} from 'utils/Scale';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';

const Body = ({}) => {
  const {t} = useTranslation();
  return (
    <View>
      <Row style={styles.header}>
        <IdentityCard style={styles.icon} />
        <HGText>{t('Specify registration data')}</HGText>
      </Row>
      <HGText>{t('specifyRegistrationData')}</HGText>
    </View>
  );
};

const SpecifyRegistrationData = ({}) => (
  <NotificationBanner body={() => <Body />} />
);

const styles = StyleSheet.create({
  header: {
    marginBottom: scale(12),
    alignItems: 'center',
  },
  icon: {
    width: scale(20),
    height: scale(15),
    marginRight: scale(11),
  },
});

export default SpecifyRegistrationData;
