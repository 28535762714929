import CameraView from 'components/CameraView';
import useModal from 'hooks/useModal';
import React, {useState} from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import ImageCropperWeb from './ImageCropper.web';

export default ({
  children,
  onPress,
  width,
  height,
  cropping,
  front,
  getImageCb,
  types,
  ...props
}) => {
  const {showModal} = useModal();

  const onFinish = photo => {
    // photo is in base64
    getImageCb && getImageCb(photo);
    showModal(null);
  };
  const onPhoto = photo => {
    showModal(null); //close camera
    showModal({
      type: 'empty',
      component: () => (
        <ImageCropperWeb
          image={photo.base64}
          onFinish={onFinish}
          onClose={() => {
            showModal(null);
            showModal(cameraModal);
          }}
        />
      ),
    });
  };
  const cameraModal = {
    type: 'empty',
    component: () => (
      <CameraView
        front={front}
        onPhoto={onPhoto}
        onClose={() => {
          showModal(null);
        }}
      />
    ),
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          showModal(cameraModal);
        }}
        {...props}>
        {children}
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  camera: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 500,
    height: 500,
    zIndex: 999,
  },
});
