import {useEffect, useMemo, useState} from 'react';
import MOCK_EVENTS from '../utils/mock_data/events.json';
import {DateTime} from 'luxon';
import {useDispatch, useSelector} from 'react-redux';
import {initializeData} from '../state/events';

export default params => {
  const dispatch = useDispatch();
  const {
    loading,
    events: _events,
    categories,
    error,
    initialize,
  } = useSelector(state => state.events);

  const {genreId, skipInitialize} = params ? params : {};

  const events = useMemo(() => {
    return genreId
      ? _events.filter(({categories}) => {
          let isInFilter = false;
          categories.forEach(({id}) => {
            if (id === genreId) {
              isInFilter = true;
            }
          });
          return isInFilter;
        })
      : _events;
  }, [_events, genreId]);

  useEffect(() => {
    if (!initialize && !skipInitialize) {
      dispatch(initializeData());
    }
  }, [dispatch, initialize, skipInitialize]);

  return {events, categories, error, loading};
};
