
import React, {useRef, useEffect}  from 'react';
import {Platform} from 'react-native';
import {
  TouchableOpacity,
} from 'react-native';

export default ({children, style, onPress, ...props}) => {
  const _iconRef = useRef();

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (_iconRef && _iconRef.current) {
        _iconRef.current.addEventListener("contextmenu", (e) => e.preventDefault());
      }
    }

  }, [_iconRef]);

  useEffect(() => {
    return () => {
      if (Platform.OS === 'web') {
        if (_iconRef && _iconRef.current) {
          _iconRef.current.addEventListener("contextmenu", (e) => e.preventDefault());
        }
      }
    }
  }, []);

  return (<TouchableOpacity
    style={style}
    onPress={onPress || (() => { })}
    ref={_iconRef}
    {...props}>
    {children}
  </TouchableOpacity>)
}