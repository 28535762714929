import React from 'react';
import {CardContainer} from 'components/Card';
import {StyleSheet} from 'react-native';
import {View, ImageBackground} from 'react-native';
import HGText from 'components/HGText';
import {scale} from 'utils/Scale';

export default ({category, image}) => {
  return (
    <CardContainer
      containerStyle={[styles.container, image ? {} : {height: 40}]}>
      {image ? (
        <ImageBackground
          source={image}
          resizeMode="cover"
          style={styles.featuredImage}
        />
      ) : (
        <View />
      )}
      <View style={styles.titleContainer}>
        <HGText variant="header" upperCase={true}>
          {category}
        </HGText>
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 140,
  },
  featuredImage: {
    flex: 3,
  },
  titleContainer: {
    flex: 2,
    justifyContent: 'center',
    paddingLeft: scale(40),
  },
});
