import React, {useCallback, useEffect, useState} from 'react';
import {View, Text, StyleSheet, ScrollView} from 'react-native';
import {useTranslation} from 'react-i18next';
import {scale} from 'utils/Scale';
import HGText from 'components/HGText';
import {MOCK_COUNTER_DATA} from 'utils/mock_data';
import CategoryCard from './components/CategoryCard';
import ItemCard from './components/ItemCard';
import BasicItemCard from './components/BasicItemCard';
import CategoryBar from './components/CategoryBar';
import getCounterProducts from '../../hooks/getCounterProducts';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import getFoodAddictiveList from '../../hooks/getFoodAddictiveList';

let workaround = {}; //allow positions to be saved

export default ({navigation, scrollContainer}) => {
  const {t, i18n} = useTranslation();
  const {products, productCategory, loading} = getCounterProducts({
    refresh: true,
  });
  const [foodAddicItems, setFoodAddicItems] = useState([]);
  const [isReadyForView, setIsReadyForView] = useState(false); //workaround to allow view to add in their positions later
  const [search, setSearch] = useState('');
  const [positions, _setPosition] = useState([]);

  const setPosition = useCallback(
    ({y, i}) => {
      workaround[i] = y;
      _setPosition(positions.map((pos, j) => workaround[j]));
    },
    [positions],
  );

  const jumpToCategory = useCallback(
    i => {
      scrollContainer.scrollTo({y: positions[i]});
    },
    [scrollContainer, positions],
  );

  useEffect(() => {
    if (!isReadyForView && productCategory.length !== positions.length) {
      _setPosition(Array(productCategory.length).fill(0));
      setIsReadyForView(true);
    }
  }, [isReadyForView, productCategory, positions]);

  useEffect(async () => {
    const {foodAddicItems} = await getFoodAddictiveList();
    setFoodAddicItems(foodAddicItems);
  }, []);

  return (
    <View>
      <View style={styles.categoryBarContainer}>
        <CategoryBar
          category={productCategory.map(
            ({name}) => name[i18n.language] ?? name.de ?? name.en,
          )}
          onPress={jumpToCategory}
          searchValue={search}
          onSearchChange={setSearch}
        />
      </View>
      {(loading || !setIsReadyForView) && <BasicLoadingIndicator />}
      {search ? (
        products
          .filter(({title, subtitle}) => {
            return (
              (title[i18n.language] ?? title.de ?? title.en ?? '')
                .toLowerCase()
                .indexOf(search.toLowerCase()) >= 0 ||
              (subtitle[i18n.language] ?? subtitle.de ?? subtitle.en ?? '')
                .toLowerCase()
                .indexOf(search.toLowerCase()) >= 0
            );
          })
          .map(({id, title, subtitle, price, stock_qty, food_additive_ids}) => (
            <View style={styles.itemContainer} key={`${id}`}>
              <BasicItemCard
                name={title[i18n.language] ?? title.de ?? title.en}
                description={
                  subtitle[i18n.language] ?? subtitle.de ?? subtitle.en
                }
                price={price}
                itemId={id}
                stock={stock_qty}
                productFoodAddic={food_additive_ids}
                allFoodAddic={foodAddicItems}
              />
            </View>
          ))
      ) : isReadyForView ? (
        productCategory.map(
          (
            {name: categoryName, id: _categoryId, image = '', items},
            categoryId,
          ) => {
            return (
              <View
                key={`category-${_categoryId}`}
                onLayout={({nativeEvent: {layout}}) => {
                  setPosition({y: layout.y, i: categoryId});
                }}>
                <View style={styles.itemContainer}>
                  <CategoryCard
                    image={image}
                    category={
                      categoryName[i18n.language] ??
                      categoryName.de ??
                      categoryName.en
                    }
                  />
                </View>
                {items.map(
                  (
                    {id, title, subtitle, price, stock_qty, food_additive_ids},
                    i,
                  ) => (
                    <View style={styles.itemContainer} key={`${id}`}>
                      <BasicItemCard
                        name={title[i18n.language] ?? title.de ?? title.en}
                        description={
                          subtitle[i18n.language] ?? subtitle.de ?? subtitle.en
                        }
                        price={price}
                        itemId={id}
                        stock={stock_qty}
                        productFoodAddic={food_additive_ids}
                        allFoodAddic={foodAddicItems}
                      />
                    </View>
                  ),
                )}
              </View>
            );
          },
        )
      ) : (
        <View />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  categoryBarContainer: {
    marginBottom: scale(30),
  },
  itemContainer: {
    marginBottom: scale(13),
  },
});
