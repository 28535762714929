import React from 'react';
import {StyleSheet} from 'react-native';

import {useTranslation} from 'react-i18next';

import inputValidations from '../../../utils/inputValidations';
import {getSalutationOptions, getTitleOptions} from 'utils/form_options';

import {CardContainer} from '../../../components/Card';
import HGTextInput from 'components/HGTextInput';
import SelectInput from 'components/SelectInput';
import HGCountryPicker from 'components/HGCountryPicker';

export default ({control, errors, values}) => {
  const {t, i18n} = useTranslation();
  const SALUTATION_OPTIONS = getSalutationOptions(i18n.language);
  const TITLE_OPTIONS = getTitleOptions(i18n.language);
  const {onlyAlphabetWithSpace, alphabetWithNumbers, onlyNumber, phoneNumber} =
    inputValidations();

  return (
    <CardContainer densePadded={true}>
      <SelectInput
        variant="form"
        form={{
          control,
          name: 'salutation',
          rules: {required: true},
        }}
        value={values.salutation}
        error={errors['salutation']}
        label={t('forms.salutation')}
        placeholder={t('forms.placeholders.salutation')}
        disableTranslation={true}
        options={SALUTATION_OPTIONS}
      />
      {/* <SelectInput
        variant="form"
        form={{
          control,
          name: 'title',
          rules: {required: true},
        }}
        value={values.title}
        error={errors['title']}
        label={t('forms.title')}
        placeholder={t('forms.placeholders.title')}
        disableTranslation={true}
        options={TITLE_OPTIONS}
      /> */}
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'firstName',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['firstName']}
        label={t('forms.firstName')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'lastName',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['lastName']}
        label={t('forms.lastName')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'address',
          rules: {required: true, ...alphabetWithNumbers},
        }}
        error={errors['address']}
        label={t('forms.address')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'postal',
          rules: {required: true, ...alphabetWithNumbers, maxLength:20},
        }}
        error={errors['postal']}
        label={t('forms.postal')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'city',
          rules: {required: true, ...onlyAlphabetWithSpace},
        }}
        error={errors['city']}
        label={t('forms.city')}
      />
      <HGTextInput
        variant="form"
        inputProps={{autoCapitalize: 'none'}}
        form={{
          control,
          name: 'email',
          rules: {
            validate: email => {
              const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(String(email).toLowerCase()) || t('errors.email');
            },
          },
        }}
        error={errors['email']}
        label={t('forms.email')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'phone',
          rules: {required: true, ...phoneNumber},
        }}
        error={errors['phone']}
        label={t('forms.phone')}
      />
      <HGTextInput
        variant="form"
        form={{
          control,
          name: 'companyName',
          rules: {required: false},
        }}
        error={errors['companyName']}
        label={t('forms.companyName')}
      />
      <HGCountryPicker
        variant={'form'}
        form={{
          control,
          name: 'countryCode',
          rules: {required: true},
        }}
        labelText={t('forms.country')}
        selectedCountryCode={values.countryCode}
        error={errors['countryCode']}
        language={i18n.language}
      />
    </CardContainer>
  );
};

const styles = StyleSheet.create({});
