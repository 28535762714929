import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import {useSelector} from 'react-redux';
import ProgressPills from 'components/ProgressPills';
import Container from 'components/Container';
import {scale} from 'utils/Scale';
import CartOverviewCard from './components/CartOverviewCard';
import Subtotal from 'components/Subtotal';
import TwoActionButtons from '../../components/TwoActionButtons';
import {useTranslation} from 'react-i18next';
import useModal from '../../hooks/useModal';
import moment from 'moment';
import {calculateTaxAmount} from '../../utils/Utils';

export default ({navigation}) => {
  const {t} = useTranslation();
  const {
    event,
    tickets,
    options = [],
  } = useSelector(state => state.ticketCheckout);
  const productsObj = useSelector(state => state.events.products);
  const loggedIn = useSelector(state => state.account.loggedIn);
  const {showModal} = useModal();

  const {totalPrice, vatRates} = calculateTotalPrice();

  function calculateTotalPrice() {
    let total = 0;
    let rates = [];

    Object.keys(tickets).forEach(productId => {
      const quantity = tickets[productId];
      total += quantity * productsObj[productId].price;

      const ticketRate = productsObj[productId].vat_rate;
      const ticketSubPrice = quantity * productsObj[productId].price;
      rates = calculateTaxAmount(rates, ticketRate, ticketSubPrice);
    });

    for (let i = 0; i < options.length; i++) {
      const {qty, price, vat_rate} = options[i] || {};
      total += qty * price;

      const optionRate = vat_rate;
      const optionSubPrice = qty * price;
      rates = calculateTaxAmount(rates, optionRate, optionSubPrice);
    }

    return {totalPrice: total, vatRates: rates};
  }

  // function calculateVatRate(rates, vatRate, price) {
  //   const rt = vatRate / 100;
  //   if (rates.length > 0) {
  //     for (let i = 0; i < rates.length; i++) {
  //       if (rates[i].vat_rate === vatRate) {
  //         // rates[i].total += (price * vatRate) / 100;
  //         rates[i].total += (price / (vatRate / 100) + 1) * (vatRate / 100);
  //       } else {
  //         const totalRate = (price / (vatRate / 100) + 1) * (vatRate / 100);
  //         rates.push({vat_rate: vatRate, total: totalRate});
  //       }
  //     }
  //   } else {
  //     const totalRate = (price / (vatRate / 100) + 1) * (vatRate / 100);
  //     rates.push({vat_rate: vatRate, total: totalRate});
  //   }
  //   return rates;
  // }

  return (
    <Container>
      <ScrollView>
        <View style={styles.progressContainer}>
          <ProgressPills total={3} current={1} />
        </View>
        <CartOverviewCard
          title={event.title}
          date={event.date_at}
          time={event.start_at}
          price={totalPrice}
          tickets={tickets}
          options={options}
        />

        <View style={styles.subtotalContainer}>
          <Subtotal total={totalPrice} vatRates={vatRates} />
        </View>
        <View style={styles.buttonsContainer}>
          <TwoActionButtons
            buttons={[
              {label: t('Back'), onPress: navigation.goBack},
              {
                label: t('Checkout'),
                onPress: () => {
                  var date = moment(
                    event.date_at + ' ' + event.start_at,
                    'YYYY-MM-DD HH:mm',
                  );

                  if (moment().isSameOrAfter(date)) {
                    showModal({
                      title: t('Invalid Event'),
                      body: t('Event Started Error'),
                    });
                  } else {
                    if (loggedIn) {
                      navigation.navigate('TicketCheckoutInvoiceAddress');
                    } else {
                      navigation.navigate('CheckoutLogin', {
                        screenType: 'checkout',
                        redirect: 'TicketCheckoutInvoiceAddress',
                      });
                    }
                  }
                },
              },
            ]}
          />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  subtotalContainer: {
    marginTop: scale(24),
    marginBottom: scale(50),
  },
  buttonsContainer: {
    marginBottom: scale(50),
  },
});
