import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';

import {useTranslation} from 'react-i18next';

import {scale} from 'utils/Scale';

import Row from 'components/Row';
import HGText from 'components/HGText';

export default ({
  navigation,
  firstName,
  lastName,
  address,
  postal,
  city,
  email,
  phone,
  companyName,
}) => {
  const {t} = useTranslation();

  return (
    <View>
      <Row>
        <HGText variant="smallHeader" upperCase={true}>
          {t('invoice address')}
        </HGText>
        <View style={styles.spacing} />
        <TouchableOpacity
          onPress={() =>
            navigation.push('TicketCheckoutInvoiceAddress', {
              nextGoBack: true,
            })
          }>
          <HGText
            style={styles.changeLink}
            variant="eventDetailsMeta"
            color="tertiary">
            {t('Change')}
          </HGText>
        </TouchableOpacity>
      </Row>
      <View style={styles.textSpacingLess}>
        <HGText variant="eventDetailsMeta">
          {firstName} {lastName}
        </HGText>
      </View>
      <View style={styles.textSpacing}>
        <HGText variant="eventDetailsMeta">{companyName}</HGText>
        <HGText variant="eventDetailsMeta">
          {address}, {postal}
        </HGText>
        <HGText variant="eventDetailsMeta">{city}</HGText>
      </View>
      <View style={styles.textSpacing}>
        <HGText variant="eventDetailsMeta">{email}</HGText>
        <HGText variant="eventDetailsMeta">{phone}</HGText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  spacing: {flex: 1},
  changeLink: {marginTop: scale(2)},
  textSpacingLess: {marginTop: scale(13)},
  textSpacing: {marginTop: scale(15)},
});
