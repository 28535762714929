import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import config from '../config';
import {updateFoodAddictive} from '../state/foodAddictive';
import getUtmDetails from '../hooks/getUtmDetails';

export default async params => {
  const {getUtm} = getUtmDetails();
  let foodAddicItems = [];
  try {
    const utmString = await getUtm();
    const res = await fetch(
      `${config.ENDPOINT_FETCH_FOOD_ADDICTIVE_LIST}${
        utmString && `?${utmString}`
      }`,
      {
        headers: {'X-HG-PWA-Origin': config.PWA_ORIGIN},
      },
    );
    const json = await res.json();
    foodAddicItems = json;
  } catch (err) {
    console.log(err);
  }
  return {foodAddicItems};
};
