import React, {useEffect, useState} from 'react';
import {Platform, StyleSheet, TouchableOpacity, View} from 'react-native';
import HGTextInput from 'components/HGTextInput';
import {height, scale} from '../../../utils/Scale';
import {useTranslation} from 'react-i18next';
import Colors from '../../../utils/Colors';
import HGText from '../../../components/HGText';
import HGCheckBox from 'components/HGCheckBox';
import WhiteContainer from '../../../components/WhiteContainer';
import {useForm} from 'react-hook-form';

const LoginBox = ({navigation, onChange}) => {
  const {t} = useTranslation();
  const [autoSign, setAutoSign] = useState(true);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors, isDirty, isValid},
  } = useForm({mode: 'onChange'});

  const [username, password] = watch(['username', 'password']);

  useEffect(() => {
    if (onChange) {
      onChange({username, password, autoSign});
    }
  }, [username, password, autoSign, onChange]);

  return (
    <WhiteContainer>
      <HGTextInput
        inputProps={{autoCapitalize: 'none'}}
        form={{
          control,
          name: 'username',
          rules: {
            validate: email => {
              const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return re.test(String(email).toLowerCase()) || t('errors.email');
            },
          },
        }}
        error={errors['username']}
        variant="form"
        label={t('email')}
      />
      <HGTextInput
        style={Platform.OS === 'web' ? {height: scale(50)} : {}}
        isPasswordField={true}
        variant="form"
        label={t('Password')}
        form={{
          control,
          name: 'password',
          rules: {required: true},
        }}
        error={errors['password']}
        labelEndComponent={() => (
          <TouchableOpacity
            onPress={() => navigation.navigate('ForgottenPassword')}>
            <HGText style={styles.forgetPassword}>
              {t('Forgotten Password')}
            </HGText>
          </TouchableOpacity>
        )}
      />
      <HGCheckBox
        value={autoSign}
        onChange={setAutoSign}
        label={t('Remain signed in')}
      />
    </WhiteContainer>
  );
};

export default LoginBox;

const styles = StyleSheet.create({
  forgetPassword: {
    fontSize: scale(12),
    color: Colors.PRIMARY_COLOR,
  },
  eye: {
    width: 24,
    height: 16,
  },
});
