import React from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from '../../utils/Scale';
import HGButton from '../HGButton';
import Row from '../Row';

export default ({buttons, isVertical}) => {
  const [first, second] = buttons;
  return (
    <>
      {!isVertical ? (
        <Row>
          <View style={styles.container}>
            <HGButton
              disabled={
                typeof first.disabled === 'undefined' ? false : first.disabled
              }
              fullWidth={true}
              label={first.label}
              icon={first.icon}
              size={first.size}
              onPress={first.onPress}
              variant={first.variant || 'secondary'}
              loading={first.loading}
            />
          </View>
          {second !== undefined ? (
            <>
              <View style={styles.divider} />
              <View style={styles.container}>
                <HGButton
                  disabled={
                    typeof second.disabled === 'undefined'
                      ? false
                      : second.disabled
                  }
                  fullWidth={true}
                  label={second.label}
                  icon={second.icon}
                  size={first.size}
                  onPress={second.onPress}
                  variant={second.variant || 'primary'}
                  loading={second.loading}
                  extraTextStyle={
                    second.extraTextStyle ? second.extraTextStyle : {}
                  }
                />
              </View>
            </>
          ) : null}
        </Row>
      ) : (
        <>
          {second !== undefined ? (
            <>
              <View style={styles.divider} />
              <View style={styles.container}>
                <HGButton
                  disabled={
                    typeof second.disabled === 'undefined'
                      ? false
                      : second.disabled
                  }
                  fullWidth={true}
                  label={second.label}
                  icon={second.icon}
                  size={first.size}
                  onPress={second.onPress}
                  variant={second.variant || 'primary'}
                  loading={second.loading}
                  extraTextStyle={
                    second.extraTextStyle ? second.extraTextStyle : {}
                  }
                  upperCase={false}
                />
              </View>
            </>
          ) : null}
          <View style={[styles.container, {marginTop: 8}]}>
            <HGButton
              disabled={
                typeof first.disabled === 'undefined' ? false : first.disabled
              }
              fullWidth={true}
              label={first.label}
              icon={first.icon}
              size={first.size}
              onPress={first.onPress}
              variant={first.variant || 'secondary'}
              loading={first.loading}
              upperCase={false}
              disableContainer={first.disableContainer}
            />
          </View>
        </>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  divider: {
    width: scale(25),
  },
});
