import React, {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import config from '../config';
import {saveContactDetails as _saveContactDetails} from '../state/corona';
import axios from 'axios';
import {Platform} from 'react-native';
import getUtmDetails from '../hooks/getUtmDetails';

const documentType = {
  vaccination: 'health_covid_vacc',
  recovery: 'health_covid_reco',
  test: 'health_covid_test',
};

export default () => {
  const {getUtm} = getUtmDetails();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {accessToken} = useSelector(state => state.account);

  async function saveContactDetails(
    {id, firstName, lastName, address, postal, email, phone},
    cb,
  ) {
    await dispatch(
      _saveContactDetails(
        {[id] :{firstName, lastName, address, postal, email, phone}},
        cb,
      ),
    );
  }

  const getDocuments = useCallback(
    async ({type}, cb) => {
      try {
        const utmString = await getUtm();
        const res = await axios.request({
          url: `${config.ENDPOINT_DOCUMENTS}${utmString && `?${utmString}`}`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
          params: {
            type: documentType[type],
          },
        });
        const data = await res.data;
        return data;
      } catch (err) {
        console.log(err);
        return {success: false, error: err};
      }
    },
    [accessToken],
  );

  const uploadDocument = useCallback(
    async ({type, file}, cb) => {
      setLoading(true);
      const formData = new FormData();
      formData.append('document_type', documentType[type]);
      if (Platform.OS === 'web') {
        formData.append('file', file);
      } else {
        formData.append('file', {
          uri: file.uri,
          name: file.name,
          type: file.type,
        });
      }
      try {
        const res = await axios.request({
          url: config.ENDPOINT_DOCUMENTS,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
          data: formData,
        });
        const json = await res.data;
        cb && cb(json);
      } catch (err) {
        console.log(err);
        return {success: false, error: err};
      } finally {
        setLoading(false);
      }
    },
    [accessToken],
  );

  const deleteDocument = useCallback(
    async ({id}, cb) => {
      setLoading(true);
      try {
        const res = await axios.request({
          url: `${config.ENDPOINT_DOCUMENTS}/${id}`,
          method: 'DELETE',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        });
        const json = await res.data;
        cb && cb(json);
      } catch (err) {
        console.log(err);
        return {success: false, error: err};
      } finally {
        setLoading(false);
      }
    },
    [accessToken],
  );
  return {
    saveContactDetails,
    uploadDocument,
    getDocuments,
    deleteDocument,
    loading,
  };
};
