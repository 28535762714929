import {useState} from 'react';
import {useSelector} from 'react-redux';
import config from '../config';
import getUtmDetails from '../hooks/getUtmDetails';

export default params => {
  const {getUtm} = getUtmDetails();
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(state => state.account.accessToken);

  const getCounterOrders = async function (disabledLoad = false) {
    if (!disabledLoad) {
      setLoading(true);
    }

    try {
      const utmString = await getUtm();

      const res = await fetch(
        `${config.ENDPOINT_FOOD_ORDERS}${utmString && `?${utmString}`}`,
        {
          method: 'GET',
          headers: {
            'X-HG-PWA-Origin': config.PWA_ORIGIN,
            Authorization: 'Bearer ' + accessToken,
          },
        },
      );
      const json = await res.json();
      return json;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return {getCounterOrders, loading};
};
