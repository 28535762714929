import React from 'react';
import {StyleSheet, View} from 'react-native';
import HGText from 'components/HGText';
import {scale} from '../../utils/Scale';
import Row from '../Row';
import IconButton from '../IconButton';
import * as HGIcon from '../HGIcon';

export default ({subTitle, title, showBack, navigation, endComponent}) => {
  return (
    <Row style={styles.container}>
      {showBack && (
        <View style={styles.backIconOuterContainer}>
          <IconButton
            style={styles.backIconContainer}
            icon={<HGIcon.LeftChevron style={styles.backIcon} />}
            onPress={navigation?.goBack ?? (() => {})}
          />
        </View>
      )}
      <View
        style={[
          styles.titleContainer,
          showBack && !endComponent ? styles.titleContainerWithBack : {},
        ]}>
        {subTitle && (
          <View style={{marginBottom: scale(5)}}>
            <HGText variant="navigatorHeaderSubTitle">
              {subTitle.slice(0, 40)}
              {subTitle.length > 40 && '...'}
            </HGText>
          </View>
        )}
        {title ? (
          typeof title === 'function' ? (
            title()
          ) : (
            <View>
              <HGText
                variant="navigatorHeaderTitle"
                {...(title.length >= 15 ? {fontSize: scale(26)} : {})}>
                {title}
              </HGText>
            </View>
          )
        ) : (
          <View />
        )}
      </View>
      {endComponent && endComponent()}
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    minHeight: scale(67),
  },
  backIconOuterContainer: {
    zIndex: 100,
    alignSelf: 'center',
  },
  backIconContainer: {
    alignSelf: 'center',
    marginLeft: scale(24),
    width: scale(20),
    height: scale(20),
  },
  backIcon: {
    width: scale(20),
    height: scale(20),
  },
  titleContainer: {
    flex: 1,
    paddingVertical: scale(16),
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleContainerWithBack: {
    marginLeft: -scale(44),
  },
});
