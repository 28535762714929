import React from 'react';
import {View, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import Header from './Header';
import {scale} from '../../../../utils/Scale';
import {ordinal, parseDisplayDate} from '../../../../utils/Utils';
import Card from 'components/Card';
import {DateTime} from 'luxon';
import Row from 'components/Row';
import HGText from 'components/HGText';
import BasicLoadingIndicator from 'components/BasicLoadingIndicator';
import {useTranslation} from 'react-i18next';

export default ({navigation, header, events, genre, hideChevron, loading}) => {
  const {t, i18n} = useTranslation();
  if (!events || events.length === 0) {
    return <View />;
  }
  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={() => navigation.navigate('EventsFilter', {genre})}>
        <Header text={t(header).toUpperCase()} hideChevron={hideChevron} />
      </TouchableOpacity>

      <ScrollView horizontal style={styles.list}>
        {loading && <BasicLoadingIndicator />}
        {events.map(
          ({
            title,
            date_at: date,
            assets,
            start_at,
            admission_at: admission,
            id: eventId,
          }) => {
            const image = assets[0]?.file?.url ?? ''; //TODO: DEFAULT 404 NO FILE HERE
            return (
              <TouchableOpacity
                key={eventId}
                style={styles.card}
                onPress={() => {
                  // TODO: Replace with proper eventId;
                  navigation.navigate('Event', {
                    screen: 'EventDetails',
                    params: {eventId},
                  });
                }}>
                <Card
                  title={title}
                  subtitle={
                    <Row>
                      <HGText variant="eventDetailsMeta" color="primary">
                        {parseDisplayDate(date, i18n.language)}
                      </HGText>
                      <HGText
                        variant="eventDetailsMeta"
                        color="primary"
                        style={styles.dateTimeDivider}>
                        |
                      </HGText>
                      <HGText
                        variant="eventDetailsMeta"
                        color="primary"
                        style={styles.label}>
                        {start_at}
                        {i18n.language === 'de' ? ' Uhr' : ''}
                      </HGText>
                    </Row>
                  }
                  image={image}
                  containerStyle={styles.card}
                  footerText={`${t('admission')}: ${admission}${
                    i18n.language === 'de' ? ' Uhr' : ''
                  }`}
                />
              </TouchableOpacity>
            );
          },
        )}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: scale(12),
  },
  card: {
    width: scale(238),
    height: scale(248),
    marginRight: scale(10),
  },
  list: {
    height: scale(248),
  },
  dateTimeDivider: {
    marginHorizontal: scale(8),
  },
});
