import React from 'react';
import Row from 'components/Row';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {scale} from 'utils/Scale';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';

export default ({navigation, paymentMethod}) => {
  const {t} = useTranslation();
  return (
    <View>
      <Row>
        <HGText variant="smallHeader" upperCase={true}>
          {t('payment method')}
        </HGText>
        <View style={styles.spacing} />
        <TouchableOpacity
          onPress={() =>
            navigation.push('TicketPaymentMethods', {
              screenType: 'ticketCheckout',
              nextGoBack: true,
            })
          }>
          <HGText
            style={styles.changeLink}
            variant="eventDetailsMeta"
            color="tertiary">
            {t('Change')}
          </HGText>
        </TouchableOpacity>
      </Row>
      <View style={styles.textSpacingLess}>
        <HGText variant="eventDetailsMeta">{paymentMethod}</HGText>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  spacing: {flex: 1},
  changeLink: {marginTop: scale(2)},
  textSpacingLess: {marginTop: scale(13)},
  textSpacing: {marginTop: scale(15)},
});
