import {useState} from 'react';
import config from '../config';
import getUtmDetails from '../hooks/getUtmDetails';

export default params => {
  const {getUtm} = getUtmDetails();
  const [loading, setLoading] = useState(false);

  const getStatus = async function () {
    setLoading(true);
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_CHECK_OPEN_COUNTER}${utmString && `?${utmString}`}`,
        {
          headers: {'X-HG-PWA-Origin': config.PWA_ORIGIN},
        },
      );
      const json = await res.json();
      return json.status === 'open';
    } catch (err) {
      return false;
    } finally {
      setLoading(false);
    }
  };

  return {getStatus, loading};
};
