import React, {useEffect, useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {scale, widthPercentageToDP} from 'utils/Scale';
import Row from 'components/Row';
import HGText from 'components/HGText';
import AddressBook from 'components/AddressBook';
import {useTranslation} from 'react-i18next';
import SelectInput from 'components/SelectInput';
import HGTextInput from 'components/HGTextInput';
import HGButton from 'components/HGButton';

import {getSalutationOptions} from 'utils/form_options';
import TwoActionButtons from 'components/TwoActionButtons';
import inputValidations from '../../../../utils/inputValidations';

export default ({visitors, form, onSave, onClose: _onClose}) => {
  const {t, i18n} = useTranslation();
  const SALUTATION_OPTIONS = getSalutationOptions(i18n.language);
  const {onlyAlphabetWithSpace} = inputValidations();
  const {
    control,
    watch,
    formState: {errors, isValid},
    reset,
    handleSubmit,
    setValue,
  } = form;

  const onSelectFromAddressBook = ({firstName, lastName, phone}) => {
    setValue('firstName', firstName, {shouldDirty: true});
    setValue('lastName', lastName, {shouldDirty: true});
    setValue('phone', phone, {shouldDirty: true});
  };

  const [salutation, visitor] = watch(['salutation', 'visitor']);

  const onClose = () => {
    reset();
    _onClose && _onClose();
  };

  useEffect(() => {
    if (typeof visitor !== 'undefined' && visitor >= 0) {
      const {
        firstName,
        lastName,
        phone,
        salutation: _salutation,
      } = visitors[visitor];
      setValue('firstName', firstName);
      setValue('lastName', lastName);
      setValue('phone', phone);
      setValue('salutation', _salutation);
    }
  }, [visitor, visitors, setValue]);

  return (
    <View style={styles.container}>
      <Row style={styles.headerContainer}>
        <HGText variant="header" fontSize={scale(14)} upperCase={true}>
          {t('assign person')}
        </HGText>
        <View style={styles.spacing} />
        {visitor >= 0 ? (
          <AddressBook onSelect={onSelectFromAddressBook} />
        ) : null}
        <View style={styles.spacing} />
      </Row>
      <View style={styles.divider} />
      <SelectInput
        variant="form"
        form={{
          control,
          name: 'visitor',
        }}
        value={visitor}
        label={t('select visitor')}
        disableTranslation={true}
        options={visitors.map(({firstName, lastName}, i) => ({
          value: i,
          label: `${firstName} ${lastName}`,
        }))}
      />
      {visitor >= 0 ? (
        <>
          <SelectInput
            variant="form"
            form={{
              control,
              name: 'salutation',
              rules: {required: true},
            }}
            value={salutation}
            error={errors['salutation']}
            label={t('forms.salutation')}
            placeholder={t('forms.placeholders.salutation')}
            disableTranslation={true}
            options={SALUTATION_OPTIONS}
          />
          <HGTextInput
            variant="form"
            form={{
              control,
              name: 'firstName',
              rules: {required: true, ...onlyAlphabetWithSpace},
            }}
            error={errors['firstName']}
            label={t('forms.firstName')}
          />
          <HGTextInput
            variant="form"
            form={{
              control,
              name: 'lastName',
              rules: {required: true, ...onlyAlphabetWithSpace},
            }}
            error={errors['lastName']}
            label={t('forms.lastName')}
          />
          <HGTextInput
            form={{
              control,
              name: 'phone',
              rules: {required: true},
            }}
            error={errors['phone']}
            label={t('forms.phone')}
          />

          <View style={styles.closeButton}>
            <TwoActionButtons
              buttons={[
                {
                  label: t('Back'),
                  onPress: onClose,
                  size: 'small',
                },
                {
                  label: t('Change'),
                  variant: 'primary',
                  onPress: onClose,
                  disabled: !isValid || typeof visitor === 'undefined',
                  size: 'small',
                },
              ]}
            />
          </View>
        </>
      ) : (
        <View style={styles.closeButton}>
          <HGButton
            fullWidth={true}
            variant="secondary"
            label={t('Back')}
            onPress={onClose}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#FFF',
    borderRadius: scale(10),
    paddingTop: scale(19),
    paddingBottom: scale(50),
    paddingHorizontal: scale(24),
    width: widthPercentageToDP(90),
  },
  headerContainer: {
    alignItems: 'center',
  },
  spacing: {
    flex: 1,
  },
  divider: {
    marginTop: scale(20),
    marginBottom: scale(27.5),
    borderTopWidth: 0.5,
    backgroundColor: '#707070',
    left: scale(-9),
    width: widthPercentageToDP(90) - scale(30),
  },
  closeButton: {
    marginTop: scale(50),
  },
});
