import React from 'react';
import {Image, View} from 'react-native';
import {widthPercentageToDP, scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import {useTranslation} from 'react-i18next';
export default ({productFoodAddic, allFoodAddic}) => {
  const {t, i18n} = useTranslation();

  return (
    <>
      {productFoodAddic.length > 0 ? (
        <View
          style={{
            justifyContent: 'center',
            marginTop: scale(28),
          }}>
          {productFoodAddic.map(foodId => (
            <>
              {allFoodAddic.some(x => x.id === foodId) ? (
                <>
                  {allFoodAddic
                    .filter(x => x.id === foodId)
                    .map(({id, dsp_nr, name}) => (
                      <HGText>
                        {dsp_nr + ' - ' + name[i18n.language] ??
                          name.de ??
                          name.en}
                      </HGText>
                    ))}
                </>
              ) : null}
            </>
          ))}
        </View>
      ) : null}
    </>
  );
};
