import {UserDetails} from 'components/HGIcon';
import HGText from 'components/HGText';
import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {scale} from 'utils/Scale';

export default ({onSelect}) => {
  const supported = 'contacts' in navigator && 'ContactsManager' in window;
  const getContact = async () => {
    const props = ['name', 'tel'];
    const opts = {multiple: false};
    try {
      const [contact] = await navigator.contacts.select(props, opts);
      const {
        name: [fullName],
        tel,
      } = contact;
      const names = fullName.split(' ');
      onSelect({
        firstName: names[0],
        lastName: names.slice(1).join(' ') ?? '',
        phone: tel[0] ?? '',
      });
    } catch (err) {
      console.log(err);
    }
  };
  return supported ? (
    <TouchableOpacity onPress={getContact}>
      <View style={styles.assignButtonContainer}>
        <UserDetails style={styles.assignButton} />
      </View>
    </TouchableOpacity>
  ) : (
    <View />
  );
};

const styles = StyleSheet.create({
  assignButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F1EEED',
    height: scale(32),
    width: scale(32),
    borderRadius: scale(16),
  },
  assignButton: {
    top: -2,
    left: -1,
  },
});
