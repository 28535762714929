import React, {useEffect, useState} from 'react';
import {View, StyleSheet, Linking, ScrollView} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import Avatar from './components/Avatar';
import UserNavigationButton from './components/UserNavigationButton';
import {useTranslation} from 'react-i18next';
import * as Icon from 'components/HGIcon';
import HGButton from '../../components/HGButton';
import useAccount from '../../hooks/useAccount';
import config from '../../config';
import useTicketCheckout from 'hooks/useTicketCheckout';
import LegalLinks from 'components/LegalLinks';

export default ({navigation}) => {
  const {t} = useTranslation();
  const {logout} = useAccount();
  const {fetchTenantDetails} = useTicketCheckout();
  const [initialize, setInitialize] = useState(false);
  const [tenantData, setTenantData] = useState({});

  useEffect(async () => {
    if (!initialize) {
      setInitialize(true);
      fetchTenantDetails(res => {
        const {data} = res || {};
        setTenantData(data);
      });
    }
  }, [initialize, fetchTenantDetails, setTenantData]);

  return (
    <Container>
      <ScrollView contentContainerStyle={{paddingBottom: scale(100)}}>
        <View style={styles.avatarContainer}>
          <Avatar />
        </View>
        <View style={styles.buttonsContainer}>
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                navigation.navigate('PersonalSettings');
              }}
              icon={<Icon.Settings />}
              label={t('user.navigation.personal')}
            />
          </View>
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() =>
                Linking.openURL(
                  `mailto:${config.SUPPORT_EMAIL}?subject=Anfrage`,
                )
              }
              icon={<Icon.QuestionCircle />}
              label={t('user.navigation.help')}
            />
          </View>
          <View style={config.DISABLE_QR_SCANNER ? {} : styles.button}>
            <UserNavigationButton
              onPress={() => {
                navigation.navigate('CoronaRegistration');
              }}
              icon={<Icon.Clipboard />}
              label={t('user.navigation.corona.corona')}
            />
          </View>
          {!config.DISABLE_QR_SCANNER && (
            <View>
              <UserNavigationButton
                onPress={() => {
                  navigation.navigate('QrCodeScanner');
                }}
                icon={<Icon.Qr />}
                label={t('user.navigation.qr')}
              />
            </View>
          )}
          <View style={[styles.button, {marginTop: scale(15)}]}>
            <UserNavigationButton
              onPress={() =>
                Linking.openURL(`${tenantData.urls.newsletter_url}`)
              }
              icon={<Icon.Settings />}
              label={t('user.navigation.newsletter')}
            />
          </View>
        </View>
        <View style={styles.logoutContainer}>
          <HGButton fullWidth={true} label={t('Log out')} onPress={logout} />
        </View>
        <LegalLinks data={tenantData.urls} />
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginTop: scale(14),
    alignItems: 'center',
  },
  buttonsContainer: {
    marginTop: scale(55),
  },
  button: {
    marginBottom: scale(15),
  },
  logoutContainer: {
    paddingHorizontal: scale(39),
    marginTop: scale(10),
    alignItems: 'center',
  },
});
