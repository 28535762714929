import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {useTranslation} from 'react-i18next';
import EventDetails from '../views/EventDetails';
import EventTicketSelection from '../views/EventTicketSelection';
import FrontNavigationHeader from '../components/FrontNavigationHeader';
import EventActionHeader from '../components/FrontNavigationHeader/EventActionHeader';

const Stack = createStackNavigator();
export default ({}) => {
  const {t} = useTranslation();
  return (
    <Stack.Navigator initialRouteName="EventDetails">
      <Stack.Screen
        name="EventDetails"
        component={EventDetails}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="EventTicketSelection"
        component={EventTicketSelection}
        options={{
          headerShown: true,
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={'Tickets'}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};
