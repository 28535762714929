import Container from 'components/Container';
import HGButton from 'components/HGButton';
import HGText from 'components/HGText';
import NotificationBanner from 'components/NotificationBanner';
import useCamera from 'hooks/useCamera';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActivityIndicator,
  Linking,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {Camera} from 'expo-camera';
import {scale} from 'utils/Scale';
import {BarCodeScanner} from 'expo-barcode-scanner';
import useModal from 'hooks/useModal';

export default ({}) => {
  const {t} = useTranslation();
  const {permission, gettingPermission} = useCamera();
  const [openModal, setOpenModal] = useState(false);
  const {showModal} = useModal();

  const onBarCodeScanned = useCallback(
    ({type, data}) => {
      if (!openModal) {
        setOpenModal(true);
        showModal({
          title: 'QR Code Found',
          body: data,
          actions: [
            {
              label: 'Ok',
              onPress: () => {
                setOpenModal(false);
                showModal(null);
              },
            },
          ],
        });
      }
    },
    [openModal, showModal],
  );

  return (
    <View style={{flex: 1}}>
      {gettingPermission && <ActivityIndicator />}
      {!gettingPermission && !permission && (
        <Container>
          <View style={styles.permissionErrorBanner}>
            <NotificationBanner
              body={t('Please grant camera permission')}
              actionText={
                <TouchableOpacity onPress={Linking.openSettings}>
                  <HGText variant="actionText">{t('View settings')}</HGText>
                </TouchableOpacity>
              }
            />
          </View>
        </Container>
      )}
      {!gettingPermission && permission && (
        <Camera
          style={StyleSheet.absoluteFill}
          type={Camera.Constants.Type.back}
          barCodeScannerSettings={{
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          }}
          onBarCodeScanned={onBarCodeScanned}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  fill: {
    flex: 1,
  },
  permissionErrorBanner: {
    marginTop: scale(20),
  },
});
