import React from 'react';
import {StyleSheet, TouchableOpacity, View, Linking} from 'react-native';
import {countries} from 'country-data';

import {useTranslation} from 'react-i18next';

import {scale} from 'utils/Scale';

import Row from 'components/Row';
import HGText from 'components/HGText';

export default ({
  address1,
  address2,
  city,
  company_name,
  country_code,
  legal_url,
  privacy_url,
  terms_url,
  zip,
  containerStyle,
}) => {
  const {t} = useTranslation();
  const countryCode = country_code?.toUpperCase() || '';
  return (
    <View style={[styles.container, containerStyle ? containerStyle : {}]}>
      <Row>
        <HGText variant="smallHeader" upperCase={true}>
          {t('checkoutOverview.paymentRecipient')}
        </HGText>
        <View style={styles.spacing} />
      </Row>
      <View style={styles.addressContainer}>
        <HGText>{company_name}</HGText>
        <HGText>{address1}</HGText>
        {address2 !== '' ? <HGText>{address2}</HGText> : null}
        <HGText>{zip + ' ' + city}</HGText>
        {countryCode !== '' ? (
          <HGText>{countries[countryCode].name}</HGText>
        ) : null}
      </View>
      <Row style={styles.urlLinksContainer}>
        <TouchableOpacity onPress={() => Linking.openURL(legal_url)}>
          <HGText style={styles.specialText}>
            {t('checkoutOverview.imprint')}
          </HGText>
        </TouchableOpacity>
        <View style={styles.linkButtonDivider} />
        <TouchableOpacity onPress={() => Linking.openURL(privacy_url)}>
          <HGText style={styles.specialText}>
            {t('checkoutOverview.privacy')}
          </HGText>
        </TouchableOpacity>
      </Row>
      <Row style={styles.termsContainer}>
        <HGText>
          {t('checkoutOverview.termMessage')
            .replace("'", '"')
            .replace("'", '"')}
          <TouchableOpacity onPress={() => Linking.openURL(terms_url)}>
            <HGText style={styles.specialText}>
              {t('checkoutOverview.term')}
            </HGText>
          </TouchableOpacity>
          {'.'}
        </HGText>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  spacing: {flex: 1},
  changeLink: {marginTop: scale(2)},
  textSpacingLess: {marginTop: scale(13)},
  textSpacing: {marginTop: scale(15)},
  container: {marginTop: scale(50)},
  addressContainer: {marginTop: scale(10)},
  urlLinksContainer: {marginTop: scale(10)},
  termsContainer: {marginTop: scale(10)},
  specialText: {color: '#991f32'},
  linkButtonDivider: {
    borderWidth: 1,
    marginRight: scale(10),
    marginLeft: scale(10),
  },
});
