import {configureStore, combineReducers} from '@reduxjs/toolkit';
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

import accountReducer from './account';

import ticketCheckoutReducer from './ticketCheckout';
import counterCheckoutReducer from './counterCheckout';
import eventsReducer from './events';
import coronaReducer from './corona';
import foodAddictiveReducer from './foodAddictive';

const rootReducer = combineReducers({
  account: accountReducer,
  ticketCheckout: ticketCheckoutReducer,
  counterCheckout: counterCheckoutReducer,
  corona: coronaReducer,
  events: eventsReducer,
  foodAddictive: foodAddictiveReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistor = persistStore(store);

export default store;
