import React, {useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {CardContainer} from '../../../components/Card';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import {useTranslation} from 'react-i18next';
import {ordinal, parseDisplayDate} from '../../../utils/Utils';
import Row from 'components/Row';
import {DateTime} from 'luxon';

export default ({title, date, time}) => {
  const {t, i18n} = useTranslation();
  return (
    <CardContainer padded={true}>
      <HGText variant="header" style={styles.header}>
        {t('ticket details')}
      </HGText>
      <HGText style={styles.title}>{title}</HGText>
      <Row>
        <HGText variant="eventDetailsMeta" color="primary">
          {parseDisplayDate(date, i18n.language)}
        </HGText>
        <HGText
          variant="eventDetailsMeta"
          color="primary"
          style={styles.dateTimeDivider}>
          |
        </HGText>
        <HGText variant="eventDetailsMeta" color="primary" style={styles.label}>
          {time}
        </HGText>
      </Row>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: scale(18),
    marginBottom: scale(13),
    textTransform: 'uppercase',
  },
  title: {
    fontSize: scale(17),
    textTransform: 'uppercase',
    marginBottom: scale(9),
  },
  datetime: {
    fontSize: scale(15),
  },
  dateTimeDivider: {
    marginHorizontal: scale(8),
  },
});
