import React from 'react';
import {View} from 'react-native';
import {heightPercentageToDP, scale} from '../../utils/Scale.js';
import Component from './index.js';
export default props => {
  if (props.route?.params?.screenType === 'checkout'){
    return (
      <View
        style={{
          height:
            heightPercentageToDP(100) -
            (props.route?.params?.screenType
              ? props.route?.params?.screenType === 'checkout'
                ? 75
                : 0
              : 75),
        }}>
        <Component {...props} />
      </View>
    );
  }
  else{
    return ( <Component {...props} /> )
  }
};
