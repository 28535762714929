import {createSlice} from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import {} from './helpers';
import persistConfig from './persistConfig';

// items will be in the form of key being categoryId-itemId-optionId and the value being the quantity

const initialState = {
  initializeProducts: false,
  products: [],
  productCategory: [],
  items: {},
  timeslot: false,
  lastCheckout: {},
  paymentMethod: 'credit',
};

const counterCheckoutSlice = createSlice({
  name: 'counterCheckout',
  initialState,
  reducers: {
    addItem: (state, action) => {
      const key = action.payload.itemOptionKey;
      const userId = action.payload.userId;

      var userItem = state.items[userId];
      if (userItem && userItem[key]) {
        // we just need to increase the quantity
        state.items = {...state.items, [userId] : {...userItem, [key]: userItem[key] + 1}};
      } else {
        // start from 1 quantity
        state.items = {...state.items, [userId] : {...userItem, [key]: 1}};
      }
    },
    removeItem: (state, action) => {
      const key = action.payload.itemOptionKey;
      const userId = action.payload.userId;
      
      var userItem = state.items[userId];

      if (userItem && userItem[key]) {
        // we just need to decrease the quantity
        state.items = {...state.items, [userId] : {...userItem, [key]: userItem[key] - 1}};

        if (userItem[key] - 1 <= 0) {
          // no longer there, we remove
          const {[key]: value, ...finalObject} = state.items[userId];
          state.items = {...state.items, [userId] :finalObject};
        }
      }
    },
    deleteItem: (state, action) => {
      const key = action.payload.itemOptionKey;
      const userId = action.payload.userId;
      const {[key]: value, ...finalObject} = state.items[userId];
      state.items = {...state.items, [userId] :finalObject};
    },
    updateTimeslot: (state, action) => {
      state.timeslot = action.payload.timeslot;
    },
    updatePaymentMethod: (state, action) => {
      state.paymentMethod = action.payload.paymentMethod;
    },
    clearCart: (state, action) => {
      const userId = action.payload.userId;
      state.lastCheckout = {items: {...state.items}, timeslot: state.timeslot};
      state.items = {...state.items, [userId]:{}};
      state.timeslot = false;
    },
    resetLastOrder: (state, action) => {
      state.items = {...state.lastCheckout.items};
      state.timeslot = state.lastCheckout.timeslot;
    },
    updateProducts: (state, action) => {
      state.initializeProducts = true;
      state.products = action.payload.products;
      state.productCategory = action.payload.productCategory;
    },
  },
});

const actions = counterCheckoutSlice.actions;

export const modifyCart =
  ({userId, itemOptionKey, modifier}, cb) =>
  async dispatch => {
    dispatch(
      actions[
        modifier === 'add'
          ? 'addItem'
          : modifier === 'remove'
          ? 'removeItem'
          : 'deleteItem'
      ]({userId, itemOptionKey}),
    );
    cb && cb();
  };

export const updateTimeslot =
  ({timeslot}, cb) =>
  async dispatch => {
    dispatch(actions.updateTimeslot({timeslot}));
    cb && cb();
  };

export const updatePaymentMethod =
  ({paymentMethod}, cb) =>
  async dispatch => {
    dispatch(actions.updatePaymentMethod({paymentMethod}));
    cb && cb();
  };

export const clearCart = ({userId}, cb) => async dispatch => {
  dispatch(actions.clearCart({userId}));
  cb && cb();
};
export const updateProducts =
  ({products, productCategory}, cb) =>
  async dispatch => {
    dispatch(actions.updateProducts({products, productCategory}));
    cb && cb();
  };

export const resetLastOrder = () => async dispatch => {
  dispatch(actions.resetLastOrder());
};

export default persistReducer(persistConfig, counterCheckoutSlice.reducer);
