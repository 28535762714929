import {CardContainer} from 'components/Card';
import HGText from 'components/HGText';
import QuantityStepInput from 'components/QuantityStepInput';
import Row from 'components/Row';
import useCounterCheckout from 'hooks/useCounterCheckout';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from 'utils/Scale';
import {parseCurrency} from '../../../utils/Utils';

export default ({itemKey, name, option, quantity, price, stock}) => {
  const {addCart, removeCart, deleteCart, cartItem : cart} = useCounterCheckout();
  return (
    <CardContainer densePadded={true}>
      <Row>
        <View style={styles.quantityContainer}>
          <HGText fontSize={scale(17)}>{quantity}</HGText>
        </View>
        <View style={styles.nameContainer}>
          <HGText fontSize={scale(17)}>{name}</HGText>
          {/* <HGText>Option: {option}</HGText> */}
        </View>
        <View style={styles.priceContainer}>
          <HGText fontSize={scale(17)}>
            {parseCurrency({amount: price * quantity})}
          </HGText>
        </View>
      </Row>
      <Row>
        <View style={styles.quantityInputContainer}>
          <QuantityStepInput
            hideQuantity={true}
            quantity={quantity}
            onChange={(val, isAdd) => {
              if (isAdd) {
                addCart({key: itemKey});
              } else {
                removeCart({key: itemKey});
              }
            }}
            stock={stock}
            cart={cart}
            itemId={parseInt(itemKey)}
          />
        </View>
      </Row>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  nameContainer: {
    flex: 1,
    paddingHorizontal:"5%"
  },
  priceContainer: {
    alignItems: 'center',
  },
  nameOptionContainer: {
    marginTop: scale(5),
  },
  quantityInputContainer: {
    marginTop: scale(27),
    flex: 1,
    alignItems: 'flex-end'
  },
});
