import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import Container from '../../components/Container';
import UserNavigationButton from './components/UserNavigationButton';
import * as Icon from 'components/HGIcon';
import {scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
import useAccount from '../../hooks/useAccount';

export default ({navigation}) => {
  const {i18n} = useTranslation();
  const {updateLanguage} = useAccount();
  return (
    <Container>
      <ScrollView>
        <View style={styles.buttonsContainer}>
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                i18n.changeLanguage('de');
                updateLanguage({language: 'de'});
                navigation.goBack();
              }}
              icon={<Icon.FlagGermany style={styles.flag} />}
              label={'Deutsch'}
              labelStyle={styles.label}
            />
          </View>
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                i18n.changeLanguage('en');
                updateLanguage({language: 'en'});
                navigation.goBack();
              }}
              icon={<Icon.FlagEnglish style={styles.flag} />}
              label={'English'}
              labelStyle={styles.label}
            />
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  buttonsContainer: {
    marginTop: scale(55),
  },
  button: {
    marginBottom: scale(15),
  },
  flag: {
    width: scale(30),
    height: scale(18),
  },
  label: {
    color: 'black',
    fontSize: scale(17),
    fontFamily: 'Avenir Next',
  },
});
