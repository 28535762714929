import Container from 'components/Container';
import HGButton from 'components/HGButton';
import {
  CovidCertificate,
  Document,
  Folder,
  HospitalCertificate,
  IdentityCard,
} from 'components/HGIcon';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ScrollView, StyleSheet, View} from 'react-native';
import {heightPercentageToDP, scale} from 'utils/Scale';
import useModal from '../../hooks/useModal';
import Avatar from '../User/components/Avatar';
import UserNavigationButton from '../User/components/UserNavigationButton';
import DocumentSelectButton from './components/DocumentSelectButton';
import UserQRCode from './components/UserQRCode';
import * as Icon from 'components/HGIcon';

export default ({navigation}) => {
  const {t} = useTranslation();
  const [document, _setDocument] = useState('default');
  const {showModal} = useModal();

  const getDocumentIcon = useCallback(() => {
    switch (document) {
      case 'vaccination':
        return <CovidCertificate />;
      case 'recovery':
        return <Document />;
      case 'test':
        return <HospitalCertificate />;
      default:
        return <Folder />;
    }
  }, [document]);

  const getDocumentLabel = useCallback(() => {
    switch (document) {
      case 'vaccination':
        return t('vaccination certificate');
      case 'recovery':
        return t('recovery');
      case 'test':
        return t('corona test');
      default:
        return t('documents');
    }
  }, [document, t]);

  const setDocument = val => {
    _setDocument(val);
    navigation.navigate('Upload', {documentType: val});
  };

  return (
    <Container showFloatingBack={navigation}>
      <ScrollView contentContainerStyle={{height: '100%'}}>
        <View style={styles.avatarContainer}>
          <Avatar />
        </View>
        <View style={styles.menuButtonsContainer}>
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                showModal({
                  title: 'QR Code',
                  component: () => <UserQRCode />,
                  titleTextStyle: {marginBottom: scale(33)},
                });
              }}
              icon={<Icon.Qr />}
              label={t('user.navigation.qrcode')}
            />
          </View>
          <View style={styles.button}>
            <DocumentSelectButton
              onChange={setDocument}
              icon={getDocumentIcon()}
              label={getDocumentLabel()}
            />
          </View>
          <View style={{zIndex: -1}}>
            <UserNavigationButton
              onPress={() => navigation.navigate('Contact')}
              icon={<IdentityCard />}
              label={t('contact details')}
            />
          </View>
        </View>
        <View style={styles.spacing} />

      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginTop: scale(14),
    alignItems: 'center',
  },
  menuButtonsContainer: {
    marginTop: scale(55),
  },
  button: {
    marginBottom: scale(15),
  },
  spacing: {
    flex: 1,
    zIndex: -1,
  },
  submitButton: {
    zIndex: -1,
    marginTop: scale(50),
    marginBottom: scale(55),
    alignItems: 'center',
    paddingHorizontal: scale(39),
  },
});
