import React, {useCallback, useState} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  NativeModules,
  LayoutAnimation,
  Platform,
} from 'react-native';
import {
  CardContainer,
  styles as CardContainerStyles,
} from '../../../components/Card';
import {scale, widthPercentageToDP} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import Row from 'components/Row';
import IconButton from 'components/IconButton';
import {PlusCircle} from 'components/HGIcon';
import hexToRgba from 'hex-to-rgba';
import Colors from 'utils/Colors';
import {useTranslation} from 'react-i18next';
import QuantityStepInput from 'components/QuantityStepInput';
import useCounterCheckout from 'hooks/useCounterCheckout';
import {parseCurrency} from '../../../utils/Utils';
import useModal from 'hooks/useModal';
import ModalFoodAdditive from './ModalFoodAdditive';
const {UIManager} = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export default ({
  name,
  description,
  itemId,
  price,
  stock,
  productFoodAddic,
  allFoodAddic,
}) => {
  const {t} = useTranslation();
  const {addCart, removeCart, deleteCart, cartItem: cart} = useCounterCheckout();

  const [showMore, _setShowMore] = useState(false);
  const setShowMore = useCallback(() => {
    LayoutAnimation.easeInEaseOut();
    _setShowMore(!showMore);
  }, [showMore]);

  const {showModal} = useModal();

  return (
    <CardContainer densePadded={true}>
      <Row>
        <View style={{flex: 0.9}}>
          <HGText style={styles.title}>{name}</HGText>
        </View>
        <View
          style={{
            flex: 0.1,
            paddingRight: scale(6),
          }}>
          {productFoodAddic.length > 0 && (
            <TouchableOpacity
              onPress={() => {
                showModal({
                  title: t('foodAdditive.modalHeader'),
                  disableBottomButton: true,
                  enableTopRightButton: true,
                  component: () => (
                    <ModalFoodAdditive
                      productFoodAddic={productFoodAddic}
                      allFoodAddic={allFoodAddic}
                    />
                  ),
                });
              }}
              style={styles.buttonFoodAdditive}>
              <HGText style={styles.buttonTextFoodAdditive}>{'i'}</HGText>
            </TouchableOpacity>
          )}
        </View>
      </Row>

      <Row style={styles.optionContainer}>
        <HGText style={styles.optionDescription}>{description}</HGText>
        <View style={styles.spacer} />
        <IconButton
          onPress={setShowMore}
          size={scale(30)}
          icon={<PlusCircle style={styles.moreButtonIcon} />}
        />
      </Row>
      <HGText style={styles.optionMinPrice}>
        {parseCurrency({amount: price})}
      </HGText>
      {showMore && (
        <>
          <View style={styles.moreDivider} />
          <View style={styles.optionsContainer}>
            <Row key={`${name}-${description}`} style={styles.optionRow}>
              <QuantityStepInput
                colorIfEnabled={true}
                minValue={0}
                size="small"
                quantity={cart.items[`${itemId}`]}
                // quantity={0}
                onChange={(val, isAdd) => {
                  if (isAdd) {
                    addCart({key: `${itemId}`});
                  } else {
                    removeCart({key: `${itemId}`});
                  }
                }}
                stock={stock}
                cart={cart}
                itemId={itemId}
              />
            </Row>
            {stock <= 5 && stock !== false ? (
              <HGText style={styles.textStockCheck}>
                {stock > 0
                  ? t('selectTicketQtyCard.stockCheckProduct').replace(
                      '${stock}',
                      stock,
                    )
                  : null}
                {stock === 0 ? t('selectTicketQtyCard.soldOut') : null}
              </HGText>
            ) : null}
          </View>
        </>
      )}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  spacer: {flex: 1},
  title: {
    fontSize: scale(17),
    marginBottom: scale(9),
    fontWeight: '500',
  },
  optionContainer: {
    alignItems: 'center',
    marginBottom: scale(10),
  },
  optionDescription: {
    fontStyle: 'italic',
  },
  moreButtonIcon: {
    width: scale(45),
    height: scale(45),
  },
  optionMinPrice: {
    fontSize: scale(15),
    fontWeight: '500',
  },
  moreDivider: {
    width: widthPercentageToDP(100),
    marginLeft:
      Platform.OS === 'web'
        ? scale(-22.5)
        : -CardContainerStyles.densedPadded.paddingHorizontal,
    marginTop: scale(17),
    marginBottom: scale(15),
    borderTopWidth: 1,
    borderTopColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
  },
  optionsContainer: {
    paddingLeft: scale(7),
  },
  optionRow: {
    marginTop: scale(17),
    alignItems: 'center',
  },
  optionExactDescription: {
    fontSize: scale(15),
    marginLeft: scale(30.5),
  },
  textStockCheck: {
    marginTop: scale(10),
    fontWeight: 'bold',
    color: Colors.STOCK_CHECK_COLOR,
    maxWidth: scale(250),
  },
  buttonFoodAdditive: {
    marginLeft: scale(10),
    borderColor: '#a24855',
    borderWidth: 1,
    width: scale(20),
    height: scale(20),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 50,
    alignSelf: 'flex-end',
  },
  buttonTextFoodAdditive: {
    color: '#a24855',
    fontWeight: 'bold',
  },
});
