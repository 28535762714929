import React, {useCallback, useState} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  NativeModules,
  LayoutAnimation,
} from 'react-native';
import {CardContainer} from '../../../components/Card';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import {useTranslation} from 'react-i18next';
const {UIManager} = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

const cutMoreText = _body => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(_body, 'text/html');
  const hasMore = htmlDoc.body.children?.length > 1;
  let summary = _body;
  if (hasMore) {
    summary = htmlDoc.body.children[0].innerHTML;
  }
  return {hasMore, summary};
};

export default ({title, body, extraComponents = []}) => {
  const {t} = useTranslation();

  const {hasMore, summary} = cutMoreText(body);
  const [showMore, _setShowMore] = useState(false);
  const setShowMore = useCallback(() => {
    LayoutAnimation.easeInEaseOut();
    _setShowMore(!showMore);
  }, [showMore]);
  return (
    <CardContainer padded={true}>
      <HGText variant="header" style={styles.title}>
        {title}
      </HGText>
      <View>
        <HGText variant="eventDetailsMeta">
          <div
            dangerouslySetInnerHTML={{
              __html: hasMore ? (showMore ? body : summary) : body,
            }}
          />
        </HGText>
        {hasMore ? (
          <TouchableOpacity onPress={setShowMore}>
            <HGText variant="actionText" style={styles.more}>
              {showMore ? t('less') : t('more')}
            </HGText>
          </TouchableOpacity>
        ) : (
          <View />
        )}
      </View>
      {extraComponents}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: scale(18),
    marginBottom: scale(13),
    textTransform: 'uppercase',
  },
  row: {
    marginBottom: scale(6),
    alignItems: 'center',
  },
  label: {marginLeft: scale(4)},
  more: {fontSize: scale(15), marginTop: scale(7)},
});
