import React, {useEffect, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Container from 'components/Container';
import LoginBox from './components/LoginBox';
import {scale} from '../../utils/Scale';
import HGButton from 'components/HGButton';
import {useTranslation} from 'react-i18next';
import HGText from '../../components/HGText';
import Row from 'components/Row';
import AvatarPlaceholder from './components/AvatarPlaceholder';
import useAccount from '../../hooks/useAccount';
import useModal from '../../hooks/useModal';
import NotificationBanner from 'components/NotificationBanner';
import LegalLinks from 'components/LegalLinks';
import useTicketCheckout from 'hooks/useTicketCheckout';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const [login, setLogin] = useState({username: '', password: ''});
  const {login: _loginUser, loading} = useAccount();
  const {showModal} = useModal();
  const {fetchTenantDetails} = useTicketCheckout();
  const [initialize, setInitialize] = useState(false);
  const [tenantData, setTenantData] = useState({});

  useEffect(async () => {
    if (!initialize) {
      setInitialize(true);
      fetchTenantDetails(res => {
        const {data} = res || {};
        if (data) {
          setTenantData(data);
        }
      });
    }
  }, [initialize, fetchTenantDetails, setTenantData]);

  const loginUser = async function () {
    if (!login.username || !login.password) return;
    try {
      const res = await _loginUser(login);
      if (res.error) {
        showModal({
          title: t('errors.login.title'),
          body: t('errors.login.body'),
        });
        return;
      }
      // if (route.params?.redirect) {
      //   navigation.replace(route.params?.redirect);
      // }
      const isRoutes = navigation
        .dangerouslyGetParent()
        ?.dangerouslyGetState()
        ?.routes.filter(x => x.name === 'CheckoutLogin');
      if (isRoutes.length > 0) {
        const {redirect} = isRoutes[0].params;
        navigation.replace(redirect);
      }
    } catch (err) {}
  };

  return (
    <Container>
      <ScrollView contentContainerStyle={{paddingBottom: scale(100)}}>
        <View style={styles.avatar}>
          <AvatarPlaceholder />
          <View style={styles.notification}>
            <NotificationBanner body={t('messages.loginMsg')} />
          </View>
        </View>
        <View style={styles.login}>
          <LoginBox onChange={setLogin} navigation={navigation} />
        </View>

        <View style={styles.button}>
          <HGButton label={t('Login')} onPress={loginUser} loading={loading} />
          <TouchableOpacity
            onPress={() => {
              if (route.params?.screenType === 'checkout') {
                navigation.navigate('CheckoutRegister', route.params);
              } else {
                navigation.navigate('Register');
              }
            }}>
            <Row style={styles.registerLink}>
              <HGText>{`${t('account.login.register')} `}</HGText>
              <HGText variant="link">{t('account.login.register-link')}</HGText>
            </Row>
          </TouchableOpacity>
        </View>
        <LegalLinks data={tenantData.urls} />
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  avatar: {
    marginTop: scale(50),
    alignItems: 'center',
    marginBottom: scale(21),
  },
  login: {
    marginBottom: scale(25),
  },
  button: {
    alignItems: 'center',
  },
  registerLink: {
    marginTop: scale(10),
  },
  notification: {
    marginTop: scale(20),
  },
});
