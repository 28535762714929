import {createSlice} from '@reduxjs/toolkit';
import {uploadDocument as _uploadDocument} from './helpers';
import persistReducer from 'redux-persist/es/persistReducer';
import persistConfig from './persistConfig';

const initialState = {
  contact: {
    firstName: '',
    lastName: '',
    address: '',
    postal: '',
    email: '',
    phone: '',
  },
};

const coronaSlice = createSlice({
  name: 'corona',
  initialState,
  reducers: {
    saveContactDetails: (state, action) => {
      const {payload} = action;
      state.contact = {...state.contact, ...payload};
    },
  },
});

export const actions = coronaSlice.actions;

export const saveContactDetails =
  (profile, cb) =>
  async dispatch => {
    dispatch(
      actions.saveContactDetails(profile),
    );
    cb && cb();
  };

export default persistReducer(persistConfig, coronaSlice.reducer);
