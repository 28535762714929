import React from 'react';
import {View, StyleSheet} from 'react-native';
import {scale} from '../../../utils/Scale';
import {UserFill} from 'components/HGIcon/';

const AvatarPlaceholder = ({}) => {
  return (
    <View style={styles.container}>
      <UserFill style={styles.avatar} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFF',
    width: scale(120),
    borderRadius: scale(60),
    height: scale(120),
  },
  avatar: {
    height: scale(70),
    width: scale(60),
  },
});

export default AvatarPlaceholder;
