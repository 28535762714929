import React, {useEffect} from 'react';
import {ScrollView, StyleSheet, View, Image} from 'react-native';
import Container from 'components/Container';
import {scale} from 'utils/Scale';
import MyTicketCard from './components/MyTicketCard';
import getTickets from 'hooks/getTickets';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import HGText from '../../components/HGText';

export default ({navigation, route}) => {
  const isLoggedIn = useSelector(state => state.account.loggedIn);
  const {tickets, loading} = getTickets();
  const {i18n, t} = useTranslation();
  const {goToTicketId} = route.params ?? {};

  useEffect(() => {
    if (!loading && tickets.length > 0 && goToTicketId) {
      navigation.setParams({goToTicketId: null});
      const ticket = tickets.filter(
        ({items}) => items.filter(({id}) => id == goToTicketId).length,
      )[0];
      if (ticket) {
        navigation.navigate('Ticket', {ticket});
      }
    }
  }, [navigation, goToTicketId, tickets, loading]);

  useEffect(() => {
    if (!isLoggedIn) {
      navigation.navigate('User');
    }
  }, [isLoggedIn, navigation]);

  const renderEmptyComponent = () => {
    return loading ? (
      <View />
    ) : (
      <View>
        <Image
          source={require('../../../assets/images/Ticket_Icon.png')}
          style={styles.ticketIcon}
        />
        <HGText
          variant={'navigatorHeaderTitle'}
          style={styles.emptyComponentTitle}>
          {t('tickets.empty_data')}
        </HGText>
      </View>
    );
  };

  const renderTickets = () => {
    return tickets.map((ticket, i) => {
      const {event, items} = ticket || {};
      const {title, start_at, date_at, admission_at} = event || {};
      return (
        <View
          style={styles.ticketContainer}
          key={items[0] && (items[0].uuid ?? `ticket-${i}`)}>
          <MyTicketCard
            uuid={items[0] && items[0].uuid}
            eventName={
              title ? title[i18n.language] ?? title.en ?? title.de : '--'
            }
            eventStart={start_at ? start_at : '-'}
            date={date_at ? date_at : '-'}
            admission={admission_at ? admission_at : '-'}
            onPress={() => {
              navigation.navigate('Ticket', {ticket, initialTab: 'Tickets'});
            }}
          />
        </View>
      );
    });
  };

  return (
    <Container>
      <ScrollView>
        {loading && <BasicLoadingIndicator />}
        <View style={styles.spacer} />
        {tickets.length > 0 ? renderTickets() : renderEmptyComponent()}
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  ticketContainer: {
    marginBottom: scale(20),
  },
  spacer: {
    height: scale(50),
  },
  emptyComponentTitle: {
    textAlign: 'center',
    color: '#9e8b84',
    fontSize: scale(24),
  },
  ticketIcon: {
    width: scale(80),
    height: scale(80),
    alignSelf: 'center',
    marginBottom: scale(20),
  },
});
