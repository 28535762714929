import {CardContainer} from 'components/Card';
import Container from 'components/Container';
import HGButton from 'components/HGButton';
import HGText from 'components/HGText';
import HGTextInput from 'components/HGTextInput';
import TwoActionButtons from 'components/TwoActionButtons';
import useCorona from 'hooks/useCorona';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {ScrollView, StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {scale} from 'utils/Scale';
import inputValidations from '../../utils/inputValidations';
import Avatar from '../User/components/Avatar';

export default ({navigation}) => {
  const {t} = useTranslation();
  const initialValues = useSelector(state => state.corona.contact);
  const account = useSelector(state => state.account);
  const id = useSelector(state => state.account.id);
  const [initialize, setInitialize] = useState(false);
  const {onlyAlphabetWithSpace} = inputValidations();
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: {errors, isDirty, isValid},
  } = useForm({mode: 'onChange'});

  const {saveContactDetails} = useCorona();

  const submitForm = data => {
    data.id = id;
    saveContactDetails(data, () => {
      navigation.navigate('Home');
    });
  };

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      if (initialValues[id]) {
        Object.keys(initialValues[id]).map(key => {
          setValue(key, initialValues[id][key], {
            shouldDirty: true,
            shouldValidate: true,
          });
        });
      }
    }
  }, [initialize, initialValues[id], setValue]);

  useEffect(() => {
    if (initialValues[id] === undefined){
      saveContactDetails({id:id, firstName: account.user.first_name,lastName: account.user.last_name,email: account.user.email,phone: account.user.phone_number})
      setInitialize(false);
    }
  }, []);

  return (
    <ScrollView>
      <Container>
        <CardContainer densePadded={true} containerStyle={styles.notice}>
          <HGText>{t('user.coronaRegistration.contactDetailsNotice')}</HGText>
        </CardContainer>
        <View style={styles.avatarContainer}>
          <Avatar hideName={true} hideUpload={true} size={scale(100)} />
        </View>
        <View style={styles.formContainer}>
          <HGTextInput
            variant="form"
            form={{
              control,
              name: 'firstName',
              rules: {required: true, ...onlyAlphabetWithSpace},
            }}
            error={errors['firstName']}
            label={t('forms.firstName')}
          />
          <HGTextInput
            variant="form"
            form={{
              control,
              name: 'lastName',
              rules: {required: true, ...onlyAlphabetWithSpace},
            }}
            error={errors['lastName']}
            label={t('forms.lastName')}
          />
          {/* 
          <HGTextInput
            variant="form"
            form={{
              control,
              name: 'address',
              rules: {required: true},
            }}
            error={errors['address']}
            label={t('forms.address')}
          />
          <HGTextInput
            variant="form"
            form={{
              control,
              name: 'postal',
              rules: {required: true},
            }}
            error={errors['postal']}
            label={t('forms.postal')}
          />
          */}
          <HGTextInput
            variant="form"
            inputProps={{autoCapitalize: 'none'}}
            form={{
              control,
              name: 'email',
              rules: {
                validate: email => {
                  const re =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  return (
                    re.test(String(email).toLowerCase()) || t('errors.email')
                  );
                },
              },
            }}
            error={errors['email']}
            label={t('forms.email')}
          />
          <HGTextInput
            form={{
              control,
              name: 'phone',
              rules: {required: true},
            }}
            error={errors['phone']}
            label={t('forms.phone')}
          />
        </View>
        <View style={styles.actionButtonsContainer}>
          <TwoActionButtons
            buttons={[
              {label: t('Back'), onPress: navigation.goBack},
              {
                label: t('Continue'),
                onPress: handleSubmit(submitForm),
                disabled: !isDirty || !isValid,
              },
            ]}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  notice: {
    marginTop: scale(40),
  },
  avatarContainer: {
    marginTop: scale(14),
    marginBottom: scale(19),
    alignItems: 'center',
  },
  formContainer: {
    marginBottom: scale(49),
  },
  actionButtonsContainer: {
    marginBottom: scale(104),
  },
});
