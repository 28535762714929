import React, {useCallback, useEffect, useState} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import hexToRgba from 'hex-to-rgba';

import {scale} from 'utils/Scale';
import useModal from 'hooks/useModal';

import ProgressPills from 'components/ProgressPills';
import Container from 'components/Container';
import TwoActionButtons from '../../components/TwoActionButtons';
import SpecifyRegistrationData from './components/SpecifyRegistrationData.js';
import EventSummaryCard from './components/EventSummaryCard';
import {TicketCard, TicketOptionCard} from 'components/TicketCardContainer';
import AssignPersonModal from './components/AssignPersonModal';
import InvoiceAddress from './components/InvoiceAddress';
import PaymentMethod from './components/PaymentMethod';
import Subtotal from 'components/Subtotal';
import useTicketCheckout from 'hooks/useTicketCheckout';
import PaymentRecipient from './components/PaymentRecipient';
import moment from 'moment';
import {calculateTaxAmount} from '../../utils/Utils';

export default ({navigation}) => {
  const {t} = useTranslation();
  const {event, tickets, options, paymentMethod, billing, assignments} =
    useSelector(state => state.ticketCheckout);
  const productsObj = useSelector(state => state.events.products);
  const {updateAssignments, loading, fetchPaymentRecipient} =
    useTicketCheckout();
  const {showModal} = useModal();
  const [initialize, setInitialize] = useState(false);
  const [recipientData, setRecipientData] = useState({});

  useEffect(async () => {
    if (!initialize) {
      setInitialize(true);
      fetchPaymentRecipient(res => {
        setRecipientData(res);
      });
    }
  }, [initialize, fetchPaymentRecipient, setRecipientData]);

  const {
    control,
    watch,
    formState: {errors},
    reset,
    handleSubmit,
    setValue,
  } = useForm({mode: 'onChange'});

  const setAssignments = useCallback(
    (productId, i, data) => {
      updateAssignments({productId, index: i, data});
    },
    [updateAssignments],
  );

  const onPaynowPressHandler = () => {
    var date = moment(event.date_at + ' ' + event.start_at, 'YYYY-MM-DD HH:mm');

    if (moment().isSameOrAfter(date)) {
      showModal({
        title: t('Invalid Event'),
        body: t('Event Started Error'),
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: 'OrderPendingPayment',
            params: {
              screen: 'OrderPendingPayment',
              params: {
                type: 'ticket',
                billing,
                tickets,
                assignments,
                options,
              },
            },
          },
        ],
      });
    }
  };

  const showAssignPersonModal = useCallback(
    (productId, i) => {
      showModal({
        component: () => (
          <AssignPersonModal
            initialValues={assignments[productId][i]}
            index={i}
            watch={watch}
            setValue={setValue}
            control={control}
            errors={errors}
            onClose={() => showModal()}
            onAssign={handleSubmit(setAssignments.bind(null, productId, i))}
            reset={reset}
          />
        ),
        type: 'empty',
      });
    },
    [
      control,
      errors,
      showModal,
      watch,
      setValue,
      reset,
      handleSubmit,
      setAssignments,
      assignments,
    ],
  );

  const getTicketContainers = () => {
    const arr = [];
    Object.keys(tickets).map(productId => {
      const quantity = tickets[productId];
      const selectedProduct = productsObj[productId];

      for (let i = 0; i < quantity; i++) {
        arr.push(
          <View key={`ticket-${productId}-${i}`} style={styles.spacing}>
            <TicketCard
              ticketType={selectedProduct}
              visitor={assignments[productId][i]}
              onAssign={showAssignPersonModal.bind(null, productId, i)}
            />
          </View>,
        );
      }
    });
    return arr;
  };

  const getTicketOptionContainers = () => {
    return options
      .filter(({qty}) => qty > 0)
      .map(({qty, title, price}, i) => (
        <View key={`ticket-option-${i}`} style={styles.spacing}>
          <TicketOptionCard quantity={qty} unit={title} price={price} />
        </View>
      ));
  };

  // TODO: Might need to put this in a util class
  const calculateTotalPrice = () => {
    let total = 0;

    Object.keys(tickets).forEach(productId => {
      const quantity = tickets[productId];
      total += quantity * productsObj[productId].price;
    });

    // Options
    for (let i = 0; i < options.length; i++) {
      const {qty, price} = options[i] || {};
      total += qty * price;
    }

    return total;
  };

  const calculateRates = () => {
    let rates = [];
    Object.keys(tickets).forEach(productId => {
      const quantity = tickets[productId];
      const ticketRate = productsObj[productId].vat_rate;
      const ticketSubPrice = quantity * productsObj[productId].price;
      rates = calculateTaxAmount(rates, ticketRate, ticketSubPrice);
    });

    // Options
    for (let i = 0; i < options.length; i++) {
      const {qty, price, vat_rate} = options[i] || {};

      const optionRate = vat_rate;
      const optionSubPrice = qty * price;
      rates = calculateTaxAmount(rates, optionRate, optionSubPrice);
    }

    return rates;
  };

  return (
    <ScrollView>
      <Container>
        <View style={styles.progressContainer}>
          <ProgressPills total={3} current={3} />
        </View>
        <View style={styles.spacing}>
          <SpecifyRegistrationData />
        </View>
        <View style={styles.spacing}>
          <EventSummaryCard event={event} tickets={tickets} options={options} />
        </View>
        {getTicketContainers()}
        {getTicketOptionContainers()}
        <View style={styles.divider} />
        <InvoiceAddress {...billing} navigation={navigation} />
        <PaymentRecipient {...recipientData} />
        {/*<View style={styles.divider} />
         <PaymentMethod paymentMethod={paymentMethod} navigation={navigation} /> */}
        <View style={styles.divider} />
        <Subtotal
          total={calculateTotalPrice()}
          label="total"
          vatRates={calculateRates()}
        />
        <View style={styles.actionButtons}>
          <TwoActionButtons
            buttons={[
              {
                label: t('checkoutOverview.cancel'),
                onPress: navigation.goBack,
                disableContainer: true,
              },
              {
                loading,
                disabled: Object.values(assignments)
                  .reduce((a, b) => [...a, ...b], [])
                  .reduce((a, b) => a || !b, false),
                label: t('checkoutOverview.payNow'),
                onPress: onPaynowPressHandler,
              },
            ]}
            isVertical={true}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  spacing: {
    marginBottom: scale(13),
  },
  divider: {
    marginVertical: scale(25),
    borderTopWidth: 1,
    borderColor: hexToRgba('#707070', 0.5),
  },
  actionButtons: {
    marginTop: scale(50),
    marginBottom: scale(70),
  },
});
