import React from 'react';
import {View, StyleSheet} from 'react-native';
import Row from 'components/Row';
import HGText from 'components/HGText';
import {RightChevron} from 'components/HGIcon';
import {scale} from '../../../../utils/Scale';

export default ({text}) => (
  <Row style={styles.container}>
    <View style={styles.header}>
      <HGText variant="header">{text}</HGText>
    </View>
  </Row>
);

const styles = StyleSheet.create({
  container: {
    marginBottom: scale(30),
    alignItems: 'center',
  },
  header: {
    flex: 1,
  },
  icon: {
    height: scale(16),
  },
});
