import React, {useEffect} from 'react';
import {View, Text, StyleSheet, ScrollView} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import Avatar from './components/Avatar';
import {useSelector} from 'react-redux';
import UserNavigationButton from './components/UserNavigationButton';
import {useTranslation} from 'react-i18next';
import * as Icon from 'components/HGIcon';
import HGButton from '../../components/HGButton';
import useAccount from '../../hooks/useAccount';

export default ({navigation}) => {
  const {t} = useTranslation();
  const {user, appLanguage} = useSelector(state => ({
    user: state.account.user,
    appLanguage: state.account.appLanguage,
  }));
  const {logout} = useAccount();

  const getAppLanguageLabel = language => {
    switch (language) {
      case 'de':
        return 'Deutsch';
      case 'en':
        return 'English';
    }
  };

  return (
    <Container showFloatingBack={navigation}>
      <ScrollView>
        <View style={styles.avatarContainer}>
          <Avatar name={`${user?.first_name} ${user?.last_name}`} />
        </View>
        <View style={styles.buttonsContainer}>
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                navigation.navigate('PersonalDetails');
              }}
              icon={<Icon.UserRed />}
              label={t('user.navigation.personalDetails')}
            />
          </View>
          {/* 
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                navigation.navigate('UserPaymentMethods');
              }}
              icon={<Icon.Euro />}
              label={t('user.navigation.paymentMethod')}
            />
          </View>
          */}
          <View style={styles.button}>
            <UserNavigationButton
              onPress={() => {
                navigation.navigate('ChangePassword');
              }}
              icon={<Icon.Lock style={{width: scale(26), height: 40}} />}
              label={t('change password')}
            />
          </View>
          <View style={{marginBottom: scale(50)}}>
            <UserNavigationButton
              onPress={() => {
                navigation.navigate('UserAppLanguage');
              }}
              icon={<Icon.Globe style={{width: scale(22), height: 24}} />}
              label={t('user.navigation.language')}
              endLabel={getAppLanguageLabel(appLanguage)}
            />
          </View>
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginTop: scale(14),
    alignItems: 'center',
  },
  buttonsContainer: {
    marginTop: scale(55),
  },
  button: {
    marginBottom: scale(15),
  },
});
