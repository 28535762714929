import {getVersion} from 'react-native-device-info';
import {Platform} from 'react-native';
import packageJson from '../../package.json';

const versionNumForMobile = getVersion();
const versionNumForWeb = packageJson.version;

const BASE_CONFIG = ENV_CONFIG => ({
  BASE_APP_URL: ENV_CONFIG.BASE_APP_URL,
  BASE_URL: ENV_CONFIG.BASE_URL,
  PWA_ORIGIN: 'dev-ticketing-1.helloguest.app',
  DEVICE_ID: '0201a04b-66e2-44e1-a841-dccd7be8c883',
  TENANT_ID: '4637e3bf-f3fb-40d5-9145-82e269a0267b',
  ENDPOINT_LOGIN: `${ENV_CONFIG.BASE_URL}/users/session`,
  ENDPOINT_PROFILE: `${ENV_CONFIG.BASE_URL}/users/profile`,
  ENDPOINT_REGISTER: `${ENV_CONFIG.BASE_URL}/users`,
  ENDPOINT_DOCUMENTS: `${ENV_CONFIG.BASE_URL}/users/documents`,
  ENDPOINT_CHANGE_PASSWORD: `${ENV_CONFIG.BASE_URL}/users/password`,
  ENDPOINT_BILLING_ADDRESS: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/users/order_addresses`,
  ENDPOINT_GENERATE_QR_CODE: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/generate_qr_code`,
  ENDPOINT_REQUEST_RESET: `${ENV_CONFIG.BASE_URL}/password_reset`,
  ENDPOINT_FETCH_EVENTS: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/events/summary-index`,
  ENDPOINT_FETCH_FOOD_BEVERAGES: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/products/category/food_beverages`,
  ENDPOINT_FETCH_FOOD_BEVERAGES_EXTENDED: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/categories/food_beverages`,
  ENDPOINT_FETCH_EVENTS_PRODUCT_OPTION: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/events/:eventId/product-options`,
 ENDPOINT_CHECKOUT_ORDER: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/orders`,
  ENDPOINT_CHECKOUT_PAYMENT: orderId =>
    `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/orders/${orderId}/initialize_payment`,
  ENDPOINT_CHECKOUT_PAYMENT_RESULTS: paymentId =>
    `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/payment_authorizations/${paymentId}/check_payment_status`,
  ENDPOINT_ORDERS: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/users/order_items`,
  ENDPOINT_FOOD_ORDERS: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/categories/food_beverages/orders`,
  ENDPOINT_CLAIM_TICKET: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/claim_ticket`,
  ENDPOINT_GENERATE_CLAIM_URL: orderId =>
    `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/generate_claim_url/${orderId}`,
  ENDPOINT_CHECK_OPEN_COUNTER: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/check_open_counter`,
  UTM_ANDROID: mobileOs =>
    `utm_source=ticketing-kulturboden&utm_medium=ticketing-kulturboden-android&utm_term=v${versionNumForMobile}+${mobileOs}`,
  UTM_IOS: `utm_source=ticketing-kulturboden&utm_medium=ticketing-kulturboden-ios&utm_term=v${versionNumForMobile}+ios-${Platform.Version}`,
  UTM_WEB: (webOs, webBrowserVer, webBrowserName) =>
    `utm_source=ticketing-kulturboden&utm_medium=ticketing-kulturboden-pwa-${webOs}&utm_term=v${versionNumForWeb}+${webOs}-${webBrowserVer}+${webBrowserName}`,
  SUPPORT_EMAIL: 'xyz@mail.de',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCY8HzMHeNBt1TmbcJkCGxqatMZvwr-PLQ',
  APP_LANGUAGE: 'de',
  DISABLE_FAVOURITES: true,
  DISABLE_TICKETS: true,
  DISABLE_BAR: true,
  DISABLE_PROFILE_COUNTRY: true,
  DISABLE_QR_SCANNER: true,
  ENABLE_EXTERNAL_PAYMENT: true,
  ENABLE_PROFILE_LANGUAGE_SELECTOR: false,
  EXTERNAL_PRIVACY: 'https://www.kulturboden-hallstadt.de/datenschutz-app',
  EXTERNAL_GTC: 'https://www.kulturboden-hallstadt.de/agb',
  ENDPOINT_FETCH_FOOD_ADDICTIVE_LIST: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/ticket-platform/food_additives`,
  ENDPOINT_FETCH_PAYMENT_RECIPIENT: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}/payment_provider`,
  ENDPOINT_FETCH_TENANT_DETAILS: `${ENV_CONFIG.BASE_URL}/tenants/${ENV_CONFIG.TENANT_ID}`,
});

export default ENV_CONFIG => ({...BASE_CONFIG(ENV_CONFIG), ...ENV_CONFIG});
