import React from 'react';
import {View, StyleSheet, ScrollView, TouchableOpacity} from 'react-native';
import Header from './Header';
import {scale} from '../../../../utils/Scale';
import {ordinal, parseDisplayDate} from '../../../../utils/Utils';
import Card from 'components/Card';
import {DateTime} from 'luxon';
import Row from 'components/Row';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import BasicLoadingIndicator from 'components/BasicLoadingIndicator';

export default ({navigation, header, events, loading}) => {
  const {t, i18n} = useTranslation();
  const renderItem = ({
    title,
    date_at: date,
    assets,
    start_at,
    admission_at: admission,
    id: eventId,
  }) => {
    const image = assets[0]?.file?.url ?? ''; //TODO: DEFAULT 404 NO FILE HERE
    return (
      <TouchableOpacity
        key={eventId}
        style={styles.card}
        onPress={() => {
          // TODO: Replace with proper eventId;
          navigation.navigate('Event', {
            screen: 'EventDetails',
            params: {eventId},
          });
        }}>
        <Card
          largeImage={true}
          title={title}
          subtitle={
            <Row>
              <HGText variant="eventDetailsMeta" color="primary">
                {parseDisplayDate(date, i18n.language)}
              </HGText>
              <HGText
                variant="eventDetailsMeta"
                color="primary"
                style={styles.dateTimeDivider}>
                |
              </HGText>
              <HGText
                variant="eventDetailsMeta"
                color="primary"
                style={styles.label}>
                {start_at}
                {i18n.language === 'de' ? ' Uhr' : ''}
              </HGText>
            </Row>
          }
          image={image}
          containerStyle={styles.card}
          footerText={`${t('admission')}: ${admission}${
            i18n.language === 'de' ? ' Uhr' : ''
          }`}
        />
      </TouchableOpacity>
    );
  };
  return (
    <View style={styles.container}>
      <Header text={header.toUpperCase()} />
      <ScrollView>
        {loading && <BasicLoadingIndicator />}
        {!loading && events.length === 0 && (
          <HGText>{t('No results found')}</HGText>
        )}
        {events.map(event => renderItem(event))}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: scale(36),
  },
  card: {
    height: scale(273),
    marginBottom: scale(20),
  },
  dateTimeDivider: {
    marginHorizontal: scale(8),
  },
});
