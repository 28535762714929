import React, {useCallback, useState, useEffect} from 'react';
import {View, StyleSheet, ScrollView, Platform, Linking} from 'react-native';
import {useSelector} from 'react-redux';
import ProgressPills from 'components/ProgressPills';
import Container from 'components/Container';
import {scale} from 'utils/Scale';
import TwoActionButtons from '../../components/TwoActionButtons';
import {useTranslation} from 'react-i18next';
import Subtotal from 'components/Subtotal';
import {MOCK_COUNTER_DATA} from 'utils/mock_data';
import ItemListCard from './components/ItemListCard';
import PaymentMethod from './components/PaymentMethod';
import TimeslotCard from './components/TimeslotCard';
import useCounterCheckout from 'hooks/useCounterCheckout';
import getCounterProducts from '../../hooks/getCounterProducts';
import PaymentRecipient from '../TicketCheckoutOverview/components/PaymentRecipient';
import useTicketCheckout from 'hooks/useTicketCheckout';
import {calculateTaxAmount} from '../../utils/Utils';

export default ({navigation}) => {
  const {t, i18n} = useTranslation();
  const {paymentMethod, timeslot} = useSelector(
    state => state.counterCheckout,
  );
  const {cartItem:items} = useCounterCheckout();

  const {products, loading} = getCounterProducts();
  const [_loading, _setLoading] = useState(false);
  const [initialize, setInitialize] = useState(false);
  const [recipientData, setRecipientData] = useState({});
  const productList = {};
  products.forEach(({id, ...product}) => (productList[id] = product));
  // MOCK_COUNTER_DATA.forEach(({items}, categoryId) =>
  //   items.forEach(({options}, itemId) =>
  //     options.forEach(
  //       ({price}, optionId) =>
  //         (priceList[`${categoryId}-${itemId}-${optionId}`] = price),
  //     ),
  //   ),
  // );
  const totalPrice = Object.keys(items.items)
    .map(key => items.items[key] * productList[key].price)
    .reduce((a, b) => a + b, 0);

  const {fetchPaymentRecipient} = useTicketCheckout();
  useEffect(async () => {
    if (!initialize) {
      setInitialize(true);
      fetchPaymentRecipient(res => {
        setRecipientData(res);
      });
    }
  }, [initialize, fetchPaymentRecipient, setRecipientData]);

  const calculateRates = () => {
    let rates = [];
    Object.keys(items.items).forEach(productId => {
      const qty = items.items[productId];
      const {price, vat_rate} = productList[productId];

      if (vat_rate > 0) {
        const subPrice = qty * price;
        rates = calculateTaxAmount(rates, vat_rate, subPrice);
      }
    });

    return rates;
  };

  return (
    <ScrollView contentContainerStyle={{minHeight: '100%'}}>
      <Container>
        <View style={styles.progressContainer}>
          <ProgressPills total={3} current={3} />
        </View>
        <View style={styles.spacing}>
          <ItemListCard
            items={Object.keys(items.items).map((key, i) => {
              const itemId = key;
              const item = productList[itemId];
              const {title, subtitle} = item;
              return {
                name: title[i18n.language] ?? title.de ?? title.en,
                option: subtitle[i18n.language] ?? subtitle.de ?? subtitle.en,
                quantity: items.items[key],
                price: productList[key].price,
              };
            })}
          />
        </View>
        <View style={styles.spacing}>
          <TimeslotCard timeslot={timeslot} navigation={navigation} />
        </View>
        <PaymentRecipient
          {...recipientData}
          containerStyle={{marginBottom: scale(40)}}
        />
        {/* <View style={styles.spacing}>
          <PaymentMethod
            paymentMethod={paymentMethod}
            navigation={navigation}
          />
        </View> */}
        <Subtotal
          total={totalPrice}
          label="total"
          vatRates={calculateRates()}
        />
        <View style={styles.actionButtons}>
          <TwoActionButtons
            buttons={[
              {label: t('Back'), onPress: navigation.goBack},
              {
                disabled: !totalPrice || totalPrice <= 0 || loading,
                loading: loading || _loading,
                label: t('pay now'),
                onPress: () => {
                  _setLoading(true);
                  navigation.reset({
                    index: 0,
                    routes: [
                      {
                        name: 'OrderPendingPayment',
                        params: {
                          screen: 'OrderPendingPayment',
                          params: {type: 'counter', products: items.items, timeslot},
                        },
                      },
                    ],
                  });
                },
              },
            ]}
          />
        </View>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  spacing: {
    marginBottom: scale(17),
  },
  actionButtons: {
    marginTop: scale(50),
    marginBottom: scale(70),
  },
});
