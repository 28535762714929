import React, {useEffect} from 'react';
import {ImageBackground, Platform} from 'react-native';
import {heightPercentageToDP, widthPercentageToDP} from 'utils/Scale';

export default ({navigation}) => {
  useEffect(() => {
    setTimeout(() => {
      if (Platform.OS === 'web') {
        const params = new URLSearchParams(window.location.search);
        if (window.location.pathname.indexOf('/event/') === 0) {
          const eventId = window.location.pathname.split('/event/')[1];
          navigation &&
            navigation.reset({
              index: 1,
              routes: [
                {name: 'App'},
                {
                  name: 'Event',
                  params: {
                    screen: 'EventDetails',
                    params: {eventId: parseFloat(eventId)},
                  },
                },
              ],
            });
          return;
        } else if (window.location.pathname.indexOf('/password-reset/') === 0) {
          const resetToken =
            window.location.pathname.split('/password-reset/')[1];
          navigation &&
            navigation.reset({
              index: 0,
              routes: [{name: 'ResetPassword', params: {resetToken}}],
            });
          return;
        } else if (window.location.pathname.indexOf('/orders/') === 0) {
          //TODO: CHECK IF SUCCESS OR FAIL, ALSO SAVE LAST ORDER TO APP
          const success = window.location.pathname.indexOf('success') >= 0;
          // need check order ID if it is ticket or counter
          const orderId = params.get('order_id');
          navigation &&
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: 'OrderPaymentWebHook',
                  params: {
                    showPlaceholder: false,
                    success,
                    orderId,
                  },
                },
              ],
            });
          return;
        }
      }
      navigation && navigation.replace('App');
    }, 1000);
  }, [navigation]);
  return (
    <ImageBackground
      style={{
        width: widthPercentageToDP(100),
        height: heightPercentageToDP(100),
      }}
      source={require('../../../assets/images/splash.png')}
    />
  );
};
