import {CardContainer} from 'components/Card';
import {Print, Share, TicketsFill} from 'components/HGIcon';
import HGText from 'components/HGText';
import Row from 'components/Row';
import TwoActionButtons from 'components/TwoActionButtons';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Image, StyleSheet, View, TouchableOpacity} from 'react-native';
import Colors from 'utils/Colors';
import {scale} from 'utils/Scale';
import {parseDisplayDate} from 'utils/Utils';
import {DateTime} from 'luxon';
import {parseCurrency} from '../../utils/Utils';
import TicketQRCode from '../../components/TicketQRCode';
import useModal from '../../hooks/useModal';
import ModalTicketQRCode from './components/ModalTicketQRCode';

export default ({tickets, event, navigation}) => {
  const {t, i18n} = useTranslation();
  const {showModal} = useModal();

  const renderTickets = () => {
    return tickets.map((item, index) => {
      const {
        visitor: {lastName, firstName},
        totalPrice,
        uuid,
        name,
      } = item || {};

      return (
        <CardContainer
          key={index}
          padded={true}
          containerStyle={styles.cardContainer}>
          <View>
            <View style={[styles.cutout, styles.topCutout]} />
          </View>
          <Row>
            <View style={styles.headerLeftContainer}>
              <HGText variant="header" upperCase={true} fontSize={scale(18)}>
                {name[i18n.language]}
              </HGText>
            </View>
            <View style={styles.headerRightContainer}>
              <View style={styles.qrContainer}>
                <View style={styles.qrClickMeContainer}>
                  <HGText style={styles.clickMeText}>
                    {t('TicketTickets.clickMe')}
                  </HGText>
                </View>
                <TouchableOpacity
                  onPress={() => {
                    showModal({
                      titleTextStyle: styles.qrModalTitle,
                      title: firstName + ' ' + lastName,
                      component: () => <ModalTicketQRCode id={uuid} />,
                    });
                  }}
                  style={styles.buttonQRCode}>
                  <Image
                    source={require('../../../assets/images/qr-clickme_50.png')}
                    style={styles.imageQRCode}
                  />
                </TouchableOpacity>
              </View>
            </View>
          </Row>
          <View style={styles.divider} />
          <Row style={styles.infoRow}>
            <View style={styles.firstCol}>
              <HGText
                variant="header"
                fontSize={scale(13)}
                upperCase={true}
                style={styles.colHeader}>
                {t('date')}
              </HGText>
              <HGText variant="eventDetailsMeta">
                {parseDisplayDate(event?.date_at, i18n.language)}
              </HGText>
            </View>
            <View style={styles.secondCol}>
              <HGText
                variant="header"
                fontSize={scale(13)}
                upperCase={true}
                style={styles.colHeader}>
                {t('beginning')}
              </HGText>
              <HGText variant="eventDetailsMeta">
                {event?.start_at}
                {i18n.language === 'de' ? ' Uhr' : ''}
              </HGText>
            </View>
          </Row>
          <Row style={styles.infoRow}>
            <View style={styles.firstCol}>
              <HGText
                variant="header"
                fontSize={scale(13)}
                upperCase={true}
                style={styles.colHeader}>
                {t('admission')}
              </HGText>
              <HGText variant="eventDetailsMeta">
                {event?.admission_at}
                {i18n.language === 'de' ? ' Uhr' : ''}
              </HGText>
            </View>
            <View style={styles.secondCol}>
              <HGText
                variant="header"
                fontSize={scale(13)}
                upperCase={true}
                style={styles.colHeader}>
                {t('price')}
              </HGText>
              <HGText variant="eventDetailsMeta">
                {parseCurrency({
                  amount: parseFloat(totalPrice),
                })}
              </HGText>
            </View>
          </Row>
          <View>
            <HGText
              variant="header"
              fontSize={scale(13)}
              upperCase={true}
              style={styles.colHeader}>
              {t('visitors')}
            </HGText>
            <HGText key={`visitor-${firstName}`} variant="eventDetailsMeta">
              {firstName} {lastName}
            </HGText>
          </View>
        </CardContainer>
      );
    });
  };

  return (
    <View style={styles.root}>
      {renderTickets()}

      <View style={styles.buttonsContainer}>
        <TwoActionButtons
          buttons={[
            {
              icon: <Share style={styles.shareIcon} />,
              label: t('Share'),
              variant: 'secondary',
              onPress: () => {
                navigation.navigate('ShareTicket', {tickets, event});
              },
            },
          ]}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {backgroundColor: Colors.BACKGROUND_COLOR},
  cardContainer: {
    borderWidth: 0,
    shadowOpacity: 0.5,
    marginBottom: scale(20),
  },
  divider: {
    marginVertical: scale(20),
    borderColor: hexToRgba('#B1B1B1', 0.5),
    borderTopWidth: 4,
    borderStyle: 'dashed',
  },
  ticketsNumberContainer: {
    alignItems: 'center',
    marginBottom: scale(30),
  },
  infoRow: {
    marginBottom: scale(16),
  },
  firstCol: {
    flex: 3,
  },
  secondCol: {
    flex: 2,
  },
  colHeader: {
    marginBottom: scale(8),
  },
  cutout: {
    height: scale(30),
    width: scale(30),
    backgroundColor: '#F1EEED',
    position: 'absolute',
    borderRadius: scale(20),
    zIndex: 999,
    top: scale(-11),
  },
  topCutout: {
    top: scale(-38),
    left: scale(115),
  },
  qrContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrImage: {
    width: scale(150),
    height: scale(150),
    marginBottom: scale(8),
  },
  qrText: {
    alignSelf: 'center',
  },
  shareIcon: {
    width: scale(15),
    height: scale(23),
  },
  printIcon: {
    width: scale(20),
    height: scale(20),
  },
  buttonsContainer: {
    marginTop: scale(20),
    marginBottom: scale(70),
  },
  headerLeftContainer: {
    flex: 0.7,
  },
  headerRightContainer: {
    flex: 0.3,
    alignItems: 'flex-end',
  },
  qrContainer: {
    alignItems: 'center',
  },
  qrClickMeContainer: {
    backgroundColor: '#8d1e2d',
    alignItems: 'center',
    justifyContent: 'center',
    width: scale(60),
  },
  clickMeText: {
    color: 'white',
    fontSize: scale(10),
  },
  buttonQRCode: {
    borderWidth: 4,
    borderColor: '#8d1e2d',
    borderRadius: 5,
  },
  imageQRCode: {
    width: scale(60),
    height: scale(60),
  },
  qrModalTitle: {
    textAlign: 'center',
    marginBottom: scale(30),
  },
});
