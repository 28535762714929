import HGCheckBox from 'components/HGCheckBox';
import HGText from 'components/HGText';
import Row from 'components/Row';
import React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
import {scale} from 'utils/Scale';

export default ({label, value, onChange, onPressLink}) => {
  return (
    <Row style={styles.container}>
      <HGCheckBox onChange={onChange} value={value} />
      <TouchableOpacity onPress={onPressLink || (() => {})}>
        <HGText variant="link" style={styles.label}>
          {label}
        </HGText>
      </TouchableOpacity>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  label: {
    marginLeft: scale(19),
  },
});
