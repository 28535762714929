import React from 'react';
import {Text, StyleSheet} from 'react-native';
import {scale} from 'utils/Scale';
import Colors from '../../utils/Colors';

const MAIN_HEADER_FONT = 'Nobel';
const DEFAULT_FONT = 'Avenir Next';

const styles = StyleSheet.create({
  navigatorHeaderTitle: {
    fontFamily: MAIN_HEADER_FONT,
    color: Colors.SECONDARY_COLOR,
    fontSize: scale(31),
    textTransform: 'uppercase',
  },
  navigatorHeaderSubTitle: {
    color: 'darkgrey',
    fontSize: scale(13),
  },
  actionText: {
    color: Colors.PRIMARY_COLOR,
  },
  header: {
    color: Colors.SECONDARY_COLOR,
    fontSize: scale(19),
    fontFamily: MAIN_HEADER_FONT,
  },
  smallHeader: {
    color: Colors.SECONDARY_COLOR,
    fontSize: scale(14),
    fontFamily: MAIN_HEADER_FONT,
  },
  meta: {
    color: Colors.PRIMARY_COLOR,
    fontSize: scale(13),
  },
  eventDetailsMeta: {
    fontSize: scale(15),
  },
  inputLabel: {
    fontSize: scale(13),
    fontFamily: MAIN_HEADER_FONT,
    color: Colors.SECONDARY_COLOR,
    textTransform: 'uppercase',
  },
  link: {
    textDecorationLine: 'underline',
    color: Colors.PRIMARY_COLOR,
  },
  error: {
    color: Colors.PRIMARY_COLOR,
  },
  tabBar: {
    fontFamily: MAIN_HEADER_FONT,
    color: Colors.SECONDARY_COLOR,
    fontSize: scale(14),
    textTransform: 'uppercase',
  },
  coronaFloatingMenuItem: {
    fontFamily: MAIN_HEADER_FONT,
    color: '#4A281B',
    fontSize: scale(12),
    textTransform: 'uppercase',
  },
  default: {
    fontSize: scale(13),
    fontFamily: DEFAULT_FONT,
  },
});

const getColor = color => {
  switch (color) {
    case 'primary':
      return Colors.PRIMARY_COLOR;
    case 'secondary':
      return Colors.SECONDARY_COLOR;
    case 'tertiary':
      return Colors.TERTIARY_COLOR;
    default:
      return color;
  }
};

export default ({
  children,
  variant,
  style,
  fontSize,
  color,
  capitalize,
  upperCase,
  ...props
}) => {
  return (
    <Text
      style={[
        styles.default,
        styles[variant],
        fontSize ? {fontSize} : {},
        capitalize ? {textTransform: 'capitalize'} : {},
        upperCase ? {textTransform: 'uppercase'} : {},
        color ? {color: getColor(color)} : {},
        style,
      ]}>
      {children}
    </Text>
  );
};
