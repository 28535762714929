import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

export default ({children}) => {
  //const profile = useSelector(state => state.account.user);
  const language = useSelector(state => state.account.appLanguage);
  const {i18n} = useTranslation();
  useEffect(() => {
    // if (profile && profile.lang && profile.lang !== i18n.language) {
    //   i18n.changeLanguage(profile.lang);
    // }
    if (language && language !== i18n.language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);
  return children;
};
