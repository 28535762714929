import React from 'react';
import {StyleSheet, View} from 'react-native';

import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {scale} from '../../../utils/Scale';
import {parseCurrency, parseDisplayDate} from '../../../utils/Utils';

import {CardContainer} from '../../../components/Card';
import Row from '../../../components/Row';
import HGText from '../../../components/HGText';

const OptionRow = ({quantity, unit, price, title, language}) => {
  const {t} = useTranslation();

  return (
    <View style={styles.optionRow}>
      <Row>
        <View style={styles.fullWidth}>
          <HGText variant="eventDetailsMeta">
            {quantity}x {unit}{' '}
            {title ? (language === 'de' ? title.de : title.en) : ''}
          </HGText>
        </View>
        <HGText variant="eventDetailsMeta">
          {parseCurrency({amount: parseFloat(price) * parseFloat(quantity)})}
        </HGText>
      </Row>
      <HGText style={styles.ticketPrice} variant="eventDetailsMeta">
        {t('CartOverviewCard.pricePerTicket').replace(
          '${price}',
          parseCurrency({amount: price}),
        )}
      </HGText>
    </View>
  );
};

const TicketRow = ({productId, quantity, product}) => {
  const {t, i18n} = useTranslation();

  return (
    <View style={styles.ticketRow} key={productId}>
      <Row>
        <View style={styles.fullWidth}>
          <HGText variant="eventDetailsMeta">
            {quantity}x {product.title[i18n.language] ?? t('Seat')}
          </HGText>
        </View>
        <View>
          <HGText variant="eventDetailsMeta">
            {parseCurrency({
              amount: parseFloat(product.price) * parseFloat(quantity),
            })}
          </HGText>
        </View>
      </Row>
      <HGText style={styles.ticketPrice} variant="eventDetailsMeta">
        {t('CartOverviewCard.pricePerTicket').replace(
          '${price}',
          parseCurrency({amount: product.price}),
        )}
      </HGText>
    </View>
  );
};

export default ({title, date, time, tickets, price, options}) => {
  const productsObj = useSelector(state => state.events.products);
  const {i18n} = useTranslation();

  const filteredTickets = filterTickets(tickets);

  function filterTickets(oriTickets) {
    // Remove ticket with 0 quantity
    // TODO: Consider doing this in the reducer
    const clonedTickets = {...oriTickets};
    for (const [key, value] of Object.entries(clonedTickets)) {
      if (value <= 0) {
        delete clonedTickets[key];
      }
    }

    return clonedTickets;
  }

  return (
    <CardContainer padded={true}>
      <HGText variant="header" upperCase={true} fontSize={scale(17)}>
        {title}
      </HGText>
      <Row style={styles.datetime}>
        <HGText variant="eventDetailsMeta" color="primary">
          {parseDisplayDate(date, i18n.language)}
        </HGText>
        <HGText
          variant="eventDetailsMeta"
          color="primary"
          style={styles.dateTimeDivider}>
          |
        </HGText>
        <HGText variant="eventDetailsMeta" color="primary">
          {time}
        </HGText>
      </Row>
      <Row style={styles.divider} />
      {Object.keys(filteredTickets).map(productId => {
        const quantity = filteredTickets[productId];
        const product = productsObj[productId];

        return (
          <TicketRow
            productId={productId}
            quantity={quantity}
            product={product}
          />
        );
      })}
      <Row style={styles.divider} />
      {options
        .filter(({qty}) => parseFloat(qty) >= 1)
        .map(({qty, unit, price, title}, i) => (
          <OptionRow
            key={`option-${i}`}
            quantity={qty}
            unit={unit}
            price={price}
            title={title}
            language={i18n.language}
          />
        ))}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  datetime: {marginTop: scale(19), marginBottom: scale(13)},
  divider: {borderTopWidth: 0.5, color: '#707070', marginVertical: scale(15)},
  ticketPrice: {marginTop: scale(16)},
  fullWidth: {flex: 1, flexDirection: 'row'},
  dateTimeDivider: {
    marginHorizontal: scale(8),
  },
  optionRow: {marginBottom: scale(19)},
  ticketRow: {marginBottom: scale(19)},
});
