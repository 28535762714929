import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from '../../utils/Colors';
import {scale} from '../../utils/Scale';
import HGText from '../HGText';

const HGButton = ({
  label,
  onPress,
  icon = null,
  variant = 'primary',
  size = 'large',
  loading = false,
  disabled = false,
  fullWidth = false,
  upperCase = true,
  labelColor,
  TouchableComponent,
  TouchableComponentProps,
  extraTextStyle,
  disableContainer = false,
}) => {
  const TouchComponent = TouchableComponent || TouchableOpacity;

  return (
    <TouchComponent
      disabled={loading || disabled}
      style={{flexDirection: 'row'}}
      onPress={onPress || (() => {})}
      {...TouchableComponentProps}>
      <View
        style={[
          size === 'large'
            ? styles.largeContainer
            : size === 'tiny'
            ? styles.tinyContainer
            : styles.smallContainer,
          variant === 'primary'
            ? styles.primaryContainer
            : styles.secondaryContainer,
          fullWidth ? {width: '100%', minWidth: 0} : {},
          disabled || loading
            ? variant === 'primary'
              ? styles.disabledPrimaryContainer
              : styles.disabledSecondaryContainer
            : {},
          disableContainer ? styles.disabledContainer : {},
        ]}>
        {loading ? (
          <ActivityIndicator
            color={variant === 'primary' ? 'white' : Colors.PRIMARY_COLOR}
          />
        ) : (
          <Row style={styles.labelContainer}>
            {icon && <View style={styles.labelIcon}>{icon}</View>}
            <HGText
              style={[
                styles.label,
                variant === 'primary'
                  ? styles.primaryLabel
                  : styles.secondaryLabel,
                labelColor
                  ? {
                      color:
                        labelColor === 'primary'
                          ? Colors.PRIMARY_COLOR
                          : labelColor === 'secondary'
                          ? Colors.SECONDARY_COLOR
                          : labelColor,
                    }
                  : {},
                size === 'tiny' ? styles.tinyLabel : {},
                extraTextStyle ? extraTextStyle : {},
              ]}>
              {upperCase ? label.toUpperCase() : label}
            </HGText>
          </Row>
        )}
      </View>
    </TouchComponent>
  );
};

const styles = StyleSheet.create({
  labelContainer: {
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: scale(11),
  },
  label: {
    fontSize: scale(17),
  },
  primaryLabel: {
    color: '#FFF',
  },
  secondaryLabel: {},
  tinyLabel: {fontSize: scale(10)},
  tinyContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: scale(7),
    paddingHorizontal: scale(8),
    minWidth: scale(100),
    borderRadius: scale(23),
  },
  smallContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: scale(13),
    paddingHorizontal: scale(15),
    minWidth: scale(140),
    borderRadius: scale(23),
  },
  largeContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: scale(15),
    paddingHorizontal: scale(18),
    minWidth: scale(158),
    borderRadius: scale(26),
  },
  primaryContainer: {
    backgroundColor: Colors.PRIMARY_COLOR,
  },
  secondaryContainer: {
    borderWidth: 1,
    borderColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
    backgroundColor: '#FFF',
  },
  disabledContainer: {
    borderWidth: 0,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
  },
  disabledPrimaryContainer: {
    backgroundColor: hexToRgba(Colors.PRIMARY_COLOR, 0.5),
  },
  disabledSecondaryContainer: {
    backgroundColor: hexToRgba('#000000', 0.05),
  },
});

export default HGButton;
