import React from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import HGButton from 'components/HGButton';
import {useTranslation} from 'react-i18next';
import HGText from '../../components/HGText';
import Row from 'components/Row';
import AvatarPlaceholder from '../Login/components/AvatarPlaceholder';
import RegisterForm from './components/RegisterForm';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  return (
    <Container>
      <ScrollView contentContainerStyle={{ paddingBottom: scale(20) }}>
        <View style={styles.avatar}>
          <AvatarPlaceholder />
        </View>
        <View style={styles.form}>
          <RegisterForm navigation={navigation} route={route} />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  avatar: {
    marginTop: scale(50),
    alignItems: 'center',
    marginBottom: scale(21),
  },
  form: {
    marginBottom: scale(50),
  },
  registerLink: {marginTop: scale(3)},
});
