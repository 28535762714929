import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import Tickets from '../views/Tickets';
import FrontNavigationHeader from '../components/FrontNavigationHeader';
import {useTranslation} from 'react-i18next';
import TicketDetails from '../views/TicketDetails';
import ShareTicket from '../views/TicketDetails/ShareTicket';

const Stack = createStackNavigator();
export default ({}) => {
  const {t} = useTranslation();
  return (
    <Stack.Navigator initialRouteName="Tickets" headerMode="screen">
      <Stack.Screen
        name="Tickets"
        component={Tickets}
        options={{
          headerShown: true,
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('tickets.navigation.title')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="Ticket"
        component={TicketDetails}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        name="ShareTicket"
        component={ShareTicket}
        options={{
          headerShown: false,
        }}
      />
    </Stack.Navigator>
  );
};
