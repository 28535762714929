import React from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import Avatar from './components/Avatar';
import {useTranslation} from 'react-i18next';
import HGButton from '../../components/HGButton';
import useAccount from '../../hooks/useAccount';
import {useForm} from 'react-hook-form';
import HGTextInput from 'components/HGTextInput';
import Row from 'components/Row';
import useModal from '../../hooks/useModal';

export default ({navigation}) => {
  const {t} = useTranslation();
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors, isDirty, isValid},
  } = useForm({mode: 'onChange'});
  const {changePassword, loading} = useAccount();

  const watchFields = watch(['password']);

  const {showModal} = useModal();

  const onSubmit = async data => {
    const res = await changePassword(data);
    if (res.success) {
      showModal({
        title: t('Success'),
        body: t('Successfully changed password'),
        onClose: () => {
          showModal(null);
          navigation.goBack();
        },
      });
    } else {
      showModal({
        title: t('Error'),
        body: t('errors.changePassword'),
        onClose: () => showModal(null),
      });
    }
  };

  return (
    <Container showFloatingBack={navigation}>
      <ScrollView contentContainerStyle={{minHeight: '100%',paddingBottom: scale(20)}}>
          <View style={styles.avatarContainer}>
            <Avatar />
          </View>
          <View style={styles.formContainer}>
            <HGTextInput
              variant="form"
              isPasswordField={true}
              form={{
                control,
                name: 'currentPassword',
                rules: {required: true},
              }}
              error={errors['currentPassword']}
              label={t('forms.currentPassword')}
            />
            <HGTextInput
              variant="form"
              isPasswordField={true}
              form={{
                control,
                name: 'password',
                rules: {
                  required: true,
                  minLength: {value: 8, message: t('errors.minLengthPassword')},
                },
              }}
              error={errors['password']}
              label={`${t('forms.newPassword')} (${t('Min Length')}: 8)`}
            />
            <HGTextInput
              variant="form"
              isPasswordField={true}
              form={{
                control,
                name: 'confirmPassword',
                rules: {
                  validate: val =>
                    (val && val === watchFields[0]) || t('errors.passwordMatch'),
                },
              }}
              error={errors['confirmPassword']}
              label={t('forms.confirmNewPassword')}
            />
          </View>
          <Row style={styles.buttonContainer}>
            <HGButton
              loading={loading}
              disabled={!isValid || !isDirty || loading}
              label={t('Update')}
              onPress={handleSubmit(onSubmit)}
            />
          </Row>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginTop: scale(14),
    alignItems: 'center',
  },
  formContainer: {
    marginTop: scale(55),
  },
  buttonContainer: {
    justifyContent: 'center',
    marginTop: scale(50),
    marginBottom: scale(70),
  },
});
