import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import config from '../../config';
import {
  loginUser,
  logoutUser,
  reloginUser,
  registerUser,
  updateAvatar as _updateAvatar,
  updateProfile as _updateProfile,
  changePassword as _changePassword,
  switchLanguage,
} from '../../state/account';

export default () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const account = useSelector(state => state.account);

  async function login({username, password}) {
    setLoading(true);
    try {
      const res = await dispatch(loginUser({username, password}));
      if (res.error) {
        return {error: true};
      }
      return {success: true};
    } catch (err) {
      return {error: true};
    } finally {
      setLoading(false);
    }
  }

  async function logout() {
    dispatch(logoutUser());
  }

  async function relogin({accessToken}) {
    setLoading(true);
    try {
      const res = await dispatch(reloginUser({accessToken}));
      if (res.error) {
        return {error: true};
      }
      return {success: true};
    } catch (err) {
      return {error: true};
    } finally {
      setLoading(false);
    }
  }

  async function register({firstName, lastName, email, password}) {
    setLoading(true);
    try {
      const res = await dispatch(
        registerUser({firstName, lastName, email, password}),
      );
      if (res.error) {
        return {error: true};
      }
      return {success: true};
    } catch (err) {
      console.log('IS THIS ERROR?', err);
      return {error: true};
    } finally {
      setLoading(false);
    }
  }

  async function updateAvatar({avatar}, cb) {
    await dispatch(_updateAvatar({avatar}, cb));
  }

  async function updateProfile(_profile, cb) {
    // convert to the same format of the one in API
    setLoading(true);
    const profile = {
      gender: _profile.gender,
      country: _profile.country,
      first_name: _profile.firstName,
      last_name: _profile.lastName,
      email: _profile.email,
      phone_number: _profile.phone,
      lang: _profile.language,
    };
    try {
      await dispatch(_updateProfile(profile));
      cb && cb(null);
    } catch (err) {
      cb && cb(err);
    } finally {
      setLoading(false);
    }
  }
  async function changePassword(
    {currentPassword, password, confirmPassword},
    cb,
  ) {
    // convert to the same format of the one in API
    setLoading(true);
    const passwordReq = {
      current_password: currentPassword,
      password,
      password_confirmation: confirmPassword,
    };
    try {
      const res = await dispatch(_changePassword(passwordReq));
      return res;
    } catch (err) {
      return {success: false};
    } finally {
      setLoading(false);
    }
  }

  async function requestResetPassword({email}) {
    setLoading(true);
    try {
      const res = await axios.request({
        url: config.ENDPOINT_REQUEST_RESET,
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(Platform.OS === 'web'
            ? {
                'X-HG-PWA-Origin': config.PWA_ORIGIN,
              }
            : {}),
        },
        data: {email},
      });
      return {success: true};
    } catch (err) {
      return {success: false};
    } finally {
      setLoading(false);
    }
  }
  async function resetPassword({resetToken, password}) {
    setLoading(true);
    try {
      const res = await axios.request({
        url: config.ENDPOINT_REQUEST_RESET + '/' + resetToken,
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...(Platform.OS === 'web'
            ? {
                'X-HG-PWA-Origin': config.PWA_ORIGIN,
              }
            : {}),
        },
        data: {password, password_confirmation: password},
      });
      return {success: true};
    } catch (err) {
      return {success: false};
    } finally {
      setLoading(false);
    }
  }

  function updateLanguage({language}) {
    dispatch(switchLanguage(language));
  }

  return {
    login,
    logout,
    relogin,
    register,
    updateAvatar,
    account,
    updateProfile,
    changePassword,
    requestResetPassword,
    resetPassword,
    updateLanguage,
    loading,
  };
};
