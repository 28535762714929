import {useEffect, useMemo, useState} from 'react';
import getEvents from './getEvents';

export default ({eventId}) => {
  const {loading, events} = getEvents();
  const event = useMemo(() => {
    if (!eventId || !events) return null;
    const filtered = events.filter(({id}) => id === eventId);
    return filtered[0] ?? null;
  }, [events, eventId]);

  return {event, loading};
};
