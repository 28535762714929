import React, {useEffect} from 'react';
import {TouchableOpacity} from 'react-native';
import useCorona from '../../hooks/useCorona';

export default ({
  children,
  onPress,
  getFileCb,
  documentType,
  onUploadStatus,
  types,
  ...props
}) => {
  const acceptString = types.join(',');
  const {uploadDocument, loading} = useCorona();
  const getFile = async () => {
    const filePicker = document.getElementById('filePicker');
    filePicker.click();
  };
  const onFileChange = async () => {
    const filePicker = document.getElementById('filePicker');

    filePicker.files.length > 0 &&
      getFileCb &&
      uploadDocument({type: documentType, file: filePicker.files[0]}, res => {
        filePicker.value = '';
        getFileCb(res);
      });
  };

  useEffect(() => {
    if (onUploadStatus) {
      onUploadStatus(loading);
    }
  }, [loading, onUploadStatus]);

  return (
    <>
      <input
        type="file"
        id="filePicker"
        style={{display: 'none'}}
        onChange={onFileChange}
        accept={acceptString}
      />
      <TouchableOpacity onPress={getFile} {...props}>
        {children}
      </TouchableOpacity>
    </>
  );
};
