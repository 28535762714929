import React, {useCallback, useState} from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import {CardContainer} from 'components/Card';
import {scale} from 'utils/Scale';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import useModal from 'hooks/useModal';
import ChangePersonModal from './ChangePersonModal';
import {useForm} from 'react-hook-form';

export default ({visitors, rawVisitors}) => {
  const {t} = useTranslation();
  const {showModal} = useModal();

  const form = useForm({mode: 'onChange'});

  const showChangePersonModal = useCallback(() => {
    showModal({
      component: () => (
        <ChangePersonModal
          visitors={rawVisitors}
          onClose={() => showModal()}
          onChange={() => {}}
          form={form}
        />
      ),
      type: 'empty',
    });
  }, [rawVisitors, showModal, form]);

  return (
    <CardContainer densePadded={true}>
      <HGText variant="header" style={styles.header}>
        {t('registered visitors')}
      </HGText>
      <View style={styles.visitorContainer}>
        {visitors.map((name, i) => (
          <HGText key={`visitor-${i}`}>{name}</HGText>
        ))}
      </View>
      {/* <TouchableOpacity onPress={showChangePersonModal}>
        <HGText variant="link" style={styles.link}>
          {t('Change')}
        </HGText>
      </TouchableOpacity> */}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: scale(18),
    marginBottom: scale(25),
    textTransform: 'uppercase',
  },
  title: {
    fontSize: scale(17),
    textTransform: 'uppercase',
    marginBottom: scale(9),
  },
  datetime: {
    fontSize: scale(15),
  },
  visitorContainer: {
    marginBottom: scale(12),
  },
  link: {textDecorationLine: 'none'},
});
