import {Platform} from 'react-native';
import {createSlice} from '@reduxjs/toolkit';
import {actions as CORONA_ACTIONS} from '../corona';
import {
  getProfile,
  loginUser as _loginUser,
  registerUser as _registerUser,
  updateProfile as _updateProfile,
  uploadAvatar,
  changePassword as _changePassword,
} from './helpers';
import persistReducer from 'redux-persist/es/persistReducer';
import persistConfig from './persistConfig';

const initialState = {
  id: '',
  user: {},
  loggedIn: false,
  accessToken: '',
  renewToken: '',
  avatar: null,
  appLanguage: 'de',
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    login: (state, action) => {
      state.id = action.payload.id;
      state.user = action.payload.user;
      state.loggedIn = true;
      state.accessToken = action.payload.accessToken;
      state.renewToken = action.payload.renewToken;
      state.avatar = action.payload.avatar ?? null;
    },
    logout: (state, action) => {
      state.id = '';
      state.user = {};
      state.loggedIn = false;
      state.accessToken = '';
      state.renewToken = '';
      state.avatar = null;
    },
    updateAvatar: (state, action) => {
      state.avatar = action.payload.avatar;
    },
    updateProfile: (state, action) => {
      state.user = {...state.user, ...action.payload};
    },
    updateLanguage: (state, action) => {
      state.appLanguage = action.payload;
    },
  },
});

const actions = accountSlice.actions;

// Thunks
export const loginUser =
  ({username, password}) =>
  async dispatch => {
    try {
      const payload = await _loginUser({username, password});
      if (!payload.error) {
        dispatch(actions.login(payload));

        return {success: true};
      } else {
        return {success: false, error: payload.error};
      }
    } catch (err) {}
  };

export const logoutUser = () => async dispatch => {
  dispatch(actions.logout());
};

export const reloginUser =
  ({accessToken}) =>
  async (dispatch, getState) => {
    const _state = getState();
    const results = await getProfile(accessToken);
    if (results.result === 'error') {
      // token expired
      dispatch(actions.logout());
      return;
    }
    const state = {
      ..._state.account,
      id: results.id,
      user: {...results.profile, email: results.email},
      loggedIn: true,
      avatar: results.avatar,
    };
    dispatch(actions.login(state));
  };

export const registerUser =
  ({firstName, lastName, email, password}) =>
  async dispatch => {
    try {
      const payload = await _registerUser({
        firstName,
        lastName,
        email,
        password,
      });
      if (!payload.error) {
        dispatch(actions.login(payload));
        return {success: true};
      } else {
        return {success: false, error: payload.error};
      }
    } catch (err) {}
  };

export const updateAvatar =
  ({avatar}, cb) =>
  async (dispatch, getState) => {
    const state = getState();
    const link = await uploadAvatar(avatar, state.account.accessToken);
    dispatch(actions.updateAvatar({avatar: link}));
    cb && cb();
  };

export const updateProfile = profile => async (dispatch, getState) => {
  const state = getState();
  const res = await _updateProfile(profile, state.account.accessToken);
  dispatch(actions.updateProfile(profile));
  return res;
};

export const changePassword = passwords => async (dispatch, getState) => {
  const state = getState();
  const res = await _changePassword(passwords, state.account.accessToken);
  return res;
};

export const switchLanguage = language => async (dispatch, getState) => {
  dispatch(actions.updateLanguage(language));
};

export default persistReducer(persistConfig, accountSlice.reducer);
