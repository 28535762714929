import FeaturedImageHeader from 'components/FeaturedImageHeader';
import React, {useMemo, useState, useEffect} from 'react';
import {View, StyleSheet, ScrollView, Dimensions} from 'react-native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import Container from 'components/Container';
import {scale} from 'utils/Scale';
import Colors from 'utils/Colors';
import CustomTabBar from './components/CustomTabBar';
import TicketDetailsInformation from './TicketDetailsInformation';
import TicketTickets from './TicketTickets';
import TicketTicketsTable from './TicketTicketsTable';
import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import TabMenu from 'components/TabMenu';
import config from '../../config';

const Tab = createMaterialTopTabNavigator();

export default ({navigation, route}) => {
  const {t, i18n} = useTranslation();
  const tabs = [
    {label: t('information'), screen: 'Information'},
    {label: t('Tickets'), screen: 'Tickets'}
  ]

  const {
    ticket: {items, event},
    initialTab,
  } = route.params;
  const tickets = useMemo(() => {
    return items.map(item => {
      const visitor = item.visitor;
      return {
        id: item.id,
        uuid: item.uuid,
        price: item.price,
        totalPrice: item.price_total,
        name: item.name,
        options:item.options || [],
        visitor: {
          firstName: visitor.first_name ?? '-',
          lastName: visitor.last_name ?? '-',
          // salutation: 'herr',
          phone: visitor.phone_number ?? '-',
        },
      };
    });
  }, [items]);

  const [activeTab, setActiveTab] = useState(
    initialTab ? initialTab : 'Information',
  );

  if (tickets && tickets.length !== 0 && tickets[0].options && tickets[0].options.length !== 0){
    tabs.push( {label: t('Table'), screen: 'Table'});
  }

  return (
    <View style={styles.root}>
      <ScrollView>
        <FeaturedImageHeader
          image={event?.assets[0]?.public_path ?? ''}
          shareLink={`${config.BASE_APP_URL}/event/${event?.id}`}
        />
        <Container>
          <View style={styles.headerContainer}>
            <HGText variant="header" upperCase={true}>
              {event?.title[i18n.language] ??
                event?.title.en ??
                event?.title.de}
            </HGText>
          </View>
          <TabMenu
            style={styles.menu}
            activeTab={activeTab}
            navigation={navigation}
            menuItems={tabs}
            onPress={setActiveTab}
          />
          {activeTab === 'Information'  && (
            <TicketDetailsInformation {...{tickets, event}} />
          )}
          {activeTab === 'Tickets'  && (
            <TicketTickets {...{tickets, event, navigation}} />
          )}
          {activeTab === 'Table' && (
            <TicketTicketsTable {...{tickets, event, navigation}} />
          )}
        </Container>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
  },
  menu: {
    marginBottom: scale(22),
  },
  resetFlex: {
    flex: 0,
  },
  headerContainer: {
    marginVertical: scale(29),
  },
});
