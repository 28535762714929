import React from 'react';
import {Platform} from 'react-native';
import {NavigationContainer} from '@react-navigation/native';
import {SafeAreaProvider, SafeAreaView} from 'react-native-safe-area-context';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import MainNavigator from './app/navigation/MainNavigator';
import store, {persistor} from './app/state';
import {ModalProvider} from './app/components/Modal';
import appName from './app.json';
import Splash from './app/views/Splash';
import LanguageSwitcher from './app/components/LanguageSwitcher';
import {navigationRef} from './app/navigation/RootNavigation';

const App = () => {
  return (
    <SafeAreaProvider>
      <Provider store={store}>
        <PersistGate loading={<Splash />} persistor={persistor}>
          <LanguageSwitcher>
            <ModalProvider>
              <SafeAreaView style={{flex: 1}}>
                <NavigationContainer
                  ref={navigationRef}
                  documentTitle={{
                    formatter: (options, route) =>
                      Platform.OS === 'web'
                        ? appName.displayName
                        : options?.title ?? route?.name,
                  }}>
                  <MainNavigator />
                </NavigationContainer>
              </SafeAreaView>
            </ModalProvider>
          </LanguageSwitcher>
        </PersistGate>
      </Provider>
    </SafeAreaProvider>
  );
};

export default App;
