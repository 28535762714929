import React from 'react';
import { Image, StyleSheet } from 'react-native';
import HGTouchableOpacity from '../../components/HGTouchableOpacity';

export const BarCupFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/bar-cup-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const BarCup = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/bar-cup.svg')}
    />
  </HGTouchableOpacity>
);
export const Bar = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/bar.svg')}
    />
  </HGTouchableOpacity>
);
export const Bell = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/bell.svg')}
    />
  </HGTouchableOpacity>
);
export const Calendar = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/calendar.svg')}
    />
  </HGTouchableOpacity>
);
export const CameraFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/camera-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const Camera = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/camera.svg')}
    />
  </HGTouchableOpacity>
);
export const CheckboxUnchecked = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/checkbox-unchecked.svg')}
    />
  </HGTouchableOpacity>
);
export const CheckboxChecked = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/checkbox-checked.svg')}
    />
  </HGTouchableOpacity>
);
export const Clipboard = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/clipboard.svg')}
    />
  </HGTouchableOpacity>
);
export const CopyFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/copy-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const CovidCertificate = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/covid-certificate.svg')}
    />
  </HGTouchableOpacity>
);
export const Delete = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/delete.svg')}
    />
  </HGTouchableOpacity>
);
export const Document = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/document.svg')}
    />
  </HGTouchableOpacity>
);
export const Download = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/download.svg')}
    />
  </HGTouchableOpacity>
);
export const Edit = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/edit.svg')}
    />
  </HGTouchableOpacity>
);
export const Euro = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/euro.svg')}
    />
  </HGTouchableOpacity>
);
export const Folder = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/folder.svg')}
    />
  </HGTouchableOpacity>
);
export const HeartFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/heart-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const Heart = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/heart.svg')}
    />
  </HGTouchableOpacity>
);
export const HomeFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/home-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const Home = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/home.svg')}
    />
  </HGTouchableOpacity>
);
export const HospitalCertificate = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/corona-test.svg')}
    />
  </HGTouchableOpacity>
);
export const IdentityCard = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/identity-card.svg')}
    />
  </HGTouchableOpacity>
);
export const LeftChevron = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/left-cheron.svg')}
    />
  </HGTouchableOpacity>
);
export const RightChevron = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[
        styles.icon,
        {
          transform: [{ rotateY: '180deg' }],
        },
        style,
      ]}
      {...props}
      source={require('./svgs/left-cheron.svg')}
    />
  </HGTouchableOpacity>
);
export const DownChevron = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[
        styles.icon,
        {
          transform: [{ rotate: '-90deg' }],
        },
        style,
      ]}
      {...props}
      source={require('./svgs/left-cheron.svg')}
    />
  </HGTouchableOpacity>
);
export const Lightning = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/lightning.svg')}
    />
  </HGTouchableOpacity>
);
export const Lock = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/lock.svg')}
    />
  </HGTouchableOpacity>
);
export const MinusCircle = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/minus-circle.svg')}
    />
  </HGTouchableOpacity>
);
export const MinusCircleRed = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/minus-circle-red.svg')}
    />
  </HGTouchableOpacity>
);
export const More = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/more.svg')}
    />
  </HGTouchableOpacity>
);
export const PlusCircle = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/plus-circle.svg')}
    />
  </HGTouchableOpacity>
);
export const PlusCircleRed = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/plus-circle-red.svg')}
    />
  </HGTouchableOpacity>
);
export const Print = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/print.svg')}
    />
  </HGTouchableOpacity>
);
export const Qr = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/qr.svg')}
    />
  </HGTouchableOpacity>
);
export const QuestionCircle = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/question-circle.svg')}
    />
  </HGTouchableOpacity>
);
export const Settings = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/settings.svg')}
    />
  </HGTouchableOpacity>
);
export const Share = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/share.svg')}
    />
  </HGTouchableOpacity>
);
export const Shield = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/shield.svg')}
    />
  </HGTouchableOpacity>
);
export const ShoppingCart = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/shopping-cart.svg')}
    />
  </HGTouchableOpacity>
);
export const ThumbsUp = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/thumbs-up.svg')}
    />
  </HGTouchableOpacity>
);
export const TicketFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/ticket-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const TicketFill1 = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/ticket-fill1.svg')}
    />
  </HGTouchableOpacity>
);
export const Ticket = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/ticket.svg')}
    />
  </HGTouchableOpacity>
);
export const TicketsFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/tickets-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const Time = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/time.svg')}
    />
  </HGTouchableOpacity>
);
export const Upload = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/upload.svg')}
    />
  </HGTouchableOpacity>
);
export const UserDetails = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/user-details.svg')}
    />
  </HGTouchableOpacity>
);
export const UserFill = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, { width: 19.22, height: 22 }, style]}
      {...props}
      source={require('./svgs/user-fill.svg')}
    />
  </HGTouchableOpacity>
);
export const User = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/user.svg')}
    />
  </HGTouchableOpacity>
);
export const UserRed = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/user-red.svg')}
    />
  </HGTouchableOpacity>
);
export const Search = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/search.svg')}
    />
  </HGTouchableOpacity>
);

export const Cross = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/cross.svg')}
    />
  </HGTouchableOpacity>
);
export const BlackCross = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/black-cross.svg')}
    />
  </HGTouchableOpacity>
);
export const ExclamationCircle = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/red-exclamation.svg')}
    />
  </HGTouchableOpacity>
);
export const GreenCheckCircle = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/green-check.svg')}
    />
  </HGTouchableOpacity>
);
export const ArrowSwap = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/arrow-swap.svg')}
    />
  </HGTouchableOpacity>
);

export const Eye = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/eye.svg')}
    />
  </HGTouchableOpacity>
);
export const FlagEnglish = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/flag-uk.svg')}
    />
  </HGTouchableOpacity>
);
export const FlagGermany = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/flag-germany.svg')}
    />
  </HGTouchableOpacity>
);
export const Globe = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/globe.svg')}
    />
  </HGTouchableOpacity>
);
export const IdCard = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/id-card.svg')}
    />
  </HGTouchableOpacity>
);
export const Warning = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/warning-border-gradient.svg')}
    />
  </HGTouchableOpacity>
);
export const ShoppingCartLine = ({ style, ...props }) => (
  <HGTouchableOpacity disabled={true}>
    <Image
      style={[styles.icon, style]}
      {...props}
      source={require('./svgs/shopping-cart-line.svg')}
    />
  </HGTouchableOpacity>
);

const styles = StyleSheet.create({
  icon: { width: 32, height: 32 },
});
