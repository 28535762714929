import {
  BlackCross,
  CovidCertificate,
  Document,
  HospitalCertificate,
  More,
} from 'components/HGIcon';
import HGText from 'components/HGText';
import IconButton from 'components/IconButton';
import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import {DateTime} from 'luxon';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Image, Linking, StyleSheet, TouchableOpacity, View} from 'react-native';
import {scale} from 'utils/Scale';
import BasicLoadingIndicator from '../../../../components/BasicLoadingIndicator';
import useCorona from '../../../../hooks/useCorona';
import useModal from '../../../../hooks/useModal';
import {checkIfImageURL} from '../../../../utils/Utils';
import MoreButton from './MoreButton';

export default ({documentType, lastRefresh, parentTouch}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [localLastRefresh, setLocalLastRefresh] = useState(lastRefresh);
  const [files, setFiles] = useState([]);
  const {getDocuments, deleteDocument} = useCorona();
  const {showModal} = useModal();
  const [containerHeight, setContainerHeight] = useState(0);
  const [openFloating, setOpenFloating] = useState(false);
  const [lastToggleFloating, setLastToggleFloating] = useState(parentTouch);
  const [floatingParams, setFloatingParams] = useState({});

  const loadFiles = useCallback(
    async file => {
      const {url, id} = file;
      setLoading(true);
      setFiles([...files, {id, url, name: url.split('/').pop()}]);
      setLocalLastRefresh(file);
      setLoading(false);
    },
    [files],
  );

  const loadDocuments = useCallback(async () => {
    setLoading(true);
    const data = await getDocuments({type: documentType});
    setFiles(data.map(({url, id}) => ({id, url, name: url.split('/').pop()})));
    setLoading(false);
  }, [documentType, getDocuments]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments]);

  useEffect(() => {
    if (lastRefresh && lastRefresh !== localLastRefresh) {
      loadFiles(lastRefresh);
    }
  }, [lastRefresh, localLastRefresh, loadFiles]);

  useEffect(() => {
    if (parentTouch - lastToggleFloating > 200) {
      setOpenFloating(false);
    }
  }, [lastToggleFloating, parentTouch]);

  const concatName = name => {
    if (name.length > 18) {
      return `${name.slice(0, 9)}...${name.slice(name.length - 8)}`;
    }
    return name;
  };

  const getDocumentIcon = useCallback(
    (type = 'icon') => {
      switch (documentType) {
        case 'vaccination':
          return (
            <CovidCertificate
              style={
                styles[
                  type === 'icon'
                    ? 'fileColIconVaccination'
                    : 'placeholderIconVaccination'
                ]
              }
            />
          );
        case 'recovery':
          return (
            <Document
              style={
                styles[type === 'icon' ? 'fileColIcon' : 'placeholderIcon']
              }
            />
          );
        case 'test':
          return (
            <HospitalCertificate
              style={
                styles[
                  type === 'icon' ? 'fileColIconTest' : 'placeholderIconTest'
                ]
              }
            />
          );
      }
    },
    [documentType],
  );

  const onDeleteFile = useCallback(
    toDelete => {
      if (openFloating) {
        setOpenFloating(false);
      }
      showModal({
        title: t('Confirm delete?'),
        actions: [
          {label: t('Cancel'), variant: 'secondary'},
          {
            label: 'ok',
            onPress: () => {
              const deletingFile = files[toDelete];
              deleteDocument({id: deletingFile.id});
              setFiles(files.filter((file, j) => j !== toDelete));
              showModal(null);
            },
          },
        ],
      });
    },
    [files, deleteDocument, showModal, t, openFloating],
  );

  const toggleFloating = useCallback(
    (evt, params) => {
      setOpenFloating(false);
      if (!evt) {
        return;
      }

      const itemHeight =
        (containerHeight - (files.length - 1) * scale(22)) / files.length;
      const {i, url} = params;
      setFloatingParams({
        i,
        url,
        x: 0,
        y: itemHeight * i + i * scale(22) + itemHeight - scale(25),
      });
      setOpenFloating(true);
      setLastToggleFloating(new Date().getTime());
    },
    [containerHeight, files],
  );

  return (
    <View
      onLayout={evt => {
        setContainerHeight(evt.nativeEvent.layout.height);
      }}>
      {loading ? (
        <BasicLoadingIndicator />
      ) : (
        files.length === 0 && getDocumentIcon('placeholder')
      )}

      {files.map(({lastModifiedDate: date, lastModified, name, url}, i) => {
        return (
          <Row
            style={[
              styles.container,
              i === files.length - 1 ? {} : styles.containerNotLast,
            ]}
            key={`${name}-${i}`}>
            <TouchableOpacity
              style={styles.crossButtonContainer}
              onPress={onDeleteFile.bind(null, i)}>
              <View>
                <BlackCross style={styles.crossIcon} />
              </View>
            </TouchableOpacity>
            <View style={styles.fileCol}>
              {checkIfImageURL(url) ? (
                <Image
                  source={{uri: url}}
                  style={styles.fileImagePreview}
                  resizeMode="cover"
                />
              ) : (
                getDocumentIcon()
              )}
            </View>
            <View style={styles.fileDetailsCol}>
              <HGText fontSize={scale(14)}>{concatName(name)}</HGText>
              {/* temp remove as there is no last modified time for uploads
              <HGText
                color={hexToRgba('#7B7B7B', 0.79)}
                fontSize={scale(10)}>{`${DateTime.fromJSDate(
                new Date(date ?? lastModified),
              ).toFormat('dd.MM.yyyy HH:mm')}`}</HGText>
              */}
            </View>
            <IconButton
              style={styles.iconButton}
              icon={<More />}
              onPress={evt => {
                toggleFloating(evt, {i, url});
              }}
            />
          </Row>
        );
      })}
      {openFloating && (
        <MoreButton
          toggleFloating={toggleFloating}
          url={floatingParams.url}
          onDelete={() => onDeleteFile(floatingParams.i)}
          x={floatingParams.x}
          y={floatingParams.y}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: -1,
    backgroundColor: '#EBE9E8',
    borderRadius: scale(10),
    paddingRight: scale(15),
    paddingLeft: scale(29),
    paddingVertical: scale(14),
    alignItems: 'center',
  },
  containerNotLast: {
    marginBottom: scale(22),
  },
  fileCol: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileColIcon: {
    width: '100%',
    height: scale(100),
  },
  fileColIconVaccination: {
    width: scale(56.14),
    height: scale(75.38),
  },
  fileImagePreview: {
    width: scale(56),
    height: scale(75),
  },
  fileColIconTest: {
    width: scale(56.14),
    height: scale(46.53),
  },
  placeholderIcon: {
    width: '100%',
    height: scale(200),
  },
  placeholderIconVaccination: {
    alignSelf: 'center',
    width: scale(168.42),
    height: scale(226.14),
  },
  placeholderIconTest: {
    alignSelf: 'center',
    width: scale(272.87),
    height: scale(226.14),
  },
  fileDetailsCol: {
    flex: 2,
  },
  iconButton: {
    backgroundColor: '#EBE9E8',
  },
  crossButtonContainer: {
    position: 'absolute',
    top: scale(10),
    left: scale(10),
  },
  crossIcon: {
    height: scale(10),
    width: scale(10),
  },
});
