import React, {ReactElement, useState} from 'react';
import { ImageBackground, ImageBackgroundProps, View} from 'react-native';
import BasicLoadingIndicator from '../BasicLoadingIndicator';

interface HGImageBackgroundProps extends ImageBackgroundProps {}

const HGImage: React.FC<HGImageBackgroundProps> = (
  props: HGImageBackgroundProps,
): ReactElement => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && (
        <View
          style={{
            height: props.height ? props.height : props.style?.height ?? 100,
            width: props.width ? props.width : '100%' ?? 100,
          }}>
          <BasicLoadingIndicator />
        </View>
      )}
      <ImageBackground
        {...{
          ...props,
          style: [props.style, {display: loading ? 'none' : 'flex'}],
        }}
        onLoadEnd={() => setLoading(false)}
      />
    </>
  );
};

export default HGImage;
