import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import {CardContainer} from '../../components/Card';
import {useTranslation} from 'react-i18next';
import HGText from '../../components/HGText';
import RadioButtonInput from '../../components/RadioButtonInput';
import TwoActionButtons from '../../components/TwoActionButtons';
import {ScrollView} from 'react-native-gesture-handler';
import HGButton from '../../components/HGButton';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const [option, setOption] = useState('credit');

  const onSelectOption = _option => {
    setOption(_option);
  };
  return (
    <Container>
      <ScrollView contentContainerStyle={{minHeight: '100%'}}>
        <CardContainer densePadded={true} containerStyle={styles.container}>
          <HGText>{t('checkout.selectPayment')}</HGText>
        </CardContainer>
        <CardContainer densePadded={true} containerStyle={styles.container}>
          <RadioButtonInput
            active={option === 'credit'}
            label={t('Credit Card')}
            onPress={onSelectOption.bind(null, 'credit')}
          />
          <View style={styles.creditCardContainer}>
            <HGText>{t('checkout.redirectMessage')}</HGText>
          </View>
        </CardContainer>
        <CardContainer densePadded={true}>
          <RadioButtonInput
            active={option === 'paypal'}
            label={t('Paypal')}
            onPress={onSelectOption.bind(null, 'paypal')}
          />
        </CardContainer>
        <View style={styles.buttonContainer}>
          <HGButton
            size="large"
            label={t('Update')}
            onPress={navigation.goBack}
          />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  container: {
    marginTop: scale(20),
    marginBottom: scale(13),
  },
  creditCardContainer: {
    marginTop: scale(18),
    marginLeft: scale(25.5),
  },
  buttonContainer: {
    marginTop: scale(50),
    alignItems: 'center',
  },
});
