import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import config from '../config';

export default ({ eventId }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [productOption, setProductOption] = useState([]);
  const [error, setError] = useState(null);
  const accessToken = useSelector(state => state.account.accessToken);

  const fetchProductOption = useCallback(async () => {
    try {
      const res = await fetch(config.ENDPOINT_FETCH_EVENTS_PRODUCT_OPTION.replace(":eventId",eventId), {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + accessToken
        },
      });

      const json = await res.json();
      if (json.error === undefined && json.result != "error"){
        setProductOption(json)
      }
    } 
    catch (err) {
      setError(err);
    } 
    finally {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!isLoaded) {
      fetchProductOption();
    }
  }, []);

  return {productOption, isLoaded};
};
