export const GENDER_OPTIONS = [
  {value: 'm', label: 'Male'},
  {value: 'f', label: 'Female'},
  {value: 'o', label: 'Others'},
];

export const LANGUAGE_OPTIONS = [
  {value: 'en', label: 'English'},
  {value: 'de', label: 'German'},
];

export const getSalutationOptions = language => {
  switch (language) {
    case 'de':
      return [
        {value: 'm', label: 'Herr'},
        {value: 'f', label: 'Frau'},
        {value: 'd', label: 'Divers'},
      ];

    case 'en':
    default:
      return [
        {value: 'm', label: 'Mr'},
        {value: 'f', label: 'Mrs'},
        {value: 'd', label: 'Mx'},
      ];
  }
};

export const getTitleOptions = language => {
  switch (language) {
    case 'de':
      return [
        {value: '-', label: '-'},
        {value: 'dr', label: 'Dr.'},
        {value: 'prof', label: 'Prof.'},
        {value: 'ing', label: 'Ing.'},
      ];
    case 'en':
    default:
      return [
        {value: '-', label: '-'},
        {value: 'dr', label: 'Dr.'},
        {value: 'prof', label: 'Professor'},
        {value: 'phd', label: 'PhD'},
        {value: 'dPhil', label: 'DPhil'},
      ];
  }
};
