import React from 'react';
import {StyleSheet, TouchableOpacity, Linking, Image} from 'react-native';
import Row from '../Row';
import HGText from '../HGText';
import {scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
const IMAGE_DOT_PATH = require('../../../assets/images/middle-dot-icon-maroon.png');
export default ({data}) => {
  const {t} = useTranslation();
  return (
    <Row style={styles.legalLinksContainer}>
      <TouchableOpacity onPress={() => Linking.openURL(data.privacy_url)}>
        <HGText style={styles.legalLinkButtonText}>
          {t('legalLinks.privacy')}
        </HGText>
      </TouchableOpacity>
      <Image
        resizeMode="contain"
        source={IMAGE_DOT_PATH}
        style={styles.dotDividerImage}
      />
      <TouchableOpacity onPress={() => Linking.openURL(data.terms_url)}>
        <HGText style={styles.legalLinkButtonText}>
          {t('legalLinks.terms')}
        </HGText>
      </TouchableOpacity>
      <Image
        resizeMode="contain"
        source={IMAGE_DOT_PATH}
        style={styles.dotDividerImage}
      />
      <TouchableOpacity onPress={() => Linking.openURL(data.imprint_url)}>
        <HGText style={styles.legalLinkButtonText}>
          {t('legalLinks.imprint')}
        </HGText>
      </TouchableOpacity>
    </Row>
  );
};

const styles = StyleSheet.create({
  legalLinksContainer: {
    marginTop: scale(20),
    justifyContent: 'center',
    alignItems: 'center',
  },
  legalLinkButtonText: {
    color: '#8d1e2e',
    textDecorationLine: 'underline',
    textDecorationColor: '#8d1e2e',
  },
  dotDividerImage: {
    width: scale(8),
    height: scale(8),
    marginLeft: scale(15),
    marginRight: scale(15),
  },
});
