import {ArrowSwap, LeftChevron} from 'components/HGIcon';
import HGText from 'components/HGText';
import IconButton from 'components/IconButton';
import NotificationBanner from 'components/NotificationBanner';
import {Camera} from 'expo-camera';
import useCamera from 'hooks/useCamera';
import React, {useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import Colors from 'utils/Colors';
import {heightPercentageToDP, scale, widthPercentageToDP} from 'utils/Scale';

export default ({front, onPhoto, onClose}) => {
  const {t} = useTranslation();
  const [switchingCamera, setSwitchingCamera] = useState(false);
  const [cameraType, setCameraType] = useState(
    !front ? Camera.Constants.Type.front : Camera.Constants.Type.back,
  );
  const {permission, gettingPermission} = useCamera();
  const camera = useRef(null);

  const snapPhoto = async () => {
    const photo = await (Platform.OS === 'web'
      ? camera.current._cameraRef.takePicture({base64: true})
      : camera.current._cameraRef.takePictureAsnyc({base64: true}));
    onPhoto && onPhoto(photo);
  };

  const switchCamera = useCallback(async () => {
    // before switching we need to stop the source for web. to do this we render a "blank screen" to unmount the camera in the package and reinstate it once switched.
    if (Platform.OS === 'web') {
      await new Promise((resolve, reject) => {
        setSwitchingCamera(true);
        setTimeout(() => {
          resolve(true);
        }, 100);
      });
    }
    setCameraType(
      cameraType === 'back'
        ? Camera.Constants.Type.front
        : Camera.Constants.Type.back,
    );
    if (Platform.OS === 'web') {
      setSwitchingCamera(false);
    }
  }, [cameraType]);

  return gettingPermission || switchingCamera ? (
    <View />
  ) : !gettingPermission && !permission ? (
    <NotificationBanner
      body={t('Please grant camera permission')}
      actionText={
        <TouchableOpacity
          onPress={Linking.openSettings || onClose || (() => {})}>
          <HGText variant="actionText">{t('View settings')}</HGText>
        </TouchableOpacity>
      }
    />
  ) : (
    <Camera ref={camera} style={StyleSheet.absoluteFill} type={cameraType}>
      <IconButton
        style={styles.backButton}
        icon={<LeftChevron />}
        onPress={onClose || (() => {})}
      />
      <TouchableOpacity onPress={snapPhoto} style={styles.shutterContainer}>
        <View style={styles.shutterInner} />
      </TouchableOpacity>
      <IconButton
        size={scale(50)}
        style={styles.switchCameraButton}
        icon={<ArrowSwap />}
        onPress={switchCamera}
      />
    </Camera>
  );
};

const styles = StyleSheet.create({
  camera: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 500,
    height: 500,
    zIndex: 999,
  },
  backButton: {
    top: scale(20),
    left: scale(20),
  },
  shutterContainer: {
    position: 'absolute',
    left: widthPercentageToDP(50) - scale(40),
    bottom: scale(80),
    backgroundColor: 'white',
    width: scale(80),
    height: scale(80),
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: scale(40),
  },
  shutterInner: {
    width: scale(60),
    height: scale(60),
    borderRadius: scale(30),
    backgroundColor: Colors.PRIMARY_COLOR,
  },
  switchCameraButton: {
    position: 'absolute',
    top: heightPercentageToDP(100) - scale(180),
    right: widthPercentageToDP(25) - scale(40),
  },
});
