import React, {useCallback, useState} from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
import WhiteContainer from '../../components/WhiteContainer';
import HGTextInput from '../../components/HGTextInput';
import HGText from '../../components/HGText';
import NotificationBanner from '../../components/NotificationBanner';
import HGButton from '../../components/HGButton';
import useAccount from '../../hooks/useAccount';
import useModal from '../../hooks/useModal';
export default ({navigation, route}) => {
  const {t} = useTranslation();
  const {loading, requestResetPassword} = useAccount();
  const {showModal} = useModal();
  const [email, setEmail] = useState('');

  const submitRequest = useCallback(async () => {
    const res = await requestResetPassword({email});
    if (res.success) {
      showModal({
        title: t('Success'),
        body: t('Reset email has been sent to your email'),
        onClose: () => {
          showModal(null);
        },
      });
    } else {
      showModal({
        title: t('Error'),
        onClose: () => {
          showModal(null);
        },
      });
    }
  }, [email, requestResetPassword, showModal, t]);

  return (
    <Container style={styles.container}>
      <ScrollView>
        <View style={styles.notification}>
          <NotificationBanner body={t('messages.forgottenPassword')} />
        </View>
        <WhiteContainer style={styles.passwordBox}>
          <HGTextInput
            value={email}
            onChange={setEmail}
            variant="form"
            label={t('email')}
          />
        </WhiteContainer>
        <View style={styles.submitBtn}>
          <HGButton
            disabled={loading}
            label={t('request reset email')}
            onPress={submitRequest}
            loading={loading}
          />
        </View>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
  },
  notification: {
    marginVertical: scale(20),
  },
  passwordBox: {
    marginBottom: scale(50),
  },
  submitBtn: {
    alignItems: 'center',
    marginBottom: scale(70),
  },
});
