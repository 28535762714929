import React, {useEffect, useState} from 'react';
import {ScrollView, StyleSheet, View} from 'react-native';
import Container from 'components/Container';
import {scale} from '../../utils/Scale';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import Avatar from '../User/components/Avatar';
import PersonalDetailsForm from './components/PersonalDetailsForm';
import HGButton from 'components/HGButton';
import Row from 'components/Row';
import useAccount from '../../hooks/useAccount';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const {account, updateProfile, loading} = useAccount();
  const [initialized, setInitialized] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: {errors, isDirty, isValid},
  } = useForm({mode: 'onChange'});

  const [gender, language] = watch(['gender', 'language']);

  useEffect(() => {
    if (!initialized && account) {
      setInitialized(true);
      const {
        gender,
        lang,
        first_name,
        last_name,
        phone_number,
        email,
        country,
      } = account.user;
      gender && setValue('gender', gender);
      first_name && setValue('firstName', first_name);
      last_name && setValue('lastName', last_name);
      country && setValue('country', country);
      email && setValue('email', email);
      phone_number && setValue('phone', phone_number);
      lang && setValue('language', lang);
    }
  }, [initialized, account, setValue]);

  const onSubmit = data => {
    updateProfile(data, () => {
      navigation.goBack();
    });
  };

  return (
    <Container showFloatingBack={navigation}>
      <ScrollView contentContainerStyle={{paddingBottom: scale(20)}}>
          <View style={styles.avatarContainer}>
            <Avatar />
          </View>
          <View style={styles.formContainer}>
            <PersonalDetailsForm
              control={control}
              values={{gender, language}}
              errors={errors}
            />
          </View>
          <Row style={styles.updateContainer}>
            <HGButton
              loading={loading}
              size="large"
              label={t('Update')}
              onPress={handleSubmit(onSubmit)}
            />
          </Row>
      </ScrollView>
    </Container>
  );
};

const styles = StyleSheet.create({
  avatarContainer: {
    marginTop: scale(14),
    alignItems: 'center',
  },
  formContainer: {
    marginTop: scale(87),
  },
  updateContainer: {
    justifyContent: 'center',
    marginTop: scale(50),
    marginBottom: scale(70),
  },
});
