import {Platform} from 'react-native';
import {getBaseOs, getUserAgent} from 'react-native-device-info';
import config from '../config';
import is from 'is_js';

const getBrowser = () => {
  let browserName = '';
  if (is.ie()) {
    browserName = 'ie';
  } else if (is.chrome()) {
    browserName = 'chrome';
  } else if (is.firefox()) {
    browserName = 'firefox';
  } else if (is.edge()) {
    browserName = 'edge';
  } else if (is.opera()) {
    browserName = 'opera';
  } else if (is.safari()) {
    browserName = 'safari';
  } else if (is.phantom()) {
    browserName = 'phantom';
  }
  return browserName;
};

export default () => {
  const browserName = getBrowser();
  async function getUtm() {
    if (Platform.OS === 'web') {
      const baseOs = await getBaseOs().then(baseOs => {
        // "Windows", "Android" etc
        return baseOs.toLowerCase().replace(' ', '_');
      });

      const browserVer = await getUserAgent().then(userAgent => {
        const userAgentLower = userAgent.toLowerCase();
        const value = userAgentLower
          .substring(userAgentLower.indexOf(browserName + '/'))
          .split(' ')[0]
          .split('/')[1];
        return value;
      });
      //   const finalString = config.UTM_WEB.replace('$(webOs)', baseOs)
      //     .replace('$(webOs)', baseOs)
      //     .replace('$(webBrowserVer)', browserVer)
      //     .replace('$(webBrowserName)', browserName);
      const finalString = config.UTM_WEB(baseOs, browserVer, browserName);
      return finalString;
    } else {
      if (Platform.OS === 'android') {
        const osAndVersion = await getUserAgent().then(userAgent => {
          let finalString = '';
          const value = userAgent.substring(
            userAgent.indexOf('(') + 1,
            userAgent.indexOf(')'),
          );
          finalString = value
            .split(';')[1]
            .trim()
            .toLowerCase()
            .replace(' ', '-');
          return finalString;
        });
        // const finalString = config.UTM_ANDROID.replace(
        //   '$(mobileOs)',
        //   osAndVersion,
        // );
        const finalString = config.UTM_ANDROID(osAndVersion);
        return finalString;
      } else if (Platform.OS === 'ios') {
        const finalString = config.UTM_IOS;
        return finalString;
      }
    }
  }

  return {getUtm};
};
