import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import config from '../config';
import {updateProducts} from '../state/counterCheckout';
import getUtmDetails from '../hooks/getUtmDetails';

export default params => {
  const {getUtm} = getUtmDetails();
  const [initialize, setInitialize] = useState(false);
  const dispatch = useDispatch();
  const {products, productCategory, initializeProducts} = useSelector(
    state => state.counterCheckout,
  );
  const [loading, setLoading] = useState(false);

  const {refresh = false} = params ? params : {};

  const fetchProducts = useCallback(
    async function () {
      setLoading(true);
      try {
        const utmString = await getUtm();
        const res = await fetch(
          `${config.ENDPOINT_FETCH_FOOD_BEVERAGES_EXTENDED}${
            utmString && `?${utmString}`
          }`,
          {
            headers: {'X-HG-PWA-Origin': config.PWA_ORIGIN},
          },
        );
        const json = await res.json();

        const categories = {[json.id]: {name: json.name, items: []}};
        function traverseSubCategories(arr = []) {
          arr.forEach(newObj => {
            categories[newObj.id] = {name: newObj.name, items: []};
            traverseSubCategories(newObj.sub_categories);
          });
        }
        traverseSubCategories(json.sub_categories);

        json.products.forEach(product => {
          categories[product.category_id].items.push(product);
        });

        dispatch(
          updateProducts({
            products: json.products,
            productCategory: Object.keys(categories)
              .map(categoryId => ({
                id: categoryId,
                name: categories[categoryId].name,
                items: categories[categoryId].items,
              }))
              .filter(({items}) => items.length > 0),
          }),
        );
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (!initialize || refresh) {
      setInitialize(true);
      fetchProducts();
    }
  }, [initialize, fetchProducts, initializeProducts]);

  return {products, productCategory, loading};
};
