import Row from 'components/Row';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Linking,
  Platform,
  StyleSheet,
  View,
  TouchableOpacity,
} from 'react-native';
import Animated, {EasingNode} from 'react-native-reanimated';
import HGText from 'components/HGText';
import {scale} from 'utils/Scale';
import {Delete, Download, More} from '../../../../components/HGIcon';

export const MoreButton = ({toggleFloating, x, y, url, onDelete}) => {
  const {t} = useTranslation();

  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    fadeAnim.setValue(1);
  });

  return (
    <Animated.View
      style={[
        styles.floatingDropdown,
        styles.floatingDropdownOpen,
        {opacity: fadeAnim},
        {top: y, right: x},
      ]}>
      <TouchableOpacity
        hitSlop={{top: 15, bottom: 15, left: 15, right: 15}}
        onPress={() => {
          toggleFloating();
          onDelete();
        }}>
        <Row style={styles.dropdownItem}>
          <View style={styles.dropdownItemIconContainer}>
            <Delete />
          </View>
          <HGText color="#000">{t('Delete')}</HGText>
        </Row>
      </TouchableOpacity>
      <TouchableOpacity
        hitSlop={{top: 15, bottom: 15, left: 15, right: 15}}
        onPress={() => {
          toggleFloating();
          Linking.openURL(url);
        }}>
        <Row style={styles.dropdownItemLast}>
          <View style={styles.dropdownItemIconContainer}>
            <Download />
          </View>
          <HGText color="#000">{t('Download')}</HGText>
        </Row>
      </TouchableOpacity>
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: scale(20),
    paddingVertical: scale(15),
    borderRadius: scale(10),
    height: scale(50),
  },
  iconContainer: {
    width: scale(32),
    marginRight: scale(21),
  },
  label: {
    flex: 1,
    fontSize: scale(13),
  },
  floatingDropdown: {
    position: 'absolute',
    top: scale(58),
    width: scale(150),
    paddingHorizontal: scale(15),
    paddingVertical: scale(17),
    backgroundColor: '#FFF',
    borderRadius: scale(10),
  },
  floatingDropdownOpen: {
    zIndex: 999,
    display: 'flex',
  },
  floatingDropdownClose: {
    zIndex: -1,
    display: 'none',
  },
  dropdownItem: {
    zIndex: 9999,
    marginBottom: scale(10),
    alignItems: 'center',
  },
  dropdownItemLast: {
    zIndex: 9999,
    alignItems: 'center',
  },
  dropdownItemIconContainer: {
    marginRight: scale(9),
  },

  iconButton: {
    backgroundColor: '#EBE9E8',
  },
});

export default MoreButton;
