import React, {useCallback, useEffect, useState} from 'react';
import {
  LayoutAnimation,
  NativeModules,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {CardContainer} from '../../../components/Card';
import {scale} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import HGCheckBox from '../../../components/HGCheckBox';
import {useTranslation} from 'react-i18next';
import Row from '../../../components/Row';
import Colors from '../../../utils/Colors';
import QuantityStepInput from '../../../components/QuantityStepInput';
import hexToRgba from 'hex-to-rgba';
import {parseCurrency} from '../../../utils/Utils';
const {UIManager} = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

const Divider = () => <View style={styles.divider} />;

const Option = ({
  title,
  name,
  unit,
  price,
  quantity,
  checked,
  onChangeCheck,
  onChangeQuantity,
  stock_qty,
}) => {
  const {t, i18n} = useTranslation();
  return (
    <View style={styles.optionContainer}>
      <Row style={styles.checkbox}>
        <View style={styles.fullWidth}>
          <HGCheckBox
            disabled={stock_qty === 0}
            onChange={onChangeCheck}
            value={checked}
            label={title ? (i18n.language === 'de' ? title.de : title.en) : '-'}
            largeLabel={true}
          />
        </View>
        <HGText fontSize={scale(17)} style={stock_qty === 0 && {color:"darkgrey"}}>
          {t('selectTicketQtyCard.eachOption').replace(
            '${price}',
            parseCurrency({amount: price}),
          )}
        </HGText>
      </Row>
      {stock_qty === 0 &&
      <Row>
        <View style={styles.containerQtyLeft} />
        <View style={styles.containerQtyRight}>
          <HGText style={styles.textStockCheck}>
            {stock_qty === 0 ? t('selectTicketQtyCard.soldOut') : null}
          </HGText>
        </View>
      </Row>
      }
      {checked ? (
        <View>
          <Divider />
          <Row style={styles.quantityInputContainer}>
            <View style={styles.fullWidth}>
              <HGText capitalize={true} fontSize={scale(15)}>
                {t('quantity')}
              </HGText>
            </View>
            <QuantityStepInput
              onChange={onChangeQuantity}
              stock={stock_qty}
              isStockCheckMsgVisible={false}
              isTicket={true}
            />
          </Row>
          <Row>
            <View style={styles.containerQtyLeft} />
            <View style={styles.containerQtyRight}>
              {stock_qty <= 5 && stock_qty !== false ? (
                <HGText style={styles.textStockCheck}>
                  {stock_qty > 0
                    ? t('selectTicketQtyCard.stockCheckOption').replace(
                        '${stock}',
                        stock_qty,
                      )
                    : null}
                  {stock_qty === 0 ? t('selectTicketQtyCard.soldOut') : null}
                </HGText>
              ) : null}
            </View>
          </Row>
        </View>
      ) : (
        <View />
      )}
    </View>
  );
};

export default ({options = [], onChange}) => {
  const {t} = useTranslation();
  const [checked, setChecked] = useState(options.map(() => false));
  const [quantity, _setQuantity] = useState(options.map(() => 1));
  const setCheck = useCallback(
    (val, i) => {
      LayoutAnimation.easeInEaseOut();
      setChecked(checked.map((check, j) => (i === j ? val : check)));
      if (!val) {
        // reset quantity to 1;
        setQuantity(1, i);
      }
    },
    [checked, setQuantity],
  );
  const setQuantity = useCallback(
    (val, i) => {
      _setQuantity(quantity.map((qty, j) => (i === j ? val : qty)));
    },
    [quantity],
  );

  useEffect(() => {
    let finalOptions = [];
    quantity.map((qty, i) => {
      if (checked[i]) {
        const optionId = options[i].id;
        finalOptions.push({id: optionId, qty: qty});
      }
    });
    onChange(finalOptions);
  }, [onChange, quantity, checked]);
  return (
    <CardContainer padded={true}>
      <HGText variant="header" style={styles.header}>
        {t('available options')}
      </HGText>
      {options.map((option, i) => {
        return (
          <Option
            key={i}
            {...option}
            checked={checked[i]}
            stock_qty={option.stock_qty}
            quantity={quantity[i]}
            onChangeCheck={val => {
              setCheck(val, i);
            }}
            onChangeQuantity={val => {
              setQuantity(val, i);
            }}
          />
        );
      })}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  divider: {
    borderTopWidth: 1,
    borderTopColor: hexToRgba('#808080', 0.25),
    width: '110%',
    marginLeft: '-5%',
    marginVertical: scale(14),
  },
  checkbox: {
    alignItems: 'center',
    marginLeft: scale(-6),
  },
  header: {
    fontSize: scale(18),
    marginBottom: scale(13),
    textTransform: 'uppercase',
  },
  title: {
    fontSize: scale(17),
  },
  meta: {color: Colors.PRIMARY_COLOR},
  fullWidth: {flex: 1},
  row: {
    alignItems: 'center',
  },
  quantityInputContainer: {
    alignItems: 'center',
    marginBottom: scale(7),
  },
  optionContainer: {
    marginTop: scale(12),
  },
  textStockCheck: {
    fontWeight: 'bold',
    color: Colors.STOCK_CHECK_COLOR,
  },
  containerQtyLeft: {
    flex: 0.3,
  },
  containerQtyRight: {
    flex: 0.7,
    alignItems: 'flex-end',
  },
});
