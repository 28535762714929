import {createSlice} from '@reduxjs/toolkit';
import config from '../../config';
import {} from './helpers';
import getUtmDetails from '../../hooks/getUtmDetails';

const {getUtm} = getUtmDetails();

const initialState = {
  loading: false,
  initialize: false,
  error: false,
  events: [],
  categories: [],
  products: {},
  product_options: [],
  timerActive: true,
};

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setInitialized: (state, action) => {
      state.initialize = action.payload;
    },
    initialize: (state, action) => {
      state.events = action.payload.events;
      state.categories = action.payload.categories;
      state.products = action.payload.products;
      state.product_options = action.payload.product_options;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setTimerActive: (state, action) => {
      state.timerActive = action.payload;
    },
  },
});

export const actions = eventsSlice.actions;

const fetchData = async language => {
  let headers = {'X-HG-PWA-Origin': config.PWA_ORIGIN};
  if (language) {
    headers = {...headers, 'Accept-Language': language};
  }

  const utmString = await getUtm();

  const res = await fetch(
    `${config.ENDPOINT_FETCH_EVENTS}${utmString && `?${utmString}`}`,
    {
      headers: headers,
    },
  );
  const json = await res.json();
  return json;
};

export const initializeData =
  (stopTimer = false) =>
  async (dispatch, getState) => {
    const language = getState().account.appLanguage;
    dispatch(actions.setLoading(true));
    dispatch(actions.setInitialized(true));
    if (stopTimer) {
      dispatch(actions.setTimerActive(false));
    }
    try {
      const {events, categories, products, product_options} = await fetchData(
        language,
      );
      const productsObj = {};
      products.forEach(product => {
        productsObj[product.id] = product;
      });
      const productOptionsObj = {};
      product_options.forEach(option => {
        productOptionsObj[option.id] = option;
      });
      dispatch(
        actions.initialize({
          categories,
          events: events.map(({title, content, ...event}) => ({
            ...event,
            title: title ? (typeof title === 'string' ? title : title.de) : '-',
            content: content ?? '-',
          })),
          // events: events.filter(({title, content}) => title && content),
          products: productsObj,
          product_options: productOptionsObj,
        }),
      );
    } catch (err) {
      dispatch(actions.setError(true));
    } finally {
      dispatch(actions.setLoading(false));
      if (stopTimer) {
        dispatch(actions.setTimerActive(true));
      }
    }
  };

export default eventsSlice.reducer;
