import config from '../../config';
import HGText from 'components/HGText';
import GoogleMapReact from 'google-map-react';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {scale} from 'utils/Scale';
import {useTranslation} from 'react-i18next';
import HGButton from '../HGButton';

export default ({
  height,
  latitude = 1.3463418183516078,
  longitude = 103.87300727598004,
  onPressDirections,
}) => {
  const {t} = useTranslation();
  const position = {lat: latitude, lng: longitude};
  const onMapLoad = ({map, maps}) => {
    new maps.Marker({
      position,
      map,
    });
  };
  return (
    <View style={[styles.container, height ? {height} : {}]}>
      <GoogleMapReact
        bootstrapURLKeys={{key: config.GOOGLE_MAPS_API_KEY}}
        defaultCenter={position}
        defaultZoom={14}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapLoad}
      />
      {onPressDirections && (
        <View style={{position: 'absolute', bottom: 30, left: 5}}>
          <HGButton
            size="tiny"
            variant="secondary"
            label={t('directions')}
            onPress={onPressDirections}
          />
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: scale(200),
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
});
