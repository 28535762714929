import React, {useCallback, useState} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  NativeModules,
  LayoutAnimation,
  Platform,
} from 'react-native';
import {
  CardContainer,
  styles as CardContainerStyles,
} from '../../../components/Card';
import {scale, widthPercentageToDP} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import Row from 'components/Row';
import IconButton from 'components/IconButton';
import {PlusCircle} from 'components/HGIcon';
import hexToRgba from 'hex-to-rgba';
import Colors from 'utils/Colors';
import {useTranslation} from 'react-i18next';
import QuantityStepInput from 'components/QuantityStepInput';
import useCounterCheckout from 'hooks/useCounterCheckout';
import {parseCurrency} from '../../../utils/Utils';
const {UIManager} = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export default ({name, options, itemId, categoryId}) => {
  const {t} = useTranslation();
  const {addCart, removeCart, deleteCart, cartItem : cart} = useCounterCheckout();

  const [showMore, _setShowMore] = useState(false);
  const setShowMore = useCallback(() => {
    LayoutAnimation.easeInEaseOut();
    _setShowMore(!showMore);
  }, [showMore]);

  const getOptionStatement = () => {
    if (options.length === 1) {
      return options[0].description;
    } else {
      return `Choice between: ${options
        .slice(0, options.length - 1)
        .map(({description}) => description)
        .join(', ')} and ${options[options.length - 1].description}`;
    }
  };

  const getMinPrice = () => {
    const price =
      options.length === 1
        ? options[0].price
        : options.sort((a, b) => a.price - b.price)[0].price;
    if (options.length === 1) {
      return `${parseCurrency({amount: price})}`;
    } else {
      return `From ${parseCurrency({amount: price})}
      `;
    }
  };

  return (
    <CardContainer densePadded={true}>
      <HGText style={styles.title}>{name}</HGText>
      <Row style={styles.optionContainer}>
        <HGText style={styles.optionDescription}>{getOptionStatement()}</HGText>
        <View style={styles.spacer} />
        <IconButton
          onPress={setShowMore}
          size={scale(30)}
          icon={<PlusCircle style={styles.moreButtonIcon} />}
        />
      </Row>
      <HGText style={styles.optionMinPrice}>{getMinPrice()}</HGText>
      {showMore && (
        <>
          <View style={styles.moreDivider} />
          <View style={styles.optionsContainer}>
            <HGText style={styles.optionMinPrice}>{t('Select size')}:</HGText>
            {options
              .map(({description}, i) => (
                <Row key={`${name}-${description}`} style={styles.optionRow}>
                  <QuantityStepInput
                    colorIfEnabled={true}
                    minValue={0}
                    size="small"
                    quantity={cart.items[`${categoryId}-${itemId}-${i}`]}
                    onChange={(val, isAdd) => {
                      if (isAdd) {
                        addCart({key: `${categoryId}-${itemId}-${i}`});
                      } else {
                        removeCart({key: `${categoryId}-${itemId}-${i}`});
                      }
                    }}
                  />
                  <HGText style={styles.optionExactDescription}>
                    {description}
                  </HGText>
                </Row>
              ))
              .reverse()}
          </View>
        </>
      )}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  spacer: {flex: 1},
  title: {
    fontSize: scale(17),
    marginBottom: scale(9),
    fontWeight: '500',
  },
  optionContainer: {
    alignItems: 'center',
    marginBottom: scale(10),
  },
  optionDescription: {
    fontStyle: 'italic',
  },
  moreButtonIcon: {
    width: scale(45),
    height: scale(45),
  },
  optionMinPrice: {
    fontSize: scale(15),
    fontWeight: '500',
  },
  moreDivider: {
    width: widthPercentageToDP(100),
    marginLeft:
      Platform.OS === 'web'
        ? scale(-22.5)
        : -CardContainerStyles.densedPadded.paddingHorizontal,
    marginTop: scale(17),
    marginBottom: scale(15),
    borderTopWidth: 1,
    borderTopColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
  },
  optionsContainer: {
    paddingLeft: scale(7),
  },
  optionRow: {
    marginTop: scale(17),
    alignItems: 'center',
  },
  optionExactDescription: {
    fontSize: scale(15),
    marginLeft: scale(30.5),
  },
});
