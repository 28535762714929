import React, {useEffect, useState} from 'react';
import {Platform} from 'react-native';
import useModal from 'hooks/useModal';
import Container from 'components/Container';
import HGText from 'components/HGText';

export default () => {
  const {showModal} = useModal();
  const [loaded, setLoaded] = useState(Platform.OS !== 'web');
  const [startingOrientation, setStartingOrientation] = useState(
    Platform.OS === 'web'
      ? screen?.orientation?.angle ?? window.orientation ?? 0
      : 0,
  );
  useEffect(() => {
    if (!loaded && Platform.OS === 'web' && typeof window !== 'undefined') {
      const onOrientationChange = function () {
        if (
          (screen && screen.orientation && screen.orientation.angle !== 0) ||
          (window.orientation && window.orientation !== 0)
        ) {
          showModal({
            type: 'empty',
            component: () => (
              <Container
                style={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <HGText>Please switch back to portrait mode</HGText>
              </Container>
            ),
          });
        } else {
          showModal(null);
          if (startingOrientation !== 0) {
            // need refresh else all the things are out of position
            window.location.reload();
          }
        }
      };
      window.addEventListener('orientationchange', onOrientationChange, false);
      onOrientationChange();
      setLoaded(true);
    }
  }, [loaded, showModal, startingOrientation]);
};
