import React from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import FrontNavigationHeader from '../components/FrontNavigationHeader';
import {useTranslation} from 'react-i18next';
import Bar from '../views/Bar';
const Stack = createStackNavigator();
export default ({}) => {
  const {t} = useTranslation();
  return (
    <Stack.Navigator initialRouteName="Counter">
      <Stack.Screen
        name="Counter"
        component={Bar}
        options={{
          header: ({scene: {route}}) => (
            <FrontNavigationHeader
              title={t('bar.navigation.title')}
              {...(route.params?.event
                ? {subTitle: route.params.event.title.toUpperCase()}
                : {})}
            />
          ),
        }}
      />
    </Stack.Navigator>
  );
};
