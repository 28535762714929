import Container from 'components/Container';
import HGButton from 'components/HGButton';
import HGText from 'components/HGText';
import Row from 'components/Row';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  Image,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {scale, widthPercentageToDP, heightPercentageToDP} from 'utils/Scale';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import getOrderDetails from '../../hooks/getOrderDetails';
import {replaceWebUrl} from '../../utils/Utils';
import ExternalProviderPlaceholder from './ExternalProviderPlaceholder';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const {getOrder, loading} = getOrderDetails();
  const [showPlaceholder, setShowPlaceholder] = useState(
    typeof route.params?.showPlaceholder !== 'undefined'
      ? route.params?.showPlaceholder
      : true,
  );
  const [order, setOrder] = useState({});
  const [initialize, setInitialize] = useState(
    typeof route.params?.showPlaceholder !== 'undefined'
      ? route.params?.showPlaceholder
      : true,
  );

  const fetchOrder = useCallback(async () => {
    if (route.params?.orderType && !route.params?.success) {
      setOrder({type: route.params.orderType});
      return;
    }
    const order = await getOrder(route.params?.orderId);
    setOrder(order);
  }, [getOrder, route]);

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      fetchOrder();
    }
  }, [initialize, fetchOrder]);

  if (showPlaceholder) {
    return (
      <ExternalProviderPlaceholder
        onContinue={() => {
          setShowPlaceholder(false);
        }}
      />
    );
  }

  if (loading) {
    return (
      <Container style={styles.container}>
        <BasicLoadingIndicator />
      </Container>
    );
  }

  return (
    <ScrollView>
      <Container style={styles.container}>
        <Image
          style={{
            width: heightPercentageToDP(33) * 1.3,
            height: heightPercentageToDP(33),
            marginBottom: scale(30),
          }}
          source={
            route.params?.success
              ? require('../../../assets/images/Payment-successful.png')
              : require('../../../assets/images/Payment-error.png')
          }
        />
        <View style={styles.thankContainer}>
          <HGText fontSize={scale(22)} style={{textAlign: 'center'}}>
            {route.params?.success
              ? t('paymentResults.successGeneral')
              : t('paymentResults.failureGeneral')}
          </HGText>
        </View>
        <View>
          <HGText fontSize={scale(17)} style={{textAlign: 'center'}}>
            {route.params?.success
              ? order?.type === 'counter'
                ? t('paymentResults.success.counter')
                : t('paymentResults.success.ticket')
              : t('paymentResults.failure')}
          </HGText>
        </View>
        <View style={styles.buttonContainer}>
          {route.params?.success ? (
            <HGButton
              fullWidth={true}
              label={
                order?.type === 'counter'
                  ? t('paymentResults.success.counterButton')
                  : t('My tickets')
              }
              onPress={() => {
                replaceWebUrl();
                navigation.reset({
                  index: 0,
                  routes: [
                    {
                      name: 'App',
                      params: {
                        screen: order?.type === 'counter' ? 'Bar' : 'Tickets',
                        goToCollect: true,
                      },
                    },
                  ],
                });
              }}
            />
          ) : (
            <HGButton
              fullWidth={true}
              label={t('Try again')}
              onPress={() => {
                replaceWebUrl();
                navigation.reset({
                  index: 1,
                  routes: [
                    {name: 'App'},
                    {
                      name: 'Checkout',
                      params: {
                        screen:
                          order?.type === 'counter'
                            ? 'CounterCheckoutCart'
                            : 'TicketCheckoutCart',
                        params: {
                          getLastCheckoutOrder: true,
                        },
                      },
                    },
                  ],
                });
              }}
            />
          )}
        </View>
        <TouchableOpacity
          onPress={() => {
            replaceWebUrl();
            navigation.replace('App');
          }}>
          <HGText fontSize={scale(17)} style={styles.linkText}>
            {t('Discover more')}
          </HGText>
        </TouchableOpacity>
      </Container>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: scale(20),
    minHeight: heightPercentageToDP(100),
    justifyContent: 'center',
    alignItems: 'center',
  },
  thankContainer: {
    width: heightPercentageToDP(33) * 1.3,
    textAlign: 'center',
    marginBottom: scale(32),
  },
  buttonContainer: {
    width: '100%',
    marginTop: scale(83),
    marginBottom: scale(19),
  },
  linkText: {
    color: '#8E8B8F',
  },
});
