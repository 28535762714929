import React, {useState} from 'react';
import {Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import config from '../config';
import {
  modifyCart as _modifyCart,
  updateTimeslot as _updateTimeslot,
  updatePaymentMethod as _updatePaymentMethod,
  clearCart as _clearCart,
  resetLastOrder,
} from '../state/counterCheckout';
import {getTimeslotMap} from '../utils/Utils';
import getUtmDetails from '../hooks/getUtmDetails';

export default () => {
  const {getUtm} = getUtmDetails();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const cart = useSelector(state => state.counterCheckout);
  const accessToken = useSelector(state => state.account.accessToken);
  const userId = useSelector(state => state.account.id);

  async function addCart({key}, cb) {
    dispatch(_modifyCart({userId:userId,itemOptionKey: key, modifier: 'add'}, cb));
  }
  async function removeCart({key}, cb) {
    dispatch(_modifyCart({userId:userId,itemOptionKey: key, modifier: 'remove'}, cb));
  }
  async function deleteCart({key}, cb) {
    dispatch(_modifyCart({userId:userId,itemOptionKey: key, modifier: 'delete'}, cb));
  }
  async function updateTimeslot({timeslot}, cb) {
    dispatch(_updateTimeslot({timeslot}, cb));
  }
  async function updatePaymentMethod({paymentMethod}, cb) {
    dispatch(_updatePaymentMethod({paymentMethod}, cb));
  }
  async function clearCart(cb) {
    dispatch(_clearCart({userId:userId},cb));
  }

  async function checkoutOrder({products, timeslot}, cb) {
    setLoading(true);
    const orderItems = [];
    Object.keys(products).forEach(productId => {
      orderItems.push({id: productId, qty: products[productId]});
    });
    let results = null;
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_CHECKOUT_ORDER}${utmString && `?${utmString}`}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
          body: JSON.stringify({
            order: {
              order_time: getTimeslotMap(timeslot),
              items: orderItems,
              addresses: [
                {
                  address_1: '-',
                  address_2: '-',
                  city: '-',
                  zip: '-',
                  country_code: '-',
                  address_type: 'billing',
                },
              ],
            },
          }),
        },
      );
      const json = await res.json();
      // json here has the order id inside.
      const {data} = json;
      const orderId = data.id;
      // using orderId we need get payment link

      const paymentRes = await fetch(
        `${config.ENDPOINT_CHECKOUT_PAYMENT(orderId)}${
          utmString && `?${utmString}`
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        },
      );
      const paymentJson = await paymentRes.json();
      results = {
        ...json,
        paymentLink: paymentJson.data.redirect_url,
        orderId,
        paymentId: paymentJson.data.payment_authorization_id,
      };
    } catch (err) {
      results = {success: false, error: err};
    } finally {
      setLoading(false);
      cb && cb(results);
    }
  }

  async function reinstateLastOrder() {
    dispatch(resetLastOrder());
  }

  async function getPaymentStatus(paymentId) {
    try {
      const utmString = await getUtm();
      const paymentRes = await fetch(
        `${config.ENDPOINT_CHECKOUT_PAYMENT_RESULTS(paymentId)}${
          utmString && `?${utmString}`
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        },
      );
      const paymentJson = await paymentRes.json();
      return {status: paymentJson.data.status};
    } catch (err) {
      console.log(err);
      return {status: 'error'};
    }
  }

  return {
    addCart,
    removeCart,
    deleteCart,
    updateTimeslot,
    updatePaymentMethod,
    clearCart,
    checkoutOrder,
    reinstateLastOrder,
    getPaymentStatus,
    cartItem : { items : cart.items[userId] || []},
    cart,
    loading,
  };
};
