import HGButton from 'components/HGButton';
import HGText from 'components/HGText';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'react-native';
import {scale} from 'utils/Scale';

export default ({onContinue}) => {
  const {t} = useTranslation();
  return (
    <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      <HGText variant="header">External Payment Provider</HGText>
      <View style={{marginTop: scale(50)}}>
        <HGButton label={t('Continue')} onPress={onContinue} />
      </View>
    </View>
  );
};
