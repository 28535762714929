import React from 'react';
import {Bar, TicketsFill} from 'components/HGIcon';
import Row from 'components/Row';
import {Image, StyleSheet, View} from 'react-native';
import {scale, widthPercentageToDP} from 'utils/Scale';
import HGText from 'components/HGText';
import {useTranslation} from 'react-i18next';
import {CardContainer} from 'components/Card';
import hexToRgba from 'hex-to-rgba';
import {ordinal, parseDisplayDate} from 'utils/Utils';
import HGImage from '../../../components/HGImage';
import {DateTime} from 'luxon';

const Divider = () => <View style={styles.divider} />;

export default ({event, tickets, options}) => {
  const {i18n} = useTranslation();

  const quantity = Object.values(tickets).reduce((a, b) => a + b, 0);
  const eventImage = event.assets[0]?.file.url ?? '';
  return (
    <CardContainer padded={true}>
      <HGText variant="header" fontSize={scale(17)} upperCase={true}>
        {event.title}
      </HGText>
      <Divider />
      <Row>
        <View style={styles.imageContainer}>
          <HGImage style={styles.image} source={eventImage} />
        </View>
        <View>
          <View>
            <Row>
              <TicketsFill style={styles.icon} />
              <HGText>
                {quantity} Ticket{quantity > 1 ? 's' : ''}
              </HGText>
            </Row>
            {options
              .filter(({qty}) => qty >= 1)
              .map(({qty, unit, title}, i) => (
                <Row key={`${qty}-${unit}-${i}`}>
                  <Bar style={styles.icon} />
                  <HGText>
                    {qty} {unit ? unit : title.de}
                    {/* {qty > 1 ? 's' : ''} */}
                  </HGText>
                </Row>
              ))}
          </View>
          <View style={styles.spacer} />
          <HGText color="primary">
            {parseDisplayDate(event.date_at, i18n.language)},{' '}
            {event.admission_at}
          </HGText>
        </View>
      </Row>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  spacer: {flex: 1},
  header: {
    marginBottom: scale(12),
    alignItems: 'center',
  },
  icon: {
    width: scale(20),
    height: scale(17),
    marginRight: scale(11),
  },
  divider: {
    marginVertical: scale(19),
    borderTopWidth: 1,
    borderColor: hexToRgba('#707070', 0.5),
    marginLeft: -widthPercentageToDP(2.5),
    width: widthPercentageToDP(75),
  },
  imageContainer: {
    justifyContent: 'center',
    flex: 1,
    marginRight: scale(21),
  },
  image: {
    width: scale(74),
    height: scale(74),
  },
});
