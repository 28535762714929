import {useState} from 'react';
import {useSelector} from 'react-redux';
import config from '../config';
import getUtmDetails from '../hooks/getUtmDetails';

export default params => {
  const {getUtm} = getUtmDetails();
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(state => state.account.accessToken);

  const getOrder = async function (orderId) {
    setLoading(true);
    try {
      const utmString = await getUtm();
      let orderType = 'ticket';
      let res = await fetch(
        `${config.ENDPOINT_ORDERS}${utmString && `?${utmString}`}`,
        {
          method: 'GET',
          headers: {
            'X-HG-PWA-Origin': config.PWA_ORIGIN,
            Authorization: 'Bearer ' + accessToken,
          },
        },
      );
      let json = await res.json();
      let orderItem = json.find(({order_id}) => order_id == orderId);

      if (!orderItem) {
        // should be a F&B order
        orderType = 'counter';
        res = await fetch(
          `${config.ENDPOINT_ORDERS}${utmString && `?${utmString}`}`,
          {
            method: 'GET',
            headers: {
              'X-HG-PWA-Origin': config.PWA_ORIGIN,
              Authorization: 'Bearer ' + accessToken,
            },
          },
        );
        res = await res.json();
        orderItem = json.find(({id}) => id == orderId);
      }

      return {
        id: orderId,
        items: [orderItem],
        type: orderType,
      };
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getOrders = async function () {
    setLoading(true);
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_ORDERS}${utmString && `?${utmString}`}`,
        {
          method: 'GET',
          headers: {
            'X-HG-PWA-Origin': config.PWA_ORIGIN,
            Authorization: 'Bearer ' + accessToken,
          },
        },
      );
      const json = await res.json();
      let orders = [];
      json.forEach(order => {
        const {order_id} = order;
        if (orders.filter(x => x.id === order_id).length) {
          const indexNum = orders.findIndex(x => x.id === order_id);
          orders[indexNum].items.push(order);
        } else {
          orders.push({
            id: order_id,
            items: [order],
            type: order.event?.length >= 1 ? 'ticket' : 'counter',
          });
        }
      });
      return orders;
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return {getOrder, getOrders, loading};
};
