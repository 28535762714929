import Container from 'components/Container';
import HGButton from 'components/HGButton';
import HGText from 'components/HGText';
import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  ActivityIndicator,
  Image,
  Linking,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import {scale, widthPercentageToDP, heightPercentageToDP} from 'utils/Scale';
import Colors from '../../utils/Colors';
import _claimTicket from '../../hooks/claimTicket';
import useModal from '../../hooks/useModal';

export default ({navigation, route}) => {
  const {t} = useTranslation();
  const [initialize, setInitialize] = useState(false);
  const {claimTicketId} = route.params ?? {};
  const {claimTicket} = _claimTicket();
  const {showModal} = useModal();

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      if (!claimTicketId) {
        navigation.replace('App');
        return;
      }

      const performClaimTicket = async () => {
        const results = await claimTicket({ticketId: claimTicketId});
        if (!results.success) {
          console.log(results);
          showModal({title: t('Error Claiming Ticket')});
          navigation.replace('App', {lastClaimed: claimTicketId});
          return;
        }

        showModal({title: t('Success Claiming Ticket')});
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'App',
              params: {
                lastClaimed: claimTicketId,
                screen: 'Tickets',
                params: {
                  screen: 'Tickets',
                  params: {
                    goToTicketId: results.ticketId,
                  },
                },
              },
            },
          ],
        });
      };

      performClaimTicket();
    }
  }, [claimTicketId, navigation, initialize, claimTicket, showModal, t]);

  return (
    <Container style={styles.container}>
      <ActivityIndicator color={Colors.PRIMARY_COLOR} />
      <HGText>{t('Claiming Ticket')}</HGText>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  paymentText: {
    marginVertical: scale(20),
  },
});
