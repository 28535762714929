import React, {useCallback, useEffect, useState, useRef} from 'react';
import {View, Text, StyleSheet, ScrollView, FlatList} from 'react-native';
import {useTranslation} from 'react-i18next';
import {MOCK_COUNTER_ORDERS} from 'utils/mock_data';
import CollectOrderCard from './components/CollectOrderCard';
import getCounterCollectOrders from '../../hooks/getCounterCollectOrders';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';
import {scale} from '../../utils/Scale';
import {RefreshControl} from 'react-native-web-refresh-control';

export default ({navigation}) => {
  const {t, i18n} = useTranslation();
  const [initialize, setInitialize] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [orders, setOrders] = useState([]);
  const {loading, getCounterOrders} = getCounterCollectOrders();
  const scrollContainer = useRef(null);

  useEffect(() => {
    if (!initialize) {
      setInitialize(true);
      const initialize = async () => {
        const res = await getCounterOrders();
        if (Array.isArray(res)) {
          setOrders(res);
        }
      };
      initialize();
    }
  }, [getCounterOrders, initialize]);

  const onRefresh = useCallback(() => {
    setRefreshing(true);
    const getOrderList = async () => {
      const res = await getCounterOrders(true);
      if (Array.isArray(res)) {
        setOrders(res);
      }
    };
    getOrderList()
      .then(() => {
        setRefreshing(false);
      })
      .catch(console.error());
  }, [getCounterOrders]);

  const keyExtractor = useCallback(item => item.key, []);

  const renderOrderItem = ({item}) => (
    <CollectOrderCard
      items={item.items.map(({name, price_total, qty}) => ({
        name: name[i18n.language],
        price: price_total,
        quantity: qty,
      }))}
      isReady={item.pickup_state === 'ready'}
      timing={item.order_time}
      collectionNo={item.display_number}
      pickupState={item.pickup_state}
      orderId={item.id}
    />
  );

  const ItemDivider = () => {
    return (
      <View
        style={{
          height: scale(12),
          width: '100%',
        }}
      />
    );
  };

  return (
    <ScrollView
      contentContainerStyle={{minHeight: '100%'}}
      ref={scrollContainer}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
      }>
      {loading && <BasicLoadingIndicator />}
      <FlatList
        data={orders}
        renderItem={renderOrderItem}
        keyExtractor={keyExtractor}
        ItemSeparatorComponent={ItemDivider}
      />
      {/* {orders.map(order => {
        const {id, items, display_number, pickup_state, order_time} = order;
        return (
          <View key={id} style={styles.cardContainer}>
            <CollectOrderCard
              items={items.map(({name, price_total, qty}) => ({
                name: name[i18n.language],
                price: price_total,
                quantity: qty,
              }))}
              isReady={pickup_state === 'ready'}
              timing={order_time}
              collectionNo={display_number}
              pickupState={pickup_state}
            />
          </View>
        );
      })} */}
      {/* {MOCK_COUNTER_ORDERS.map(({items, timing, collectionNo}, i) => {
        return (
          <CollectOrderCard
            key={i}
            index={i + 1}
            items={items}
            timing={timing}
            collectionNo={collectionNo}
          />
        );
      })} */}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  cardContainer: {marginBottom: scale(10)},
});
