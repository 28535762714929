import React, {useCallback, useState} from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  View,
  NativeModules,
  LayoutAnimation,
  Platform,
  Text,
} from 'react-native';
import {
  CardContainer,
  styles as CardContainerStyles,
} from '../../../components/Card';
import {scale, widthPercentageToDP} from '../../../utils/Scale';
import HGText from '../../../components/HGText';
import Row from 'components/Row';
import {Time} from 'components/HGIcon';
import hexToRgba from 'hex-to-rgba';
import Colors from 'utils/Colors';
import {useTranslation} from 'react-i18next';
import HGCheckBox from 'components/HGCheckBox';
import {orderTimeMap, parseCurrency} from '../../../utils/Utils';
const {UIManager} = NativeModules;

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export default ({
  items,
  collectionNo,
  timing,
  isReady,
  pickupState,
  orderId,
}) => {
  const {t} = useTranslation();
  const [showMore, _setShowMore] = useState(false);
  const setShowMore = useCallback(() => {
    LayoutAnimation.easeInEaseOut();
    _setShowMore(!showMore);
  }, [showMore]);

  const _pickedUp = 'picked_up';
  const _ready = 'ready';
  const _new = 'new';

  return (
    <CardContainer
      densePadded={true}
      containerStyle={
        pickupState === _pickedUp ? {backgroundColor: '#60605C'} : {}
      }>
      <View style={styles.containerOrderState}>
        <View style={styles.leftContainerOrderState}>
          <Text>{t('orderNumber')}</Text>
          <Text style={{marginLeft: 8}}>{orderId}</Text>
        </View>
        <View style={styles.rightContainerOrderState}>
          {pickupState === _new ? (
            <View style={styles.containerNewLabel}>
              <Text style={styles.labelTextNewState}>
                {t('pickUpState.new')}
              </Text>
            </View>
          ) : null}
          {pickupState === _ready ? (
            <View style={styles.containerReadyLabel}>
              <Text style={styles.labelTextReadyState}>
                {t('pickUpState.pickup')}
              </Text>
            </View>
          ) : null}
          {pickupState === _pickedUp ? (
            <View style={styles.containerDoneLabel}>
              <Text style={styles.labelTextDoneState}>
                {t('pickUpState.done')}
              </Text>
            </View>
          ) : null}
        </View>
      </View>
      <View style={styles.moreDivider} />
      <View style={styles.collectionNumber}>
        <HGText
          variant={'navigatorHeaderTitle'}
          fontSize={scale(19)}
          color="secondary"
          upperCase={true}>
          {t('Collection number')}:
        </HGText>
        <HGText
          variant={'navigatorHeaderTitle'}
          fontSize={scale(30)}
          color={isReady ? 'primary' : '#808080'}>
          {collectionNo}
        </HGText>
      </View>
      <View style={styles.divider} />
      <Row style={styles.timeRow}>
        <Time style={styles.clockIcon} />
        <HGText variant="eventDetailsMeta">
          {t(
            `checkout.timeslots.${timing ? orderTimeMap(timing) : 'possible'}`,
          )}
        </HGText>
      </Row>
      <View style={styles.itemsContainer}>
        {items.map(({name, quantity, price}, itemId) => (
          <Row key={`${name}-${itemId}`} style={styles.itemContainer}>
            <View style={styles.quantityContainer}>
              <HGText variant="eventDetailsMeta">{quantity}</HGText>
            </View>
            <View style={{flex: 1}}>
              <HGText variant="eventDetailsMeta">{name}</HGText>
            </View>
            <View>
              <HGText variant="eventDetailsMeta">
                {parseCurrency({amount: price})}
              </HGText>
            </View>
          </Row>
        ))}
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  spacer: {flex: 1},
  moreDivider: {
    width: widthPercentageToDP(100),
    marginLeft:
      Platform.OS === 'web'
        ? scale(-22.5)
        : -CardContainerStyles.densedPadded.paddingHorizontal,
    marginTop: scale(17),
    marginBottom: scale(15),
    borderTopWidth: 1,
    borderTopColor: hexToRgba(Colors.SECONDARY_COLOR, 0.25),
  },
  collectionNumber: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    borderColor: '#707070',
    borderTopWidth: 1,
    marginTop: scale(20.5),
    marginBottom: scale(23.5),
  },
  timeRow: {alignItems: 'center'},
  clockIcon: {height: scale(22), marginRight: scale(9)},
  itemsContainer: {paddingLeft: scale(10), marginTop: scale(15)},
  itemContainer: {marginBottom: scale(7)},
  quantityContainer: {
    width: scale(40),
  },
  containerOrderState: {
    flexDirection: 'row',
  },
  leftContainerOrderState: {
    flex: 0.5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightContainerOrderState: {
    flex: 0.5,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  containerNewLabel: {
    padding: 5,
    backgroundColor: '#FFFE00',
    borderRadius: 5,
  },
  containerReadyLabel: {
    padding: 5,
    backgroundColor: '#e4f8e6',
    borderRadius: 5,
  },
  containerDoneLabel: {
    padding: 5,
    backgroundColor: '#ECECE7',
    borderRadius: 5,
  },
  labelTextNewState: {
    color: '#EEBB00',
    fontWeight: 'bold',
  },
  labelTextReadyState: {
    color: '#74bb6f',
    fontWeight: 'bold',
  },
  labelTextDoneState: {
    color: '#60605C',
    fontWeight: 'bold',
  },
});
