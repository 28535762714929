import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Container from 'components/Container';
import {ScrollView, StyleSheet, View} from 'react-native';
import TicketDetailsCard from './components/TicketDetailsCard';
import SelectTicketQuantityCard from './components/SelectTicketQuantityCard';
import {scale} from '../../utils/Scale';
import OptionsCard from './components/OptionsCard';
import PriceCalculator from './components/PriceCalculator';
import useTicketCheckout from '../../hooks/useTicketCheckout';
import {useSelector} from 'react-redux';
import getProductOption from '../../hooks/getProductOption';
import {calculateTaxAmount} from '../../utils/Utils';

export default ({route, navigation}) => {
  const {event} = route.params;
  const {isLoaded, productOption} = getProductOption({eventId: event.id});
  const productsObj = useSelector(state => state.events.products);

  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [tickets, setTickets] = useState({});
  const [options, setOptions] = useState([]);
  const {createCart} = useTicketCheckout();

  const [vatRates, setVatRates] = useState([]);

  const ticketProducts = event.product_ids
    .map(id => productsObj[id])
    .filter(obj => !!obj);
  useEffect(() => {
    let total = 0;
    let totalQty = 0;
    let rates = [];
    Object.keys(tickets).forEach(productId => {
      const quantity = tickets[productId];
      totalQty += quantity;
      total += quantity * productsObj[productId].price;

      const ticketRate = productsObj[productId].vat_rate;
      const ticketSubPrice = quantity * productsObj[productId].price;
      rates = calculateTaxAmount(rates, ticketRate, ticketSubPrice);
    });

    if (options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        const {id, qty} = options[i];
        const selectedOption = productOption.find(x => x.id == id);
        if (selectedOption) {
          total += qty * selectedOption.price;

          const optionRate = selectedOption.vat_rate;
          const optionSubPrice = qty * selectedOption.price;
          rates = calculateTaxAmount(rates, optionRate, optionSubPrice);
        }
      }
    }

    setTotalPrice(total);
    setTotalQuantity(totalQty);
    setVatRates(rates);
  }, [productsObj, tickets, options, event, productOption]);

  function goToCheckout() {
    createCart(
      {
        id: event.id,
        event,
        options: options.map(({id, qty}) => ({
          qty,
          ...productOption.filter(i => i.id == id)[0],
        })),
        tickets,
      },
      () => {
        navigation.navigate('Checkout');
      },
    );
  }

  return (
    <>
      <ScrollView>
        <Container style={styles.container}>
          <TicketDetailsCard
            title={event.title}
            date={event.date_at}
            time={event.start_at}
          />
          <View style={styles.cardContainer}>
            <SelectTicketQuantityCard
              ticketTypes={ticketProducts}
              onChange={setTickets}
            />
          </View>
          {productOption.length != 0 && isLoaded ? (
            <View style={styles.cardContainer}>
              <OptionsCard options={productOption} onChange={setOptions} />
            </View>
          ) : null}
          <View style={styles.priceContainer}>
            <PriceCalculator
              price={isNaN(totalPrice) ? 0 : totalPrice}
              noOfTickets={totalQuantity}
              onPress={goToCheckout}
              vatRates={vatRates}
            />
          </View>
        </Container>
      </ScrollView>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: scale(30),
  },
  cardContainer: {
    marginTop: scale(13),
  },
  priceContainer: {
    marginTop: scale(46),
    marginBottom: scale(30),
  },
});
