import React from 'react';
import {StyleSheet, View} from 'react-native';
import IconButton from '../IconButton';
import Row from '../Row';
import * as HGIcon from 'components/HGIcon';
import {scale, widthPercentageToDP} from '../../utils/Scale';
import config from '../../config';

export default ({navigation, disableShare = false}) => {
  return (
    <Row style={styles.container}>
      <Row style={styles.fullWidth}>
        <IconButton
          onPress={navigation.goBack}
          icon={<HGIcon.LeftChevron style={styles.icon} />}
        />
      </Row>
      <Row>
        {!disableShare ? (
          <IconButton
            backgroundColor="#F1EEED"
            style={styles.shareIconContainer}
            icon={<HGIcon.Share style={styles.shareIcon} />}
          />
        ) : null}

        {!config.DISABLE_FAVOURITES && (
          <IconButton
            backgroundColor="#F1EEED"
            style={styles.favouriteIconContainer}
            icon={<HGIcon.Heart style={styles.favouriteIcon} />}
          />
        )}
      </Row>
    </Row>
  );
};

const styles = StyleSheet.create({
  container: {
    width: widthPercentageToDP(100),
    paddingHorizontal: scale(16),
  },
  fullWidth: {
    flex: 1,
  },
  icon: {
    width: scale(20),
    height: scale(20),
  },
  shareIconContainer: {
    marginRight: scale(20),
  },
  shareIcon: {
    width: scale(20),
    height: scale(25),
  },
  favouriteIconContainer: {},
  favouriteIcon: {
    width: scale(20),
    height: scale(22),
  },
});
