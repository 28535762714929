import {CardContainer} from 'components/Card';
import {TicketFill} from 'components/HGIcon';
import HGText from 'components/HGText';
import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {StyleSheet, View} from 'react-native';
import {scale} from 'utils/Scale';
import {parseCurrency} from '../../../utils/Utils';

export default ({visitor, price}) => {
  const {t} = useTranslation();
  return (
    <CardContainer densePadded={true} containerStyle={{borderWidth: 0}}>
      <Row style={styles.headerContainer}>
        <TicketFill />
        <HGText
          upperCase={true}
          fontSize={scale(14)}
          variant="header"
          color="secondary">
          {t('seat')}
        </HGText>
      </Row>
      <View style={styles.divider}>
        <View style={[styles.cutout, styles.leftCutout]} />
        <View style={[styles.cutout, styles.rightCutout]} />
      </View>
      <View>
        <Row>
          <HGText variant="eventDetailsMeta">{t('Price/ Discount')}</HGText>
          <View style={styles.spacing} />
          <HGText variant="eventDetailsMeta">{t('Amount')}</HGText>
        </Row>
        <Row style={styles.priceContainer}>
          <HGText variant="eventDetailsMeta" color="primary">
            {t('Sale')}
          </HGText>
          <View style={styles.spacing} />
          <HGText variant="eventDetailsMeta" color="primary">
            {parseCurrency({amount: parseFloat(price)})}
          </HGText>
        </Row>
        <Row>
          <HGText variant="eventDetailsMeta">{t('Visitor')}</HGText>
          <View style={styles.spacing} />
        </Row>
        <View>
          <HGText variant="eventDetailsMeta" color="primary">
            {visitor.firstName} {visitor.lastName}
          </HGText>
        </View>
      </View>
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  spacing: {flex: 1},
  priceContainer: {
    marginBottom: scale(15),
  },
  headerContainer: {
    alignItems: 'center',
  },
  divider: {
    marginTop: scale(10),
    marginBottom: scale(17),
    borderTopWidth: 1,
    borderStyle: 'dashed',
    borderTopColor: hexToRgba('#707070', 0.25),
  },
  cutout: {
    height: scale(22),
    width: scale(22),
    backgroundColor: '#F1EEED',
    position: 'absolute',
    borderRadius: scale(11),
    zIndex: 999,
    top: scale(-11),
  },
  leftCutout: {
    left: scale(-33.5),
  },
  rightCutout: {
    right: scale(-33.5),
  },
});
