import HGText from 'components/HGText';
import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {StyleSheet, TouchableOpacity, View} from 'react-native';
import Colors from 'utils/Colors';
import {scale} from 'utils/Scale';

export default ({menuItems, onPress, activeTab, style}) => {
  return (
    <Row style={style}>
      {menuItems.map(({label, screen}, i) => {
        return (
          <Row style={styles.tabItemContainer} key={`${label}-${i}`}>
            <TouchableOpacity onPress={() => onPress(screen)}>
              <View style={{paddingHorizontal: scale(8)}}>
                <HGText variant="tabBar">{label}</HGText>
              </View>
              {activeTab === screen ? (
                <View style={styles.indicator} />
              ) : (
                <View style={styles.emptyIndicator} />
              )}
            </TouchableOpacity>
            {i !== menuItems.length - 1 && <View style={styles.menuDivider} />}
          </Row>
        );
      })}
    </Row>
  );
};

const styles = StyleSheet.create({
  tabItemContainer: {
    alignItems: 'center',
  },
  menuDivider: {
    width: 2,
    alignSelf: 'center',
    marginHorizontal: scale(24),
    marginBottom: scale(14),
    height: scale(22),
    backgroundColor: hexToRgba('#808080', 0.6),
  },
  indicator: {
    backgroundColor: Colors.PRIMARY_COLOR,
    height: scale(4),
    borderRadius: scale(2),
    marginTop: scale(14),
  },
  emptyIndicator: {
    height: scale(18),
  },
});
