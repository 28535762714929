import HGText from 'components/HGText';
import Row from 'components/Row';
import hexToRgba from 'hex-to-rgba';
import React from 'react';
import {StyleSheet, View, Platform} from 'react-native';
import {TabBar, TabBarItem, TabBarIndicator} from 'react-native-tab-view';
import Colors from 'utils/Colors';
import {scale} from 'utils/Scale';
export default ({
  state,
  descriptors,
  navigation,
  position,
  navigationState,
  ...props
}) => {
  return (
    <TabBar
      pressColor={Colors.BACKGROUND_COLOR}
      navigationState={navigationState}
      position={position}
      {...props}
      style={styles.tabBar}
      tabStyle={styles.tabStyle}
      indicatorStyle={styles.indicatorStyle}
      renderLabel={({route, focused, color}) => {
        return <HGText variant="tabBar">{route.name}</HGText>;
      }}
      renderIndicator={({style, ...props}) => {
        return (
          <TabBarIndicator
            {...props}
            style={{
              ...style,
              marginLeft: props.navigationState.index === 1 ? scale(56) : 0,
            }}
          />
        );
      }}
      renderTabBarItem={({style, ...props}) => {
        const showIndicator =
          (props.route.name === 'Information' &&
            props.navigationState.index === 0) ||
          (props.route.name === 'Tickets' && props.navigationState.index === 1);
        return (
          <Row key={props.key}>
            <TabBarItem
              {...props}
              style={{
                ...style,
                ...(Platform.OS === 'web'
                  ? {
                      borderBottomWidth: showIndicator ? 2 : 0,
                      borderColor: Colors.PRIMARY_COLOR,
                    }
                  : {}),
              }}
            />
            {props.route.name === 'Information' && (
              <View style={styles.divider} />
            )}
          </Row>
        );
      }}
    />
  );
};

const styles = StyleSheet.create({
  tabBar: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    elevation: 0,
    shadowOpacity: 0,
    marginBottom: scale(22),
  },

  tabStyle: {
    alignItems: 'flex-start',
    paddingLeft: 0,
    paddingRight: 0,
    width: 'auto',
    paddingBottom: scale(14),
  },
  indicatorStyle: {
    backgroundColor: Colors.PRIMARY_COLOR,
    height: scale(4),
    opacity: 1,
    borderRadius: scale(2),
  },
  divider: {
    width: 2,
    alignSelf: 'center',
    marginHorizontal: scale(28),
    height: scale(22),
    backgroundColor: hexToRgba('#808080', 0.6),
  },
});
