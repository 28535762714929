import {CardContainer} from 'components/Card';
import HGText from 'components/HGText';
import QuantityStepInput from 'components/QuantityStepInput';
import Row from 'components/Row';
import useCounterCheckout from 'hooks/useCounterCheckout';
import React from 'react';
import {StyleSheet, View} from 'react-native';
import {useSelector} from 'react-redux';
import {scale} from 'utils/Scale';
import {parseCurrency} from '../../../utils/Utils';

export default ({items}) => {
  return (
    <CardContainer densePadded={true}>
      {items.map(({name, option, quantity, price}, i) => {
        return (
          <Row
            key={`${name}-${option}-${i}`}
            style={{marginBottom: i === items.length - 1 ? 0 : scale(17)}}>
            <View style={styles.quantityContainer}>
              <HGText fontSize={scale(17)}>{quantity}</HGText>
            </View>
            <View style={styles.nameContainer}>
              <HGText fontSize={scale(17)}>{name}</HGText>
              {option && option !== "" && <HGText>{option}</HGText>}
            </View>
            <View style={styles.priceCOntainer}>
              <HGText fontSize={scale(17)}>
                {parseCurrency({amount: price * quantity})}
              </HGText>
            </View>
          </Row>
        );
      })}
    </CardContainer>
  );
};

const styles = StyleSheet.create({
  nameContainer: {
    flex: 1,
  },
  quantityContainer: {
    width: '10%',
  },
  priceCOntainer: {
    width: '25%',
    alignItems: 'center',
  },
  nameOptionContainer: {
    marginTop: scale(5),
  },
});
