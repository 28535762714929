import {
  CovidCertificate,
  Document,
  DownChevron,
  HospitalCertificate,
} from 'components/HGIcon';
import Row from 'components/Row';
import React, {useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, StyleSheet, TouchableOpacity, View} from 'react-native';
import Animated, {EasingNode} from 'react-native-reanimated';
import HGText from 'components/HGText';
import {scale} from 'utils/Scale';

export const DocumentSelectButton = ({icon, label, onChange}) => {
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);
  const fadeAnim = useRef(new Animated.Value(0)).current;

  const toggleFloating = useCallback(() => {
    if (Platform.OS === 'web') {
      fadeAnim.setValue(open ? 0 : 1);
      setOpen(!open);
      return;
    }
    if (!open) {
      setOpen(true);
    }
    const openAfter = open;
    Animated.timing(fadeAnim, {
      toValue: open ? 0 : 1,
      easing: EasingNode.linear,
      duration: 150,
    }).start(finished => {
      if (finished && openAfter) {
        setOpen(false);
      }
    });
  }, [fadeAnim, open]);

  const changeDocument = document => {
    onChange(document);
    toggleFloating();
  };

  return (
    <View>
      <TouchableOpacity onPress={toggleFloating}>
        <View style={styles.buttonContainer}>
          <View style={styles.iconContainer}>{icon}</View>
          <HGText variant="header" style={styles.label}>
            {label?.toUpperCase()}
          </HGText>
          <DownChevron />
        </View>
      </TouchableOpacity>
      <Animated.View
        style={[
          styles.floatingDropdown,
          open ? styles.floatingDropdownOpen : styles.floatingDropdownClose,
          {opacity: fadeAnim},
        ]}>
        <TouchableOpacity onPress={() => changeDocument('vaccination')}>
          <Row style={styles.dropdownItem}>
            <View style={styles.dropdownItemIconContainer}>
              <CovidCertificate />
            </View>
            <HGText variant="coronaFloatingMenuItem">
              {t('vaccination certificate')}
            </HGText>
          </Row>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => changeDocument('recovery')}>
          <Row style={styles.dropdownItem}>
            <View style={styles.dropdownItemIconContainer}>
              <Document />
            </View>
            <HGText variant="coronaFloatingMenuItem">{t('recovery')}</HGText>
          </Row>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => changeDocument('test')}>
          <Row style={[styles.dropdownItem, {marginBottom: 0}]}>
            <View style={styles.dropdownItemIconContainer}>
              <HospitalCertificate />
            </View>
            <HGText variant="coronaFloatingMenuItem">{t('corona test')}</HGText>
          </Row>
        </TouchableOpacity>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    backgroundColor: '#FFF',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: scale(20),
    paddingVertical: scale(15),
    borderRadius: scale(10),
    height: scale(50),
  },
  iconContainer: {
    width: scale(32),
    marginRight: scale(21),
  },
  label: {
    flex: 1,
    fontSize: scale(13),
  },
  floatingDropdown: {
    position: 'absolute',
    right: scale(20),
    top: scale(58),
    paddingHorizontal: scale(15),
    paddingVertical: scale(17),
    backgroundColor: '#BAAEA9',
    borderRadius: scale(10),
  },
  floatingDropdownOpen: {
    zIndex: 999,
    display: 'flex',
  },
  floatingDropdownClose: {
    zIndex: -1,
    display: 'none',
  },
  dropdownItem: {
    marginBottom: scale(10),
    alignItems: 'center',
  },
  dropdownItemIconContainer: {
    marginRight: scale(9),
  },
});

export default DocumentSelectButton;
