import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import Container from 'components/Container';
import ProgressPills from 'components/ProgressPills';
import {scale} from '../../utils/Scale';
import {CardContainer} from '../../components/Card';
import {useTranslation} from 'react-i18next';
import HGText from '../../components/HGText';
import RadioButton from '../../components/RadioButton';
import RadioButtonInput from '../../components/RadioButtonInput';
import TwoActionButtons from '../../components/TwoActionButtons';
import {useSelector} from 'react-redux';
import useTicketCheckout from '../../hooks/useTicketCheckout';
import useCounterCheckout from 'hooks/useCounterCheckout';

export default ({navigation, route}) => {
  const {updatePaymentMethod: updateTicketPaymentMethod} = useTicketCheckout();
  const {updatePaymentMethod: updateCounterPaymentMethod} =
    useCounterCheckout();
  const {t} = useTranslation();
  const _selectedPayment = useSelector(state => state);
  const selectedPayment =
    _selectedPayment[route.params.screenType].paymentMethod;

  const [option, setOption] = useState(selectedPayment);

  const onSelectOption = _option => {
    setOption(_option);
  };
  return (
    <Container>
      <View style={styles.progressContainer}>
        <ProgressPills
          total={4}
          current={route.params.screenType === 'counterCheckout' ? 3 : 2}
        />
      </View>
      <CardContainer densePadded={true} containerStyle={styles.container}>
        <HGText>{t('checkout.selectPayment')}</HGText>
      </CardContainer>
      <CardContainer densePadded={true} containerStyle={styles.container}>
        <RadioButtonInput
          active={option === 'credit'}
          label={t('Credit Card')}
          onPress={onSelectOption.bind(null, 'credit')}
        />
        <View style={styles.creditCardContainer}>
          <HGText>{t('checkout.redirectMessage')}</HGText>
        </View>
      </CardContainer>
      <CardContainer densePadded={true}>
        <RadioButtonInput
          active={option === 'paypal'}
          label={t('Paypal')}
          onPress={onSelectOption.bind(null, 'paypal')}
        />
      </CardContainer>
      <View style={styles.buttonContainer}>
        <TwoActionButtons
          buttons={[
            {label: t('Back'), onPress: navigation.goBack},
            {
              label: t('Continue'),
              onPress: () => {
                switch (route.params.screenType) {
                  case 'ticketCheckout':
                    updateTicketPaymentMethod({paymentMethod: option}, () => {
                      if (route.params?.nextGoBack) {
                        navigation.goBack();
                      } else {
                        navigation.navigate('TicketCheckoutInvoiceAddress');
                      }
                    });
                    break;
                  case 'counterCheckout':
                    updateCounterPaymentMethod({paymentMethod: option}, () => {
                      if (route.params?.nextGoBack) {
                        navigation.goBack();
                      } else {
                        navigation.navigate('CounterCheckoutOverview');
                      }
                    });
                    break;
                }
              },
            },
          ]}
        />
      </View>
    </Container>
  );
};

const styles = StyleSheet.create({
  progressContainer: {
    marginLeft: scale(19),
    marginVertical: scale(30),
  },
  container: {
    marginBottom: scale(13),
  },
  creditCardContainer: {
    marginTop: scale(18),
    marginLeft: scale(25.5),
  },
  buttonContainer: {
    marginTop: scale(50),
  },
});
