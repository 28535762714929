// RootNavigation.js

import * as React from 'react';

export const navigationRef = React.createRef();

export function root_goBack() {
  if (navigationRef.current?.canGoBack()) {
    navigationRef.current?.goBack();
  } else {
    history.go(1);
  }
}
