import React, {useCallback} from 'react';
import {
  View,
  Text,
  ScrollView,
  StyleSheet,
  ActivityIndicator,
  Linking,
  TouchableWithoutFeedback,
} from 'react-native';
import Container from 'components/Container';
import {useTranslation} from 'react-i18next';
import HGText from '../../components/HGText';
import getEventDetails from '../../hooks/getEventDetails';
import FeaturedImageHeader from 'components/FeaturedImageHeader';
import {scale} from '../../utils/Scale';
import DateTimePriceCard from './components/DateTimePriceCard';
import DetailsCard from './components/DetailsCard';
import CategoryList from '../Home/components/CategoryList';
import StickyFooter from './components/StickyFooter';
import Colors from 'utils/Colors';
import getEvents from 'hooks/getEvents';
import CONFIG from 'config';
import Map from 'components/Map';
import config from '../../config';

export default ({route, navigation, containerStyle}) => {
  const {t} = useTranslation();
  const {loading, event} = getEventDetails({eventId: route.params.eventId});
  const {events, loading: fetchingEvents} = getEvents();

  const goToTicketSelection = useCallback(() => {
    if (CONFIG.ENABLE_EXTERNAL_PAYMENT) {
      Linking.openURL(event?.data?.urls?.booking_url);
    } else {
      navigation.navigate('EventTicketSelection', {event});
    }
  }, [navigation, event]);

  if (loading || !event) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator color={Colors.PRIMARY_COLOR} />
      </View>
    );
  }

  const eventImage = event?.assets[0]?.file?.url ?? '';

  return (
    <>
      <ScrollView
        contentContainerStyle={[styles.scrollContainer, containerStyle]}>
        <FeaturedImageHeader
          image={eventImage}
          shareLink={`${config.BASE_APP_URL}/event/${route.params.eventId}`}
        />
        <Container>
          <HGText style={styles.header} variant="header">
            {event.title.toUpperCase()}
          </HGText>
          <View style={styles.firstCard}>
            <DateTimePriceCard
              date={event.date_at}
              time={event.admission_at}
              startTime={event.start_at}
              price={event.data.price}
              multiplePrices={event.data.cheapest}
            />
          </View>
          <View style={styles.card}>
            <DetailsCard title={t('description')} body={event.content} />
          </View>
          <View style={styles.card}>
            <DetailsCard
              disableHTML={true}
              title={t('location')}
              body={Object.values(event.data.location.address).join('\n')}
              extraComponents={[
                <View style={styles.mapContainer} key="map">
                  <Map
                    latitude={
                      event.data?.coordinates ? event.data?.coordinates[0] : 0
                    }
                    longitude={
                      event.data?.coordinates ? event.data?.coordinates[1] : 0
                    }
                    onPressDirections={() =>
                      Linking.openURL(
                        `https://www.google.com/maps/dir/?api=1&destination=${
                          event.parking
                            ? typeof event.parking === 'string'
                              ? event.parking
                              : `${event.parking[0]},${event.parking[1]}`
                            : event.data.coordinates
                            ? event.data.coordinates.join(',')
                            : '0'
                        }`,
                      )
                    }
                  />
                </View>,
              ]}
            />
          </View>
          <View style={styles.following}>
            <CategoryList
              loading={fetchingEvents}
              hideChevron={true}
              navigation={navigation}
              header={'FOLLOWING EVENTS'}
              events={events.filter(
                ({date_at: date}) => new Date(date) > new Date(event.date_at),
              )}
            />
          </View>
        </Container>
      </ScrollView>
      <StickyFooter
        price={event.data.price}
        multiplePrices={event.data.cheapest}
        onPress={goToTicketSelection}
      />
    </>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollContainer: {
    paddingBottom: scale(100),
  },
  header: {
    marginTop: scale(28),
  },
  firstCard: {
    marginTop: scale(30),
  },
  card: {
    marginTop: scale(13),
  },
  following: {
    marginTop: scale(25),
  },
  mapContainer: {
    marginTop: scale(8),
    borderRadius: scale(10),
    overflow: 'hidden',
  },
});
