import React, {useState} from 'react';
import {Platform} from 'react-native';
import {useDispatch, useSelector} from 'react-redux';
import config from '../config';
import {
  createCart as _createCart,
  updatePaymentMethod as _updatePaymentMethod,
  updateBillingAddress as _updateBillingAddress,
  updateAssignments as _updateAssignments,
} from '../state/ticketCheckout';
import getUtmDetails from '../hooks/getUtmDetails';

export default () => {
  const {getUtm} = getUtmDetails();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const accessToken = useSelector(state => state.account.accessToken);

  async function createCart({id, event, tickets, options}, cb) {
    dispatch(_createCart({id, event, tickets, options}, cb));
  }

  async function updatePaymentMethod({paymentMethod}, cb) {
    dispatch(_updatePaymentMethod({paymentMethod}, cb));
  }

  async function updateBillingAddress({billingAddress}, cb) {
    dispatch(_updateBillingAddress({billingAddress}, cb));
  }

  async function updateAssignments({productId, index, data}, cb) {
    dispatch(_updateAssignments({productId, index, data}, cb));
  }

  async function fetchBillingAddress(cb) {
    setLoading(true);
    let results = null;
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_BILLING_ADDRESS}${utmString && `?${utmString}`}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        },
      );
      const json = await res.json();
      results = json.addresses[0];
    } catch (err) {
    } finally {
      setLoading(false);
      cb && cb(results);
    }
  }

  async function fetchPaymentRecipient(cb) {
    setLoading(true);
    let results = null;
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_FETCH_PAYMENT_RECIPIENT}${
          utmString && `?${utmString}`
        }`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        },
      );
      const json = await res.json();
      results = json.vendor;
    } catch (err) {
    } finally {
      setLoading(false);
      cb && cb(results);
    }
  }

  async function fetchTenantDetails(cb) {
    setLoading(true);
    let results = null;
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_FETCH_TENANT_DETAILS}${
          utmString && `?${utmString}`
        }`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            // Authorization: 'Bearer ' + accessToken,
            // ...(Platform.OS === 'web'
            //   ? {
            //       'X-HG-PWA-Origin': config.PWA_ORIGIN,
            //     }
            //   : {}),
          },
        },
      );
      const json = await res.json();
      results = json;
    } catch (err) {
      console.log('error', err);
    } finally {
      setLoading(false);
      cb && cb(results);
    }
  }

  async function checkoutOrder({billing, tickets, assignments, options}, cb) {
    setLoading(true);
    let orderItems = [];
    Object.keys(tickets).forEach(productId => {
      orderItems.push({
        id: productId,
        qty: tickets[productId],
        visitors: assignments[productId].map(
          ({firstName, lastName, phone}) => ({
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
          }),
        ),
      });
    });

    let results = null;
    try {
      const utmString = await getUtm();
      const res = await fetch(
        `${config.ENDPOINT_CHECKOUT_ORDER}${utmString && `?${utmString}`}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
          body: JSON.stringify({
            order: {
              items: orderItems.concat(options.map(({id, qty}) => ({id, qty}))),
              addresses: [
                {
                  first_name: billing.firstName,
                  last_name: billing.lastName,
                  gender: billing.salutation, // Salutation will map to gender. There is only salutation on UI
                  email: billing.email,
                  address_1: billing.address,
                  address_2: '-',
                  city: billing.city,
                  zip: billing.postal,
                  country_code: billing.countryCode,
                  address_type: 'billing',
                  company_name: billing.companyName,
                },
              ],
            },
          }),
        },
      );

      const json = await res.json();
      // json here has the order id inside.
      const {data} = json;
      const orderId = data.id;
      // using orderId we need get payment link

      const paymentRes = await fetch(
        `${config.ENDPOINT_CHECKOUT_PAYMENT(orderId)}${
          utmString && `?${utmString}`
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        },
      );
      const paymentJson = await paymentRes.json();
      results = {
        ...json,
        paymentLink: paymentJson.data.redirect_url,
        orderId,
        paymentId: paymentJson.data.payment_authorization_id,
      };
    } catch (err) {
      results = {success: false, error: err};
    } finally {
      setLoading(false);
      cb && cb(results);
    }
  }

  async function getPaymentStatus(paymentId) {
    try {
      const utmString = await getUtm();
      const paymentRes = await fetch(
        `${config.ENDPOINT_CHECKOUT_PAYMENT_RESULTS(paymentId)}${
          utmString && `?${utmString}`
        }`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + accessToken,
            ...(Platform.OS === 'web'
              ? {
                  'X-HG-PWA-Origin': config.PWA_ORIGIN,
                }
              : {}),
          },
        },
      );
      const paymentJson = await paymentRes.json();
      return {status: paymentJson.data.status};
    } catch (err) {
      console.log(err);
      return {status: 'error'};
    }
  }

  return {
    createCart,
    updatePaymentMethod,
    updateBillingAddress,
    updateAssignments,
    fetchBillingAddress,
    fetchPaymentRecipient,
    fetchTenantDetails,
    checkoutOrder,
    getPaymentStatus,
    loading,
  };
};
