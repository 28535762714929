import React, {useEffect, useRef, useState} from 'react';
import {View, Text, StyleSheet, ScrollView} from 'react-native';
import Container from 'components/Container';
import TabMenu from 'components/TabMenu';
import {useTranslation} from 'react-i18next';
import {scale} from 'utils/Scale';
import NotificationBanner from 'components/NotificationBanner';
import HGText from 'components/HGText';
import MOCK_EVENTS from 'utils/mock_data/events.json';
import Order from './Order';
import useCounterCheckout from 'hooks/useCounterCheckout';
import CheckoutCartFloatingButton from './components/CheckoutCartFloatingButton';
import Collect from './Collect';
import getCounterOpenStatus from '../../hooks/getCounterOpenStatus';
import BasicLoadingIndicator from '../../components/BasicLoadingIndicator';

export default ({navigation}) => {
  const {t} = useTranslation();
  const [activeTab, setActiveTab] = useState(
    navigation.dangerouslyGetParent()?.dangerouslyGetState()?.routes[2].params
      ?.goToCollect
      ? 'collect'
      : 'order',
  );
  const {cartItem : cart} = useCounterCheckout();
  const scrollContainer = useRef(null);

  const [index, setIndex] = useState(0);
  const [initialize, setInitialize] = useState(false);
  const {getStatus, loading} = getCounterOpenStatus();
  const [activeEvent, setActiveEvent] = useState(false);

  useEffect(() => {
    if (!initialize) {
      async function init() {
        const isOpen = await getStatus();
        setActiveEvent(isOpen);
      }
      setInitialize(true);
      init();
    }
  }, [initialize, getStatus, navigation]);

  return (
    <Container>
      <View style={styles.menuContainer}>
        <TabMenu
          activeTab={activeTab}
          onPress={screen => setActiveTab(screen)}
          menuItems={[
            {label: t('order'), screen: 'order'},
            {label: t('collect'), screen: 'collect'},
          ]}
        />
      </View>
      {loading && <BasicLoadingIndicator />}
      {!activeEvent && initialize && !loading && (
        <NotificationBanner
          body={() => (
            <View>
              <HGText style={{fontWeight: '700'}}>
                {t('counterUnavailable.title')}
              </HGText>
              <View style={{marginTop: scale(26)}}>
                <HGText>{t('counterUnavailable.body')}</HGText>
              </View>
            </View>
          )}
        />
      )}
      {activeEvent && activeTab === 'order' && (
        <ScrollView key={index}
          contentContainerStyle={{minHeight: '100%'}}
          ref={scrollContainer}>
          <Order scrollContainer={scrollContainer?.current ?? null} />
        </ScrollView>
      )}
      {activeEvent && activeTab === 'collect' && <Collect />}
      {activeTab === 'order' &&
        Object.keys(cart.items).length > 0 &&
        activeEvent && (
          <CheckoutCartFloatingButton
            onPress={() => {
              setIndex(index+ 1);
              navigation.navigate('Checkout', {screen: 'CounterCheckoutCart'});
            }}
            cartNum={Object.values(cart.items).reduce((accumulator, value) => {
              return accumulator + value;
            }, 0)}
          />
        )}
    </Container>
  );
};

const styles = StyleSheet.create({
  menuContainer: {
    marginVertical: scale(22),
  },
});
