import React, {useEffect} from 'react';
import {createStackNavigator} from '@react-navigation/stack';
import {useTranslation} from 'react-i18next';
import User from '../views/User';
import {useSelector} from 'react-redux';
import Login from '../views/Login';
import FrontNavigationHeader from '../components/FrontNavigationHeader';
import Register from '../views/Register';
import PersonalSettings from '../views/User/PersonalSettings';
import PersonalDetails from '../views/PersonalDetails';
import ChangePassword from '../views/User/ChangePassword';
import CoronaRegistration from '../views/CoronaRegistration';
import ContactDetails from '../views/CoronaRegistration/ContactDetails';
import UploadDocuments from '../views/CoronaRegistration/UploadDocuments';
import QrCodeScanner from '../views/QrCodeScanner';
import UserPaymentMethod from '../views/UserPaymentMethod';
import ForgottenPassword from '../views/ForgottenPassword';
import ChangeAppLanguage from '../views/User/ChangeAppLanguage';

const Stack = createStackNavigator();

const CoronaNavigator = ({route}) => {
  const {t} = useTranslation();

  return (
    <Stack.Navigator initialRouteName="Home" headerMode="screen">
      <Stack.Screen
        name="Home"
        component={CoronaRegistration}
        options={{headerShown: false}}
      />
      <Stack.Screen
        name="Contact"
        component={ContactDetails}
        options={{
          header: ({navigation}) => (
            <FrontNavigationHeader
              navigation={navigation}
              showBack={true}
              title={t('contact details')}
            />
          ),
        }}
      />
      <Stack.Screen
        name="Upload"
        component={UploadDocuments}
        options={{
          headerShown: false, // manually set in component
        }}
      />
    </Stack.Navigator>
  );
};
export default ({route}) => {
  const {t} = useTranslation();
  const user = useSelector(state => state.account);

  const isLoggedIn = user.loggedIn;

  return (
    <Stack.Navigator
      headerMode={'screen'}
      initialRouteName={isLoggedIn ? 'Login' : 'User'}>
      {isLoggedIn ? (
        <>
          <Stack.Screen
            name="User"
            component={User}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PersonalSettings"
            component={PersonalSettings}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="PersonalDetails"
            component={PersonalDetails}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="UserPaymentMethods"
            component={UserPaymentMethod}
            options={{
              headerShown: true,
              header: ({navigation}) => (
                <FrontNavigationHeader
                  navigation={navigation}
                  showBack={true}
                  title={t('user.navigation.paymentMethod')}
                />
              ),
            }}
            initialParams={{screenType: 'userSettings'}}
          />
          <Stack.Screen
            name="ChangePassword"
            component={ChangePassword}
            options={{headerShown: false}}
          />
          <Stack.Screen
            name="CoronaRegistration"
            options={{headerShown: false}}
            component={CoronaNavigator}
          />
          <Stack.Screen
            name="QrCodeScanner"
            component={QrCodeScanner}
            options={{
              headerShown: true,
              header: ({navigation}) => (
                <FrontNavigationHeader
                  navigation={navigation}
                  showBack={true}
                  title={t('user.navigation.qr')}
                />
              ),
            }}
          />
          <Stack.Screen
            name="UserAppLanguage"
            component={ChangeAppLanguage}
            options={{
              headerShown: true,
              header: ({navigation}) => (
                <FrontNavigationHeader
                  navigation={navigation}
                  showBack={true}
                  title={t('user.navigation.language')}
                />
              ),
            }}
          />
        </>
      ) : (
        <>
          <Stack.Screen
            name="Login"
            component={Login}
            options={{
              header: () => {
                const {name} = route || {};
                if (name === 'CheckoutLogin') {
                  null;
                } else {
                  <FrontNavigationHeader title={t('LOGIN')} />;
                }
              },
            }}
          />
          <Stack.Screen
            name="ForgottenPassword"
            component={ForgottenPassword}
            options={{
              header: ({navigation}) => (
                <FrontNavigationHeader
                  navigation={navigation}
                  showBack={true}
                  title={t('reset password')}
                />
              ),
            }}
          />
          <Stack.Screen
            name="Register"
            component={Register}
            options={{
              header: ({navigation}) => (
                <FrontNavigationHeader
                  navigation={navigation}
                  showBack={true}
                  title={t('REGISTER')}
                />
              ),
            }}
          />
        </>
      )}
    </Stack.Navigator>
  );
};
